import React, { useEffect, useLayoutEffect, useRef } from 'react';
import './MembershipInfo.scss';
import Nav from 'components/Nav/Nav';
import Footer from 'components/Footer/Footer';
import ReviewSwiper from 'components/Review/ReviewSwiper';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MembershipSelector from 'pages/MembershipInfo/Components/MembershipSelector';
import useDiscount from 'hooks/price/useDiscount';
import { useProduct } from 'hooks/product/useProduct';

function MembershipInfo() {
  const ref = useRef();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    PRODUCT_TYPE,
    products,
    selectedProduct,
    setSelectedProductById,
    setSelectedProductByType,
    setDiscount,
  } = useProduct();
  const { applyDiscount } = useDiscount();

  const onNavigate = () => {
    navigate(`/payment?product_id=${selectedProduct.id}`);
  };

  useLayoutEffect(() => {
    applyDiscount(
      setDiscount,
      searchParams.get('coupon_number'),
      searchParams.get('code'),
    );
    setSelectedProductById(searchParams.get('product_id'));
  }, []);

  useEffect(() => {
    ref.current.scrollIntoView();
  }, []);

  return (
    <div ref={ref}>
      <Nav />
      <MembershipSelector
        PRODUCT_TYPE={PRODUCT_TYPE}
        products={products}
        selectedProduct={selectedProduct}
        setSelectedProductById={setSelectedProductById}
        setSelectedProductByType={setSelectedProductByType}
        onNavigate={onNavigate}
      />
      <div className="membership-review">
        <div className="membership-review-title strong">리뷰 / 후기</div>
        <ReviewSwiper buttonColor="#3D3D3D" />
      </div>
      <div className="membership-payment-button-container">
        <button
          className="membership-payment-button strong"
          onClick={onNavigate}
        >
          {selectedProduct?.type} 멤버십 시작하기
        </button>
      </div>
      <Footer />
    </div>
  );
}

export default MembershipInfo;
