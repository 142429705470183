import Layout from 'components/Raffle/common/Layout';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import ScratchCard from 'simple-scratch-card';
import styled from 'styled-components';
import lotteryCoverImage from '../../assets/images/img_lottery_background.png';
import lotteryWinningBackground from '../../assets/images/img_lottery_winning_background.png';
import { palette } from 'components/Raffle/palette';
import axios from 'axios';
import { API_BASE_URL } from './config';

export const sendKakao = () => {
  // my_ref_code 가져오기
  // 로컬 저장소에서 my_ref_code 가져오기
  const myRefCode = localStorage.getItem('my_ref_code');
  let url = 'https://glucofit.co.kr/raffle';

  // my_ref_code가 있다면 URL에 파라미터로 추가
  if (myRefCode) {
    url += `?ref=${myRefCode}`;
  }
  // alert('카카오 공유')
  if (window.Kakao) {
    if (!window.Kakao.isInitialized()) {
      const kakao = window.Kakao;
      kakao.init('7697fad3b3b425297ca7967470153751');
    }
    const kakao = window.Kakao;
    kakao.Link.sendDefault({
      // container: '#kakaotalk-sharing-btn',
      objectType: 'feed', // 카카오 링크 공유 여러 type들 중 feed라는 타입 -> 자세한 건 카카오에서 확인
      content: {
        title: '무료 프리스타일 리브레 받기', // 인자값으로 받은 title
        description: '매일 출석하고 프리스타일 리브레 응모권을 받으세요', // 인자값으로 받은 title
        imageUrl:
          'https://glucofit-image.s3.ap-northeast-2.amazonaws.com/LOG/Frame+633959.png',
        link: {
          mobileWebUrl: url, // 인자값으로 받은 route(uri 형태)
          webUrl: url,
        },
      },
      buttons: [
        {
          title: '응모하기',
          link: {
            mobileWebUrl: url,
            webUrl: url,
          },
        },
      ],
    });
  } else {
    console.log('kakao 없음');
  }
};

const RaffleScratch = () => {
  const boomImage =
    'https://glucofit-image.s3.ap-northeast-2.amazonaws.com/LOG/img_lottery_boom.png';
  const coinImage =
    'https://glucofit-image.s3.ap-northeast-2.amazonaws.com/LOG/img_coin.png';
  const [isScratched, setIsScratched] = useState(false);
  const [lotteryInfo, setLotteryInfo] = useState(null);

  const fetchPrizeData = async () => {
    try {
      const authToken = localStorage.getItem('access_token');

      const response = await axios.post(
        `${API_BASE_URL}/raffle/enter`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + authToken,
          },
        },
      );
      return response.data; // axios는 자동으로 JSON을 파싱하여 반환
    } catch (error) {
      console.error('Failed to fetch data:', error);
      return null;
    }
  };

  useEffect(() => {
    const loadData = async () => {
      const data = await fetchPrizeData();

      if (data === null) {
        window.location.href = '/raffle';
      }

      if (data?.prize != null) {
        // 당첨
        if (data?.prize.type === 'COFFEE') {
          setLotteryInfo({
            title: '아메리카노 당첨!',
            subTitle: '축하드립니다!',
            description: (
              <div>
                아메리카노에 당첨 되셨어요!
                <br />
                <strong>이벤트 종료 후</strong> 일괄적으로
                <br />
                상품을 발송해드릴게요!
              </div>
            ),
            imgSrc:
              'https://glucofit-image.s3.ap-northeast-2.amazonaws.com/240418_coffee.png',
          });
        } else if (data.prize.type === 'LIBRE') {
          setLotteryInfo({
            title: '프리스타일 리브레 당첨!',
            subTitle: '축하드립니다!',
            description: (
              <div>
                프리스타일 리브레에 당첨 되셨어요!
                <br />
                <strong>아래 구글폼을 작성</strong>해주시면
                <br />
                프리스타일 리브레를 발송해드릴게요
              </div>
            ),
            imgSrc:
              'https://glucofit-image.s3.ap-northeast-2.amazonaws.com/LOG/img_raffle_libre.png',
          });
        }
      }
    };

    loadData();
  }, []); // userId가 변경될 때마다 새로 데이터를 불러옴

  if (!isScratched) {
    return (
      <LargeLayout>
        <Layout>
          <Container>
            <ContentsContainer>
              <MainContainer>
                <MainRoof>글핏복권</MainRoof>
                <CoinImageContainer>
                  <CoinImageWrapper>
                    <img src={coinImage} />
                  </CoinImageWrapper>
                </CoinImageContainer>
                <TitleContainer>
                  <Title1>글핏복권</Title1>
                  <Title2>을 긁어주세요!</Title2>
                </TitleContainer>
                <SubTitle>과연..결과는?!</SubTitle>
                <ScratchCardContainer>
                  <ScratchCard
                    cover={lotteryCoverImage}
                    percent={50}
                    width={280}
                    height={112}
                    onComplete={() => {
                      // 다 긁음
                      setIsScratched(true);
                    }}
                  >
                    <ImageContainer>
                      <ImageWrapper>
                        <img
                          src={
                            lotteryInfo === null
                              ? boomImage
                              : lotteryInfo.imgSrc
                          }
                          width={10}
                          height={10}
                          object-fit="cover"
                        />
                      </ImageWrapper>
                    </ImageContainer>
                  </ScratchCard>
                </ScratchCardContainer>
              </MainContainer>
            </ContentsContainer>
          </Container>
        </Layout>
      </LargeLayout>
    );
  }

  return (
    <LargeLayout>
      <Layout>
        <Container>
          <ContentsContainer>
            <MainContainer>
              <MainRoof>글핏복권</MainRoof>
              {lotteryInfo === null ? (
                <BoomContainer>
                  <BoomImageContainer>
                    <BoomImageWrapper>
                      <img src={boomImage} />{' '}
                    </BoomImageWrapper>
                  </BoomImageContainer>
                  <BoomTitleContainer>
                    <BoomTitle1>꽝...!</BoomTitle1>
                    <BoomTitle2>다음기회에</BoomTitle2>
                  </BoomTitleContainer>
                </BoomContainer>
              ) : (
                <WinningContainer>
                  <WinningImageContainer>
                    <WinningImageWrapper>
                      <img src={lotteryInfo.imgSrc} />
                    </WinningImageWrapper>
                  </WinningImageContainer>
                  <WinningTitle>{lotteryInfo.title}</WinningTitle>
                  <WinningSubTitle>{lotteryInfo.subTitle}</WinningSubTitle>
                </WinningContainer>
              )}
              {lotteryInfo === null ? (
                <SharingTitle>
                  친구에게 공유하면
                  <br />
                  <strong>추가 응모권을</strong>드려요
                </SharingTitle>
              ) : (
                <WinningDescription>
                  {lotteryInfo.description}
                </WinningDescription>
              )}
              <StyledButton onClick={sendKakao}>친구에게 공유하기</StyledButton>
            </MainContainer>
            <HomeLink to="/raffle">홈으로 가기</HomeLink>
          </ContentsContainer>
        </Container>
      </Layout>
    </LargeLayout>
  );
};

const LargeLayout = styled.div`
  background: ${palette.Gray900};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const ContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  width: 100vw;
  padding-left: 20px;
  padding-right: 20px;
  background: ${palette.Gray900};
  height: 100%;
`;

const MainContainer = styled.div`
  position: relative;
  width: 100%;
  // margin-top: 164px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 36px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 16px;
  border: 1px solid #ebebeb;
  background: #fff;
  backdrop-filter: blur(2px);
`;

const MainRoof = styled.div`
  position: absolute;
  top: -20px;
  align-self: center;
  text-align: center;
  padding: 10px 24px 6px 24px;
  justify-content: center;
  border-radius: 50px;
  font-family: 'Noto Sans KR', sans-serif; /* Noto Sans KR로 변경 */
  font-size: 16px;
  font-weight: 700;
  line-height: 130%; /* 20.8px */
  background: ${palette.FitMint500};
`;

const ScratchCardContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
`;

const BoomContainer = styled.div`
  justify-content: center;
  padding-top: 16.5px;
  padding-bottom: 16.5px;
  margin-top: 6px;
  border-radius: 16px;
  border: 1px solid #e7e7e7;
  background: #f3f3f3;
`;

const WinningContainer = styled.div`
  justify-content: center;
  padding-top: 16.5px;
  padding-bottom: 16.5px;
  margin-top: 6px;
  border-radius: 16px;
  border: 1px solid #e7e7e7;
  background: #f3f3f3;
  background-image: url(${lotteryWinningBackground});
  background-size: cover;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

const Title1 = styled.div`
  color: ${palette.FitMint700};
  text-align: center;
  text-decoration: underline;
  font-family: 'Noto Sans KR', sans-serif; /* Noto Sans KR로 변경 */
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 20.8px */
`;

const Title2 = styled.div`
  color: ${palette.Gray900};
  text-align: center;
  font-family: 'Noto Sans KR', sans-serif; /* Noto Sans KR로 변경 */
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 20.8px */
`;

const BoomTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10.5px;
  align-items: center;
`;

const WinningTitle = styled.div`
  margin-top: 8px;
  margin-bottom: 4px;
  color: ${palette.Gray900};
  text-align: center;
  font-family: 'Noto Sans KR', sans-serif; /* Noto Sans KR로 변경 */
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
`;

const WinningSubTitle = styled.div`
  color: ${palette.Gray800};
  text-align: center;
  font-family: 'Noto Sans KR', sans-serif; /* Noto Sans KR로 변경 */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
`;

const WinningDescription = styled.div`
  margin-top: 20px;
  color: ${palette.Gray600};
  text-align: center;
  font-family: 'Noto Sans KR', sans-serif; /* Noto Sans KR로 변경 */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 20.8px */
`;

const BoomTitle1 = styled.div`
  color: ${palette.Gray900};
  text-align: center;
  font-family: 'Noto Sans KR', sans-serif; /* Noto Sans KR로 변경 */
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
`;

const BoomTitle2 = styled.div`
  margin-left: 4px;
  color: ${palette.Gray600};
  text-align: center;
  font-family: 'Noto Sans KR', sans-serif; /* Noto Sans KR로 변경 */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
`;

const SharingTitle = styled.div`
  margin-top: 20px;
  color: ${palette.Gray600};
  text-align: center;
  font-family: 'Noto Sans KR', sans-serif; /* Noto Sans KR로 변경 */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 20.8px */
`;

const SubTitle = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  color: ${palette.Gray600};
  font-family: 'Noto Sans KR', sans-serif; /* Noto Sans KR로 변경 */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  line-height: 150%; /* 18px */
`;

const CoinImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CoinImageWrapper = styled.div`
  display: flex;
  width: 42px;
  justify-content: center; /* 가로 방향으로 중앙 정렬 */
`;

const BoomImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BoomImageWrapper = styled.div`
  display: flex;
  width: 74px;
  height: 74px;
  justify-content: center; /* 가로 방향으로 중앙 정렬 */
`;

const WinningImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WinningImageWrapper = styled.div`
  display: flex;
  width: 79px;
  height: 79px;
  justify-content: center; /* 가로 방향으로 중앙 정렬 */
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 100px;
  justify-content: center; /* 가로 방향으로 중앙 정렬 */
`;

export const StyledButton = styled.button`
  margin-top: 20px;
  background-color: ${palette.Gray900};
  color: ${palette.FitMint500}; /* 흰색 텍스트 적용 */
  font-family: 'Noto Sans KR', sans-serif; /* Noto Sans KR로 변경 */
  opacity: ${(props) => (props.isEnabled ? 0.5 : 1)};
  border: none;
  line-height: 150%;
  font-size: 16px;
  font-weight: 700;
  padding: 12px 24px; /* 적당한 크기의 패딩을 적용 */
  border-radius: 16px;
  cursor: pointer;
  display: inline-block;
  text-align: center; /* 텍스트 중앙 정렬 */

  &:hover {
    background-color: ${palette.FitMint600};
  }
`;

const HomeLink = styled(Link)`
  margin-top: 24px;
  text-decoration: underline;
  color: ${palette.Gray300};
  font-family: 'Noto Sans KR', sans-serif; /* Noto Sans KR로 변경 */
  font-size: 14px;
  font-weight: 500;
`;

export default RaffleScratch;
