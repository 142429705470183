import { useEffect, useRef, useState } from 'react';
import '../CSS/OrderForm.scss';
import { useLocation } from 'react-router-dom';

export default function OrderForm({
  order,
  setOrder,
  handleOrder,
  handleFindAddressClick,
  theme = 'main',
}) {
  const detailAddressRef = useRef();
  const { state } = useLocation();
  const [isChecked, setIsChecked] = useState(
    order.userName.value || order.userPhoneNumber.value,
  );

  const handleFindAddress = (e) => {
    handleFindAddressClick(e);
    scrollToDetailAddress();
  };

  const scrollToDetailAddress = () => {
    if (detailAddressRef.current) {
      detailAddressRef.current.scrollIntoView({ block: 'center' });
      detailAddressRef.current.focus();
    }
  };

  const handleActualUserCheckButtonClick = () => {
    setOrder((prev) => ({
      ...prev,
      userName: { ...prev.userName, value: '', invalid: false },
      userPhoneNumber: {
        ...prev.userPhoneNumber,
        value: '',
        invalid: false,
      },
    }));
    setIsChecked((prev) => !prev);
  };

  // sensoronly 페이지에서 주소찾기 버튼을 눌렀을 때, 팝업이 아닌 페이지 이동 방식이어서 다시 redirect 되었을 때, 기존 값 유지
  useEffect(() => {
    if (state?.order && setOrder) {
      setOrder((prev) => ({
        ...prev,
        buyerName: { ...prev.buyerName, value: state.order.buyerName },
        buyerPhoneNumber: {
          ...prev.buyerPhoneNumber,
          value: state.order.buyerPhoneNumber,
        },
        userName: { ...prev.userName, value: state.order.userName },
        userPhoneNumber: {
          ...prev.userPhoneNumber,
          value: state.order.userPhoneNumber,
        },
        payMethod: { ...prev.payMethod, value: state.order.payMethod },
        address: {
          ...prev.address,
          value: state.order.address,
          invalid: false,
        },
        postcode: {
          ...prev.postcode,
          value: state.order.postcode,
          invalid: false,
        },
      }));
      setIsChecked(state?.order?.userName || state?.order?.userPhoneNumber);
      scrollToDetailAddress();
    }
  }, [state]);

  return (
    <div className="form-container">
      <div className="form-info">
        <label htmlFor="buyerName">이름(실명)</label>
        <div>
          <input
            name="buyerName"
            id="buyerName"
            className={order.buyerName.invalid ? 'invalid' : undefined}
            value={order.buyerName.value}
            placeholder="어플 승인을 위해 실명으로 입력해 주세요"
            onChange={handleOrder}
          />
          {order.buyerName.invalid && (
            <div className="invalid-text">이름을 입력해 주세요.</div>
          )}
        </div>
      </div>
      <div className="form-info">
        <label htmlFor="buyerPhoneNumber">휴대폰 번호</label>
        <div>
          <input
            id="buyerPhoneNumber"
            name="buyerPhoneNumber"
            className={order.buyerPhoneNumber.invalid ? 'invalid' : undefined}
            value={order.buyerPhoneNumber.value}
            placeholder="휴대폰 번호"
            inputMode="numeric"
            onChange={handleOrder}
          />
          {order.buyerPhoneNumber.invalid && (
            <div className="invalid-text">휴대폰 번호를 입력해 주세요.</div>
          )}
        </div>
      </div>
      <div className="form-info">
        <label htmlFor="postCode">제품 배송지</label>
        <div>
          <div className="find-address">
            <input
              id="postcode"
              name="postcode"
              className={order.postcode.invalid ? 'invalid' : undefined}
              value={order.postcode.value}
              readOnly
              onClick={handleFindAddress}
            />
            <button
              id="find-address-button"
              className={theme}
              onClick={handleFindAddress}
            >
              주소찾기
            </button>
          </div>
          <input
            id="address"
            name="address"
            className={order.postcode.invalid ? 'invalid' : undefined}
            value={order.address.value}
            placeholder="도로명 주소 (예: 서울특별시 강남구 테헤란로 217)"
            readOnly
          />
          {order.postcode.invalid && (
            <div className="invalid-text">주소를 입력해 주세요.</div>
          )}
          <input
            id="detailAddress"
            name="detailAddress"
            className={order.detailAddress.invalid ? 'invalid' : undefined}
            value={order.detailAddress.value}
            placeholder="상세주소 (예: 101동 101호)"
            onChange={handleOrder}
            ref={detailAddressRef}
          />
          {order.detailAddress.invalid && (
            <div className="invalid-text">상세주소를 입력해 주세요.</div>
          )}
        </div>
      </div>
      <div className="user-check">
        <input type="checkbox" id="userCheck" checked={isChecked} readOnly />
        <label htmlFor="userCheck" onClick={handleActualUserCheckButtonClick}>
          구매자와 실 사용자가 달라요(선물 할게요)
        </label>
      </div>
      {isChecked && (
        <>
          <div className="payment-subtitle">실 사용자 정보</div>
          <div className="form-info">
            <label htmlFor="buyerName">이름(실명)</label>
            <div>
              <input
                name="userName"
                id="userName"
                className={order.userName.invalid ? 'invalid' : undefined}
                value={order.userName.value}
                placeholder="어플 승인을 위해 실명으로 입력해 주세요"
                onChange={handleOrder}
              />
              {order.userName.invalid && (
                <div className="invalid-text">이름을 입력해 주세요.</div>
              )}
            </div>
          </div>
          <div className="form-info">
            <label htmlFor="userPhoneNumber">휴대폰 번호</label>
            <div>
              <input
                id="userPhoneNumber"
                name="userPhoneNumber"
                className={
                  order.userPhoneNumber.invalid ? 'invalid' : undefined
                }
                value={order.userPhoneNumber.value}
                placeholder="휴대폰 번호"
                inputMode="numeric"
                onChange={handleOrder}
              />
              {order.userPhoneNumber.invalid && (
                <div className="invalid-text">휴대폰 번호를 입력해 주세요.</div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
