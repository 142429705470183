// palette.js

export const palette = {
  Background: '#CFE3D7',
  Gray900: '#141414',
  Gray800: '#222222',
  Gray700: '#3D3D3D',
  Gray600: '#5C5C5C',
  Gray500: '#707070',
  Gray300: '#B8B8B8',
  LightGray: '#E6E6E6',
  FitMint700: '#08A68C',
  FitMint600: '#08A68C',
  FitMint500: '#44E3C8',
  White: '#FFFFFF',
};

export const fontSize = {
  small: '10px',
  normal: '12px',
  emphasize: '14px',
  big: '16px',
};
