import React from 'react';
import styled from 'styled-components';
import { palette } from 'components/Raffle/palette';

export const SmsTitle = styled.div`
  margin-top: 48px;
  margin-bottom: 20px;
  color: ${palette.White};
  font-family: 'Noto Sans KR', sans-serif; /* Noto Sans KR로 변경 */
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  @media (max-width: 800px) {
    color: ${palette.White};
    font-family: 'Noto Sans KR', sans-serif; /* Noto Sans KR로 변경 */
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }
`;

export const SmsTitle2 = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  color: ${palette.White};
  font-family: 'Noto Sans KR', sans-serif; /* Noto Sans KR로 변경 */
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  @media (max-width: 800px) {
    color: ${palette.White};
    font-family: 'Noto Sans KR', sans-serif; /* Noto Sans KR로 변경 */
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }
`;

export const SmsInput = styled.div`
  @media (max-width: 800px) {
    color: var(--Gray-Gray800, #222);
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }
`;

export const SmsCheckList = styled.div`
  @media (max-width: 800px) {
    color: var(--Gray-Gray800, #222);
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }
`;

export const StyledButton = styled.button`
  background-color: ${palette.FitMint500};
  color: ${palette.Gray900}; /* 흰색 텍스트 적용 */
  font-family: 'Noto Sans KR', sans-serif; /* Noto Sans KR로 변경 */
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  border: none;
  line-height: 150%;
  font-size: 16px;
  font-weight: 700;
  padding: 12px 24px; /* 적당한 크기의 패딩을 적용 */
  border-radius: 16px;
  cursor: pointer;
  display: inline-block;
  text-align: center; /* 텍스트 중앙 정렬 */
  margin-top: 12px; /* 상하좌우에 약간의 마진 추가 */
  margin-bottom: 12px; /* 상하좌우에 약간의 마진 추가 */

  &:hover {
    background-color: ${palette.FitMint600};
  }
`;

export const SmsCodeButton = styled.button`
  flex: 0.4;
  background-color: ${(props) =>
    props.isEnabled ? palette.Gray300 : '#4c4c4c'};
  border: none;
  margin-top: 10px;
  margin-bottom: 1px;
  margin-left: 4px;
  padding: 16px 20px;
  border-radius: 8px;
  cursor: pointer;
  text-align: center; /* 텍스트 중앙 정렬 */
  color: ${palette.Gray800};
  text-align: center;
  font-family: 'Noto Sans KR', sans-serif; /* Noto Sans KR로 변경 */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 21px */
`;

export const SmsCodeVerificationButton = styled.button`
  width: 100%;
  background-color: ${palette.Gray300};
  border: none;
  margin-top: 10px;
  margin-bottom: 1px;
  margin-left: 4px;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  text-align: center; /* 텍스트 중앙 정렬 */
  color: ${palette.Gray800};
  text-align: center;
  font-family: 'Noto Sans KR', sans-serif; /* Noto Sans KR로 변경 */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 21px */
`;
