import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function PostCode(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const element_wrap = useRef(null);

  useEffect(() => {
    new window.daum.Postcode({
      oncomplete: function (data) {
        let addr = '';

        if (data.userSelectedType === 'R') {
          addr = data.roadAddress;
        } else {
          addr = data.jibunAddress;
        }

        if (state?.redirectPath) {
          navigate(state.redirectPath, {
            state: {
              productId: state?.productId,
              order: {
                ...state?.order,
                postcode: data.zonecode,
                address: addr,
              },
            },
          });
          return;
        }

        const savedURL = localStorage.getItem('savedURL');
        if (savedURL) {
          // navigate(`/invite?code=${encodeURIComponent(localStorage.getItem('code'))}`, {
          //   state: { postcode: data.zonecode, address: addr },
          // });
          navigate('/invitepayment', {
            state: { postcode: data.zonecode, address: addr },
          });
        } else {
          element_wrap.current.style.display = 'none';
          navigate('/payment', {
            state: { postcode: data.zonecode, address: addr },
          });
        }
      },
      onresize: function (size) {
        element_wrap.current.style.height = size.height + 'px';
      },
      width: '100%',
      height: '100%',
    }).embed(element_wrap.current);

    element_wrap.current.style.display = 'block';
  }, []);

  return (
    <div>
      <div
        ref={element_wrap}
        style={{
          display: 'none',
          border: '1px solid',
          width: '100%',
          height: '100%',
          margin: '5px 0',
          position: 'relative',
        }}
      />
    </div>
  );
}

export default PostCode;
