import React, { useEffect } from 'react';
import axios from 'axios';

const CertificationComponent = () => {
  useEffect(() => {
    // jQuery 스크립트를 로드합니다.
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = resolve;
        script.onerror = () =>
          reject(new Error(`Script load error for ${src}`));
        document.head.appendChild(script);
      });
    };

    // jQuery와 IMP 스크립트를 로드합니다.
    const initializeScripts = async () => {
      try {
        // await loadScript('https://code.jquery.com/jquery-1.12.4.min.js');
        // await loadScript('https://cdn.iamport.kr/v1/iamport.js');

        // IMP 초기화 및 인증 요청
        const { IMP } = window;
        IMP.init('imp22561845');

        IMP.certification(
          {
            pg: 'inicis_unified.MIIiasTest',
            merchant_uid: 'ORD20240531-000001',
            m_redirect_url: 'https://glucofit.co.kr/',
            // popup: true,
          },
          function (rsp) {
            console.log('rsp...', rsp);
            if (rsp.success) {
              console.log('요청합니다...', rsp.imp_uid);
              axios
                .post(
                  'http://localhost:4000/ciauth',
                  {
                    app_id: '',
                    imp_uid: rsp.imp_uid,
                  },

                  {
                    headers: { 'Content-Type': 'application/json' },
                  },
                )
                .then((response) => {
                  console.log('Success IMP.certification');
                  window.location.href = '/';
                })
                .catch((error) => {
                  console.log('Error during AJAX request:', error);
                });
            } else {
              console.log('Fail IMP.certification');
            }
          },
        );
      } catch (error) {
        console.error('Error initializing scripts:', error);
      }
    };

    initializeScripts();
  }, []);

  return (
    <div>
      <h1>Certification...</h1>
    </div>
  );
};

export default CertificationComponent;
