// palette.js

export const palette = {
  Background: '#CFE3D7',
  Black: '#000000',
  Gray900: '#141414',
  Gray800: '#222222',
  Gray700: '#3D3D3D',
  Gray600: '#5C5C5C',
  Gray550: '#8F8F8F',
  Gray500: '#707070',
  Gray400: '#BDBDBD',
  Grey300: '#E0E0E0', // replace with the actual color you want for "not checked"
  Gray200: '#DBDBDB',
  Gray100: '#F6F6F6',
  LightGray: '#E6E6E6',
  FitMint700: '#08A68C',
  FitMint600: '#10CCAD',
  FitMint500: '#44E3C8',
  White: '#FFFFFF',
  Blue600: '#4291FF',
  Blue100: '#E8F2FF',
  Orange600: '#EB6D13',
  Mint600: '#10CCAD',
  Red500: '#FF4D4F',
  Red400: '#FF7875',
  AnyBlue: '#4889ff',
  AnyBlue100: '#4889ff30',
};

export const fontSize = {
  small: '10px',
  normal: '12px',
  emphasize: '14px',
  big: '16px',
};
