import React from 'react';
import '../CSS/Terms.scss';
import CheckBox from 'assets/icons/ic-checkbox.svg';
import CheckBoxChecked from 'assets/icons/ic-checkbox-checked.svg';

function Terms({ order, handleOrder, type, termMessage }) {
  return (
    <div className="payment-terms-container">
      <div className="sensor-count-bottom">
        <label className="sensor-count-terms" htmlFor="terms1">
          <input
            type="checkbox"
            name="terms1"
            id="terms1"
            value={order.terms1.value}
            onChange={handleOrder}
          />
          {order.terms1.value ? (
            <img src={CheckBoxChecked} alt="checked" />
          ) : (
            <img src={CheckBox} alt="unchecked" />
          )}
          <span className="color">(필수) </span>
          {termMessage}
        </label>
        <label className="sensor-count-terms" htmlFor="terms2">
          <input
            type="checkbox"
            name="terms2"
            id="terms2"
            value={order.terms2.value}
            onChange={handleOrder}
          />
          {order.terms2.value ? (
            <img src={CheckBoxChecked} alt="checked" />
          ) : (
            <img src={CheckBox} alt="unchecked" />
          )}
          <span className="color">(필수) </span>
          <a
            href="https://lansik.notion.site/30e6fc8b10f84fddb72338f956e1f2c8?pvs=4"
            style={{ textDecoration: 'underline' }}
          >
            이용약관
          </a>
          에 환불규정(13조)을 <br /> 확인했습니다.
        </label>
      </div>
    </div>
  );
}

export default Terms;
