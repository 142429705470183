export const ONEMONTH = '1개월멤버십_일반결제_45';
export const ONEYEAR = '1년멤버십_정기결제_365';
export const SIXMONTH = '6개월멤버십_정기결제_180';

export const REAL_SERVER = 'https://glucofit-api.lansik.us/graphql/';
export const DEV_SERVER = 'https://glucofit-api-dev.lansik.us/graphql/';
export const LOCAL_SERVER = 'http://192.168.35.5:4000/graphql/';
export const TARGET_SERVER = REAL_SERVER;

export const MEMBERSHIP_MONTH_PRICE = 25000;
export const MEMBERSHIP_MONTH_PRICE_DISCOUNT = 22500;
export const CGM_PRICE = 88000;

export const MEMBERSHIP_PRODUCT_ID = {
  2: 18,
  4: 19,
  8: 20,
  12: 21,
  24: 22,
};

export const MEMBERSHIP_YEARLY_PRICE = {
  4: 54300,
  8: 84000,
  12: 113000,
  24: 201000,
};
export const MEMBERSHIP_YEARLY_PRICE_DISCOUNT = {
  4: 51800,
  8: 81500,
  12: 110500,
  24: 198500,
};
export const MEMBERSHIP_YEARLY_DEFAULT_DISCOUNT = {
  TYPE: 'yearly',
  WORD: '1년',
  PRICE: MEMBERSHIP_YEARLY_PRICE_DISCOUNT[4],
  SENSOR_COUNT: 4,
};

export const MEMBERSHIP_YEARLY_DEFAULT = {
  TYPE: 'yearly',
  WORD: '1년',
  PRICE: MEMBERSHIP_YEARLY_PRICE[4],
  SENSOR_COUNT: 4,
};
export const MEMBERSHIP_MONTHLY_DEFAULT_DISCOUNT = {
  TYPE: 'monthly',
  WORD: '1개월',
  PRICE: 246000,
  SENSOR_COUNT: 2,
};
export const MEMBERSHIP_MONTHLY_DEFAULT = {
  TYPE: 'monthly',
  WORD: '1개월',
  PRICE: 276000,
  SENSOR_COUNT: 2,
};
