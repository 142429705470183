import './CSS/WelstoryPayment.scss';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Footer from 'components/Footer/Footer';
import ProductImg from 'assets/images/ver3.0/img-welstory-product.png';
import useOrder from 'hooks/order/useOrder';
import { useProduct } from 'hooks/product/useProduct';
import useDiscount from 'hooks/price/useDiscount';
import OrderForm from 'pages/Payment/Components/OrderForm';
import PaymentMethodSelector from 'pages/Payment/Components/PaymentMethodSelector';
import usePayment from 'hooks/payment/usePayment';
import { useLocation, useNavigate } from 'react-router-dom';
import MobileHeader from 'components/Nav/MobileHeader';

function WelstoryPayment() {
  const ref = useRef();
  const naviagte = useNavigate();
  const { state } = useLocation();
  const [productCount, setProductCount] = useState(1);

  const { order, setOrder, handleOrder, validateOrder } = useOrder();
  const {
    PRODUCT_TYPE,
    products,
    selectedProduct,
    setSelectedProductById,
    setDiscount,
  } = useProduct();
  const { createOrder, requestPay, completeOrder } = usePayment();
  const { discount, applyDiscount } = useDiscount();

  const handlePayment = async () => {
    const test = false;
    const mRedirectUrl = test
      ? 'http://221.139.227.62:16560/redirectsensoronly'
      : 'https://glucofit.co.kr/redirectsensoronly';
    if (!validateOrder(['terms1', 'terms2'])) {
      return alert('구매자 정보를 입력해주세요.');
    }
    const merchantUid = await createOrder({
      order: order,
      productId: selectedProduct.id,
      productCount: productCount,
      totalPrice:
        (selectedProduct.price - selectedProduct.discount) * productCount,
      couponNumber: discount?.coupon_number,
    });
    const impUid = await requestPay({
      order: order,
      merchantUid: merchantUid,
      productId: selectedProduct.id,
      productPrice:
        (selectedProduct.price - selectedProduct.discount) * productCount,
      mRedirectUrl: mRedirectUrl,
      interestFree: false,
    });
    completeOrder({
      productId: selectedProduct.id,
      impUid: impUid,
      merchantUid: merchantUid,
      redirectPath: '/successreward',
    });
  };

  const handleFindAddress = () => {
    naviagte('/postcode', {
      state: {
        redirectPath: '/welstory/payment',
        productId: selectedProduct.id,
        order: {
          buyerName: order.buyerName.value,
          buyerPhoneNumber: order.buyerPhoneNumber.value,
          userName: order.userName.value,
          userPhoneNumber: order.userPhoneNumber.value,
          payMethod: order.payMethod.value,
          productCount: productCount,
        },
      },
    });
  };

  const handlePlusClick = () => {
    setProductCount((prev) => prev + 1);
  };

  const handleMinusClick = () => {
    setProductCount((prev) => (prev > 1 ? prev - 1 : prev));
  };

  useLayoutEffect(() => {
    applyDiscount(setDiscount);
    setSelectedProductById(
      state?.productId ||
        products.find((p) => p.type === PRODUCT_TYPE.WELSTORY).id,
    );
  }, []);

  useEffect(() => {
    if (!state) {
      window.scrollTo(0, 0);
    }
    if (state?.order?.productCount) {
      setProductCount(state.order.productCount);
    }
  }, []);

  if (selectedProduct === null) return null;
  return (
    <div>
      <MobileHeader
        centerText="결제"
        onClickLeft={() => naviagte('/welstory')}
      />
      <div className="sensoronly-payment-body">
        <div className="payment-left">
          <div>
            <div className="sensorOnly-subtitle">주문 상품 정보</div>
            <div className="sensorOnly-product-container">
              {products
                .filter((p) => p.type === PRODUCT_TYPE.WELSTORY)
                .map((p) => (
                  <div key={p.id}>
                    <input
                      type="radio"
                      name="sensor-only-product"
                      id={p.id}
                      onClick={() => setSelectedProductById(p.id)}
                      checked={selectedProduct.id === p.id}
                      readOnly
                    />
                    <label htmlFor={p.id}>
                      <div className="product-img">
                        <img src={ProductImg} id="welstory-img" alt="sensor" />
                        {p.sensorCount > 1 && (
                          <div className="count">
                            X<span>{p.sensorCount}</span>
                          </div>
                        )}
                      </div>
                      <div className="product-info">
                        <div className="product-description">{p.content}</div>
                        <div className="product-sensor-price strong">
                          {(p.price - p.discount).toLocaleString()}원
                          <span className="product-discount-percent">
                            {Math.ceil((p.discount / p.price) * 100)}% 할인
                          </span>
                          <span className="product-original-price">
                            {p.price.toLocaleString()}원
                          </span>
                        </div>
                      </div>
                      <div className="product-counter">
                        <button
                          className="minus"
                          disabled={productCount <= 1}
                          onClick={handleMinusClick}
                        >
                          -
                        </button>
                        <div className="count">{productCount}</div>
                        <button className="plus" onClick={handlePlusClick}>
                          +
                        </button>
                      </div>
                    </label>
                  </div>
                ))}
            </div>
          </div>
          <div>
            <div className="sensorOnly-subtitle">결제 금액</div>
            <div className="payment-total-container-top">
              <div>
                <div className="payment-total-textContainer">
                  <div>총 상품 금액</div>
                  <div>
                    {(selectedProduct.price * productCount).toLocaleString()}원
                  </div>
                </div>
                {selectedProduct.discount > 0 && (
                  <div className="payment-total-textContainer">
                    <div>할인</div>
                    <div>
                      -
                      {(
                        selectedProduct.discount * productCount
                      ).toLocaleString()}
                      원
                    </div>
                  </div>
                )}
              </div>
              <div className="payment-total-price">
                <div>최종 결제 금액</div>
                <div>
                  <div className="green3">
                    {(
                      (selectedProduct.price - selectedProduct.discount) *
                      productCount
                    ).toLocaleString()}
                    원
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="sensorOnly-subtitle" ref={ref}>
              구매자 정보
            </div>
            <OrderForm
              order={order}
              setOrder={setOrder}
              handleFindAddressClick={handleFindAddress}
              handleOrder={handleOrder}
              theme="second"
            />
            <div className="sensorOnly-subtitle">결제 수단</div>
            <PaymentMethodSelector
              order={order}
              handleOrder={handleOrder}
              interestFreeInfo={false}
            />
          </div>
        </div>
        <div className="payment-right">
          <div className="payment-total-container-bottom">
            <div className="payment-total-subtitle">결제 금액</div>
            <div>
              <div className="payment-total-textContainer">
                <div>총 상품 금액</div>
                {(selectedProduct.price * productCount).toLocaleString()}원
              </div>
              {selectedProduct.discount > 0 && (
                <div className="payment-total-textContainer">
                  <div>할인</div>
                  <div>
                    -
                    {(selectedProduct.discount * productCount).toLocaleString()}
                    원
                  </div>
                </div>
              )}
            </div>
            <div className="payment-total-price">
              <div>최종 결제 금액</div>
              <div>
                <div className="green3">
                  {(
                    (selectedProduct.price - selectedProduct.discount) *
                    productCount
                  ).toLocaleString()}
                  원
                </div>
              </div>
            </div>
          </div>
          <button
            className="payment-totalAccept-button strong clicked"
            onClick={handlePayment}
          >
            {(
              (selectedProduct.price - selectedProduct.discount) *
              productCount
            ).toLocaleString()}
            원 결제하기
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default WelstoryPayment;
