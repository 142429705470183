import React from 'react';
import coach from '../../../assets/images/ver3.0/img-coach-1.png';
import coach2 from '../../../assets/images/ver3.0/img-coach-2.png';
import coach3 from '../../../assets/images/ver3.0/img-coach-3.png';
import '../CSS/Profile.scss';
import { logEvent } from './Amplitude';

function Profile(props) {
  const onClickDetail = (url) => {
    logEvent('storydetails_click', 10205);
    window.location.href = url;
    window.gtag('event', 'conversion', {
      send_to: 'AW-10992256925/0WURCMrzqKwYEJ2Pwvko',
    });
  };
  return (
    <div className="container7">
      <div className="profile-title-container">
        <div className="profile-title-eng">
          <span className="strong">Medical Coach and Consultant</span>
        </div>
        <div className="profile-title">
          글루코핏,
          <span className="strong">의사 코치 및 자문단</span>
        </div>
        <div className="profile-subtitle">
          진정한 건강을 향한 여정, <br />
          글루코핏은 전문가와 함께합니다.
        </div>
      </div>
      <div className="profile-container img-right thema1">
        <div className="profile-text">
          <div className="profile-info">
            <div className="profile-info-name strong">의사 양혁용</div>
            <div className="profile-info-title">글루코핏 대표</div>
          </div>
          <div className="profile-message">
            6년간 15kg의 요요가 반복되는 것을 <br />
            직접 경험하고 극복했습니다. <br />
            또한 다이어트와 대사에 관한 논문을 읽고 <br />
            스스로에게 실험하며 <br />
            <span className="strong">
              1600명이 넘는 분들의 다이어트를 도왔습니다.
            </span>
            <br /> <br />
            제가 직접 경험한 <span className="strong">지속가능하고 </span>{' '}
            <br />
            <span className="strong">건강한 혈당 다이어트</span>를 더 많은
            분들이 <br />
            경험할 수 있으면 좋겠습니다.
          </div>
          <button
            className="profile-detail-button"
            onClick={() =>
              onClickDetail(
                'https://blog.glucofit.co.kr/geulrukopis-cangeob-seutori/',
              )
            }
          >
            자세히 보기
          </button>
        </div>
        <div className="profile-img">
          <img src={coach} alt="img" />
        </div>
      </div>
      <div className="profile-container img-left thema2">
        <div className="profile-text">
          <div className="profile-info">
            <div className="profile-info-name strong">이진복 원장</div>
            <div className="profile-info-title">닥터리 가정의학과</div>
          </div>
          <div className="profile-message">
            &#183; 서울대 보건대학원 수료 <br />
            &#183; 대한가정의학회 정회원 <br />
            &#183; 대한 비만학회 정회원 <br /> <br />
            &#183; 전) 고려대 의대 외래교수 <br />
            &#183; 전) 카톨릭 의대 외래교수 <br />
            &#183; 현) 인제대 의대 외래교수 <br />
            &#183; 현) 차의과대학 외래교수 <br /> <br />
            &#183; 13만 구독자 닥터리TV 운영
          </div>
        </div>
        <div className="profile-img">
          <img src={coach2} alt="img" id="leejinbok" />
        </div>
      </div>
      <div className="profile-container img-right thema3">
        <div className="profile-text">
          <div className="profile-info">
            <div className="profile-info-name strong">민혜연 원장</div>
            <div className="profile-info-title">청담에스민 의원</div>
          </div>
          <div className="profile-message">
            <br />
            &#183; 서울대학교 의과대학 졸업 <br />
            &#183; 서울대학교 병원 가정의학과 수련 <br />
            &#183; 전) 서울대학교병원 가정의학과 임상강사 <br /> <br />
            &#183; 전) 오가나피부과 원장 <br />
            &#183; 전) 소요클리닉 원장 <br />
            &#183; 전) 세란병원 피부비만 센터장 <br />
          </div>
        </div>
        <div className="profile-img">
          <img src={coach3} alt="img" />
        </div>
      </div>
    </div>
  );
}

export default Profile;
