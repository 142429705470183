import React from 'react';
import '../CSS/Intro.scss';
import Chat from '../../../assets/images/ver3.0/img-intro-chat-bubble.png';
import KeywordLeft from '../../../assets/images/ver3.0/img-intro-keyword-left.png';
import KeywordRight from '../../../assets/images/ver3.0/img-intro-keyword-right.png';
import ChatMobile from '../../../assets/images/ver3.0/img-intro-chat-bubble-mobile.png';
import ArrowDown from '../../../assets/icons/ic-intro-arrow-down.svg';
function Intro() {
  return (
    <div className="intro-container">
      <div className="intro-title">
        <span className="strong">혹시, 지금</span> <br />
        <span className="strong color">이런 고민</span>하고 있진 <br />
        않으신가요?
      </div>
      <div className="intro-chat-container">
        <div className="intro-chat web">
          <img src={KeywordLeft} alt="" className="left" />
          <img src={KeywordRight} alt="" className="right" />
          <img src={Chat} alt="" />
        </div>
        <div className="intro-chat mobile">
          <img src={ChatMobile} alt="" />
        </div>
      </div>
      <div className="arrow-down">
        <img src={ArrowDown} alt="" />
      </div>
      <div className="intro-title">
        <span className="strong">글루코핏과 함께</span> <br />
        <span className="strong color">혈당 스파이크를 조절</span>하면 <br />
        개선될 수 있어요!
      </div>
    </div>
  );
}

export default Intro;
