import React from 'react';
import styled from 'styled-components';

// 스타일드 컴포넌트를 사용하여 컨테이너를 생성합니다.
const Container = styled.div`
  max-width: 440px; /* 최대 너비를 600px로 제한합니다. */
  margin: 0 auto; /* 가운데 정렬을 합니다. */
  // padding-left: 20px; /* 내부 패딩을 추가합니다. */
  // padding-right: 20px; /* 내부 패딩을 추가합니다. */
  height: 100%;
  background: #141414;
`;

const Background = styled.div`
  background: #141414;
  min-width: 100vw;
  width: 100%;
  display: flex;
  flex-direction: ;
`;

// 너비를 제한한 스타일드 컴포넌트를 사용하는 함수형 컴포넌트를 생성합니다.
const Layout = ({ children }) => {
  return (
    <Background>
      <Container>{children}</Container>;
    </Background>
  );
};

export default Layout;
