import { jwtDecode } from 'jwt-decode';
import { API_BASE_URL } from './config';
import axios from 'axios';
export const validateToken = async (token) => {
  try {
    console.log('token input...', token);
    console.log('API_BASE_URL...', API_BASE_URL);
    console.log('API_BASE_URL...', process.env.ANYFIT_API_URL);
    if (!token) {
      return { result: false, token: null };
    } else {
      // 서버 요청 검증

      const response = await axios.post(`${API_BASE_URL}/api/authMallToken`, {
        token,
      });
      console.log('response...', response.data);
      // return { result: true, code: 0, token: token, data: jwtDecode(token) };

      if (response.data.code == 0) {
        console.log('토큰 서버 검증 - 성공');
        return { result: true, code: 0, token: token, data: jwtDecode(token) };
      } else if (response.data.code == 1) {
        localStorage.setItem('token', token);
        console.log('토큰 - 동의 조항 필요');
        return { result: false, code: 1, token: token, data: jwtDecode(token) };
      } else {
        console.log('토큰 서버 검증 - 실패');
        return { result: false, token: null };
      }
    }
  } catch (err) {
    window.location.href = '/anyfit/error';
    return { result: false, token: null };
  }
};

export const validateStorageToken = async () => {
  // 2) 스토리지에 토큰이 있는지 확인한다.
  const storageToken = localStorage.getItem('token');
  if (storageToken) {
    // if 토큰, 유효성을 확인한다.
    // const validationResult = await this.validateStorageToken(storageToken);

    if (true) {
      //    if 유효하다면, 로컬스토리지에 저장한다 / 종료한다.
      // alert('(test)토큰이 유효합니다.');
      return {
        result: true,
        token: storageToken,
        data: jwtDecode(storageToken),
      };
      //    if 무효라면, 에러메시지를 띄우고, 에러페이지로 이동한다.
    } else {
      return { result: false, token: null };
    }
  }

  if (!storageToken) {
    return { result: false, token: null };
  } else {
    return { result: true, token: storageToken };
  }
};

// 1. URL에서 TOKEN 파라미터 파싱 함수
export const parseTokenFromUrl = () => {
  const params = new URLSearchParams(window.location.search);
  console.log('TOKEN....', params.get('TOKEN'));
  console.log('token....', params.get('token'));
  if (params.get('TOKEN')) return params.get('TOKEN');
  if (params.get('token')) return params.get('token');
};

// 2. 스토리지에서 TOKEN 존재 여부 체크 함수
export const getTokenFromStorage = () => {
  return localStorage.getItem('token');
};
