import React, { useState } from 'react';
import styled from 'styled-components';
import Layout from 'components/Anyfit/common/Layout';
import chevronDown from 'assets/icons/ic_chevron_down_black.svg';
import chevronUp from 'assets/icons/ic_chevron_up_black.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  // min-width: 360px;
  min-height: 120vh;
  width: 100%;
`;

const Title = styled.div`
  color: #141414;
  font-size: 20px;
  font-weight: bold;
  font-family: 'Noto Sans KR', sans-serif;
  margin-top: 60px;
  padding-left: 10px;
`;

const StyledImage = styled.img`
  width: 1.5em;
  height: 1.5em;
  margin-left: 1rem;
`;

const QuestionContainer = styled.div`
  border-bottom: 1px solid
    ${(props) => (props.expanded ? '#FFFFFF' : '#E0E4E4')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px;
`;

const AnswerContainer = styled.div`
  padding: 10px;
  color: #555555; /* 덜 강조된 색상 */
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  max-height: ${(props) => (props.expanded ? '1000px' : '0')};
  opacity: ${(props) => (props.expanded ? '1' : '0')};
  overflow: hidden;
  white-space: pre-line; /* 줄바꿈 적용 */
`;

const questions = [
  {
    id: 1,
    question: '연속혈당측정기(CGM)가 뭔가요?',
    answer:
      '팔 뒤에 부착하는 500원 동전 크기의 작은 센서로 실시간으로 혈당을 측정해주는 기기입니다. 식약처를 통해 안전성과 효과성이 입증 되었습니다.',
  },
  {
    id: 2,
    question: '아프지는 않나요?',
    answer:
      '피에서 측정하는 방식이 아니기 때문에 93%의 회원분들은 통증이 전혀 없다고 말씀하셨습니다. 부착 반응이 궁금한 경우 유튜브에 ‘연속혈당측정기’를 검색해보시면 영상을 확인해보실 수 있습니다.',
  },
  {
    id: 3,
    question: '샤워나 운동 등 일상생활에 문제는 없나요?',
    answer:
      '샤워, 운동, 사우나, 여행 모두 문제 없이 하실 수 있습니다. 물 속에서는 30분까지 있을 수 있습니다. 센서(CGM)는 한 번에 2주 동안 부착하고 있는 기기이고, 2주 후에는 떼서 일반 쓰레기로 버리면 됩니다.',
  },
  {
    id: 4,
    question: '당뇨/임신성 당뇨가 있는 사람도 이용해도 되나요?',
    answer:
      '슈가핏은 의료적 조언이나 치료를 제공하는 서비스가 아닌 영양과 운동 정보 피드백을 제공하는 서비스입니다. \n\n 당뇨는 크게 약물치료와 행동교정 치료 두 가지를 동시에 하는 것이 필요합니다. 약물 치료는 당뇨에 대한 근본적인 해결책이 아닌 혈당만 낮추는 일시적인 치료인 경우가 많고 생활습관 교정을 하는 것이 인슐린 저항성을 낮추는 근본적인 해결책입니다. 약물 치료를 통해 고혈당 상태를 최소한으로 유지할 수 있도록 하고 꼭 생활습관 교정도 함께 진행해주세요.',
  },
  {
    id: 5,
    question: '임산부인데 연속혈당 측정기를 사용해도 괜찮나요?',
    answer:
      '네 괜찮습니다. 이미 임신성 당뇨 환자 분들은 CGM을 부착하고 사용하고 있고, 임신과 전혀 무관하게 사용할 수 있습니다.',
  },
  {
    id: 6,
    question:
      '연속혈당측정기(CGM) 사용 중 고장이 나거나 떨어지면 어떻게 되나요?',
    answer:
      '슈가핏을 통해 구매한 센서는 고객센터로 문의 주시면 사유 확인을 통해 원스톱으로 교환 및 A/S를 도와 드립니다.',
  },
  {
    id: 7,
    question: '슈가핏을 하면 어떤 변화를 기대할 수 있나요?',
    answer:
      '1. 나한테 어떤 음식이 살이 찌고 건강한지를 진짜 데이터를 기반으로 확인할 수 있습니다. 획일적으로 ‘건강하다고 알려진’ 식단이 아닌 나에게 살이 찌는 음식을 직접 눈으로 확인할 수 있습니다.\n\n 2. 살이 찌는 음식을 무작정 끊는 것이 아니라, 똑같은 음식도 더 건강하게 먹으면서 혈당을 조절하는 방법을 알게 되어 지속 가능한 요요없는 식단관리가 가능합니다.\n\n 3. 나에게 맞는 운동 종류와 시간, 최적의 간헐적 단식 시간, 수면 시간 까지 알 수 있게 됩니다. \n\n 4. 혈당 조절은 단순히 다이어트 뿐만 아니라 컨디션 개선, 식곤증 개선, 피부 주름, 여드름 개선에도 효과가 있습니다.',
  },
  {
    id: 8,
    question: '연속혈당측정기(CGM)만 구매해서 사용하는 것과 무엇이 다른가요?',
    answer:
      '연속혈당측정기는 혈당을 측정하기만 하는 용도의 기기입니다. 슈가핏 서비스를 통해 혈당이 왜 올랐는지, 올라갔을 때는 어떻게 해야하는지 인공지능의 분석과 피드백을 받아볼 수 있습니다.',
  },
  {
    id: 9,
    question: '주문한 슈가핏 패키지는 언제 받을 수 있나요?',
    answer:
      '주문한 슈가핏 패키지는 결제 완료 후 2~3일 내로 받아보실 수 있습니다. 다만 배송 상황에 따라 조금 지연될 수 있는 점 양해 부탁드립니다.',
  },
];

const AnyfitFAQ = () => {
  const [index, setIndex] = useState(null);

  const onClickHandler = (idx) => {
    setIndex((prevIndex) => (prevIndex === idx ? null : idx));
  };

  return (
    <div>
      <Layout>
        <Title>자주 묻는 질문</Title>
        <Container>
          {questions.map((item) => (
            <React.Fragment key={item.id}>
              <QuestionContainer
                expanded={index === item.id}
                onClick={() => onClickHandler(item.id)}
              >
                <div>{item.question}</div>
                <StyledImage
                  src={index === item.id ? chevronUp : chevronDown}
                  alt="toggle icon"
                />
              </QuestionContainer>
              <AnswerContainer expanded={index === item.id}>
                {item.answer}
              </AnswerContainer>
            </React.Fragment>
          ))}
        </Container>
      </Layout>
    </div>
  );
};

export default AnyfitFAQ;
