import { gql } from '@apollo/client';

export const GET_LANDINGPAGE_REVIEWS = gql`
  query getLandingPageReviews {
    getLandingPageReviews {
      id
      display
      phone_number
      message
      order {
        id
        status
        payer_phone_number
        payer_name
        recipient_name
        recipient_phone_number
        address
        payment {
          id
          imp_uid
          amount
          description
          payment_cancellations {
            id
            amount
          }
        }
        created_at
        postal_code
        invite_code
        updated_at
      }
      score
      likes_count
      dislikes_count
      contents {
        type
        original_content_url
        resized_content_url
      }
      created_at
      updated_at
      date
      created_by
      product_name
      product_review_count
    }
  }
`;

export const GET_REVIEWS_BY_PAGINATION = gql`
  query getReviewsByPagination(
    $pagination_param_input: pagination_param_input!
  ) {
    getReviewsByPagination(pagination_param_input: $pagination_param_input) {
      id
      display
      phone_number
      message
      order {
        id
        status
        payer_phone_number
        payer_name
        recipient_name
        recipient_phone_number
        address
        payment {
          id
          imp_uid
          amount
          description
          payment_cancellations {
            id
            amount
          }
        }
        created_at
        postal_code
        invite_code
        updated_at
      }
      score
      likes_count
      dislikes_count
      contents {
        type
        original_content_url
        resized_content_url
      }
      created_at
      updated_at
      date
      created_by
      product_name
      product_review_count
    }
  }
`;
