import React from 'react';
import { SmsCodeButton } from '../../styles/RaffleSms.module.js';

const SmsPopUpTextBox = ({ isOpen, close }) => {
  if (!isOpen) return null;

  const style = {
    fontFamily: "'Noto Sans KR', sans-serif",
    fontSize: '12px',
    width: '80%', // Set a specific width
    maxHeight: '60%', // Set a maximum height
    overflowY: 'auto', // Enables vertical scrolling
    // overflowY: 'scroll', // Enables vertical scroll
    // border: '1px solid #ccc', // Optional border
    // padding: '10px', // Padding inside the text box
    // boxSizing: 'border-box', // Includes padding in width and height
    // marginTop: '20px',
    position: 'fixed',
    top: '20%',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: 'white',
    padding: '10px',
    border: '1px solid black',
    zIndex: 100,
  };

  const listStyle = {
    listStyleType: 'disc', // Default bullet points
    marginLeft: '20px', // Adjust spacing to the left of the list
    color: 'black', // Example to change the text color of the list items
  };

  return (
    <div style={style}>
      <h4>글핏복권 개인정보 처리방침</h4>
      <p>본 방침은 2024년 04월 18일부터 적용됩니다.</p>

      <h4>1. 개인정보의 수집 및 이용 목적</h4>
      <ul style={listStyle}>
        <li>글핏복권 회원 가입 및 관리</li>
        <li>글핏복권 서비스 제공</li>
        <li>글핏복권 당첨자 확인</li>
        <li>글핏복권 사용자 분석</li>
      </ul>

      <h4>2. 개인정보의 보유 및 이용기간</h4>
      <ul style={listStyle}>
        <li>
          글핏복권 회원가입 및 관리, 서비스 제공, 당첨자 확인, 사용자 분석:
          글핏복권 이벤트 종료 후 30일
        </li>
      </ul>

      <h4>3. 수집하는 개인정보의 항목</h4>
      <ul style={listStyle}>
        <li>
          글핏복권 회원가입 및 관리, 서비스 제공: 이름, 휴대전화번호, 유입 코드
        </li>
        <li>글핏복권 당첨자 확인: 이름, 휴대전화번호</li>
        <li>
          글핏복권 사용자 분석: IP 정보, 이용 기록, 접속 로그, 쿠키, 접속 기록
          등
        </li>
      </ul>

      <h4>4. 개인정보의 파기절차 및 방법</h4>
      <ul style={listStyle}>
        <li>
          글핏복권은 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
          불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
        </li>
        <li>
          정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이
          달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야
          하는 경우에는, 해당 개인정보를 별도의 데이터 베이스(DB)로 옮기거나
          보관장소를 달리하여 보존합니다.
        </li>
        <li>
          파기절차: 글핏복권은 파기 사유가 발생한 개인정보를 선정하고,
          글핏복권의 개인 정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
        </li>
        <li>
          파기방법: 글핏복권은 전자적 파일 형태로 기록·저장된 개인정보는 기록을
          재생할 수 없도록 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로
          분쇄하거나 소각하여 파기합니다.
        </li>
      </ul>

      <h4>5. 개인정보 제공</h4>
      <ul style={listStyle}>
        <li>
          글핏복권은 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다.
          다만, 아래의 경우에는 예외로 합니다.
        </li>
        <li>이용자들이 사전에 동의한 경우</li>
        <li>
          법령의 규정에 의거하거나, 수사 목적으로 사기관의 요구가 있는 경우
        </li>
      </ul>

      <h4>6. 수집한 개인정보의 위탁</h4>
      <ul style={listStyle}>
        <li>
          (주)써머스플랫폼: 위탁 업무 - 글핏복권 서비스 제공 알림톡 발송, 위탁
          항목 - 전화번호, 보유 및 이용기간 - 알림톡 발송 외 보유 안함
        </li>
        <li>
          (주)네이버클라우드: 위탁 업무 - 본인확인 문자 발송, 위탁 항목 -
          전화번호, 보유 및 이용기간 - 인증 문자 발송 외 보유 안함
        </li>
        <li>
          (유)아마존웹서비시즈코리아: 위탁 업무 - 개인정보가 저장된 인프라 운영,
          위탁 항목 - 서비스 제공을 위해 처리되는 개인정보, 보유 및 이용기간 -
          이용 목적 달성시까지
        </li>
      </ul>

      <h4>7. 이용자 및 법정대리인의 권리와 그 행사방법</h4>
      <p>
        이용자 및 법정 대리인은 언제든지 등록되어 있는 본인의 개인정보를
        조회하거나 수정할 수 있으며 가입 해지를 요청할 수도 있습니다.
      </p>

      <h4>8. 개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항</h4>
      <p>
        글핏복권은 정보주체의 이용정보를 저장하고 수시로 불러오는
        ‘쿠키(cookie)’를 사용하지 않습니다.
      </p>

      <h4>9. 개인정보의 안정성 확보조치에 관한 사항</h4>
      <ul style={listStyle}>
        <li>관리적 조치: 취급 직원 최소화, 정기적 직원 교육</li>
        <li>
          기술적 조치: ‌개인정보처리시스템 등의 접근권한 관리, 접근통제시스템
          설치, 개인정보의 암호화, 보안프로그램 설치 및 갱신
        </li>
      </ul>

      <h4>10. 개인정보 관리 책임자 및 개인정보 열람청구</h4>
      <ul style={listStyle}>
        <li>
          글핏복권은 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보
          처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 개인정보
          보호책임자를 지정하고 있습니다. 성명: 양혁용, 직책: 대표이사, 연락처:
          070-8822-6061
        </li>
        <li>
          정보주체는 「개인정보 보호법」 제35조에 따른 개인정보의 열람 청구를
          아래의 부서에 할 수 있습니다. 부서명: 글루코핏, 담당자: 양혁용,
          연락처: 070-8822-6061
        </li>
      </ul>

      <h4>11. 권익침해 구제방법</h4>
      <ul style={listStyle}>
        <li>
          기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기
          바랍니다. 개인정보분쟁조정위원회: 1833-6972 (www.kopico.go.kr),
          개인정보침해신고센터: 118 (privacy.kisa.or.kr), 대검찰청: 1301
          (www.spo.go.kr), 경찰청: 182 (ecrm.cyber.go.kr)
        </li>
      </ul>

      <h4>12. 개인정보 취급방침 변경에 관한 사항</h4>
      <p>이 개인정보 취급방침은 2024년 04월 18일부터 적용됩니다.</p>

      <h4>[마케팅 및 홍보 활용에 대한 동의 안내]</h4>
      <p>
        (주)랜식의 글핏복권 서비스에서 이벤트 및 상품 안내, 이벤트 행사 관련
        정보 안내 목적으로 SNS, SMS를 통한 상품 및 이벤트 정보 송신을 위해
        이용하고자 합니다. 마케팅 및 홍보에 활용을 원하지 않을 경우 동의하지
        않으셔도 됩니다.
      </p>
      <ul style={listStyle}>
        <li>
          수집 및 이용 목적: 글핏복권 이벤트 및 상품 안내, 이벤트 행사 관련 안내
        </li>
        <li>수집 항목: 이름, 휴대폰 번호</li>
        <li>보유 및 이용 기간: 고객 요청 또는 이벤트 종료 이후 30일까지</li>
      </ul>
      <SmsCodeButton
        onClick={close}
        style={{ width: '100%', height: '80%', backgroundColor: '#B8B8B8' }}
      >
        약관 닫기
      </SmsCodeButton>
    </div>
  );
};

export default SmsPopUpTextBox;
