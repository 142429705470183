import React from 'react';
import '../CSS/SideBar.scss';
import { getPricePerMonth } from 'utils/utils';
import { useProduct } from 'hooks/product/useProduct';

export default function SideBar({
  price,
  discount,
  membershipType,
  isButtonDisabled,
  handleButtonClicked,
  children,
}) {
  const { PRODUCT_TYPE } = useProduct();
  const totalPriceText =
    price - discount < 0
      ? '0'
      : membershipType === PRODUCT_TYPE.MONTH_MEMBERSHIP
      ? (price - discount).toLocaleString()
      : `월 ${getPricePerMonth(price, discount).toLocaleString()}`;
  return (
    <div className="side-bar-contents">
      <div className="payment-event-container">
        <div className="payment-event-top">
          <div className="payment-event-title strong">멤버십 결제하기</div>
          <div className="payment-event-subtitle">
            [EVENT] 12개월 무이자 할부 지원
          </div>
        </div>
      </div>
      <div className="payment-total-container">
        <div className="payment-total-info">
          <div className="payment-total-line">
            <div>총 상품 금액</div>
            <div>{price.toLocaleString()}원</div>
          </div>
          <div className="payment-total-line">
            {discount ? (
              <>
                <div>
                  할인
                  <div className="sale-tag">
                    {Math.ceil((discount / price) * 100)}% 할인
                  </div>
                </div>
                <div className="orange">-{discount.toLocaleString()}원</div>
              </>
            ) : (
              <>
                <div>할인</div>
                <div>0원</div>
              </>
            )}
          </div>
        </div>
        <div className="payment-total-price-container strong">
          <div>최종 결제 금액</div>
          <div className="payment-price">{totalPriceText}원</div>
        </div>
        <div className="payment-notification">
          {membershipType === PRODUCT_TYPE.YEAR_MEMBERSHIP && (
            <>
              총 {(price - discount).toLocaleString()}원 <br />
              (12개월 무이자 할부 적용 시)
            </>
          )}
        </div>
      </div>
      {children}
      <button
        className={`payment-total-button ${
          !isButtonDisabled && 'checked'
        } strong`}
        onClick={handleButtonClicked}
      >
        {totalPriceText}원 결제하기
      </button>
    </div>
  );
}
