// RoundButton.js
import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { palette } from '../../pages/AnyfitMall/theme';
import { useNavigate } from 'react-router-dom';

const StyledButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: ${palette.AnyBlue};
  color: white;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;

  &:hover {
    filter: brightness(85%); // Darkens the button by reducing brightness
  }
`;

const QuestionButton = ({
  path = '/anyfit/questionList',
  label = '문의하기',
}) => {
  // let history = useHistory();

  const navigate = useNavigate();
  const onClick = () => {
    navigate('/anyfit/questionList');
    window.scrollTo(0, 0);
  };

  return <StyledButton onClick={() => onClick()}>{label}</StyledButton>;
};

export default QuestionButton;
