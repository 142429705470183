import React from 'react';
import '../CSS/PositiveEffect.scss';
import effect1 from '../../../assets/images/img-positive-effect-1.png';
import effect2 from '../../../assets/images/img-positive-effect-2.png';
import effect3 from '../../../assets/images/img-positive-effect-3.png';
import mark1 from '../../../assets/images/img-mark-1.png';
import mark2 from '../../../assets/images/img-mark-2.png';

function PositiveEffect() {
  const renderData = [
    {
      title: '쉬운 식단관리',
      subtitle: (
        <span>
          음식을 먹기 전에 혈당반응을 확인하고, <br /> 살이 찌는 음식을 미리
          피해요.
        </span>
      ),
      img: effect1,
    },
    {
      title: '행동으로 이어지는 데이터',
      subtitle: (
        <span>
          혈당 반응 예측 데이터를 토대로 <br /> 나에게 꼭 필요한 건강 생활습관
          팁을 받아보세요.
        </span>
      ),
      img: effect2,
    },
    {
      title: '신뢰도 높은 AI모델',
      subtitle: (
        <span>
          글루코핏 회원들의 누적 데이터 <br /> 300만 건을 바탕으로 개발한
          모델이에요.
        </span>
      ),
      img: effect3,
    },
  ];
  return (
    <div className="positive-effect-container">
      {renderData.map((item, index) => {
        return (
          <div className="positive-effect-item" key={index}>
            <div className="positive-effect-item-title"> {item.title} </div>
            <div className="positive-effect-item-subtitle">
              {' '}
              {item.subtitle}{' '}
            </div>
            <img
              className="positive-effect-item-img"
              src={item.img}
              alt="img"
            />
          </div>
        );
      })}
      <div className="positive-effect-bottom">
        <div className="positive-effect-bottom-content">
          <div className="positive-effect-bottom-text">
            <div className="positive-effect-bottom-title">
              서울대 인공지능 전문가 &#183; 의사
            </div>
            <div className="positive-effect-bottom-subtitle">
              서울대 인공지능 전문가들과 의사가 <br />
              집대성한 인공지능 분석 서비스를 경험해보세요.
            </div>
          </div>
          <div className="positive-effect-bottom-img">
            <img src={mark1} alt="서울대학교 마크" />
            <img src={mark2} alt="서울대학교 마크" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PositiveEffect;
