import React, { useState, useEffect, useRef } from 'react';
import Layout from 'components/Raffle/common/Layout';
import styled from 'styled-components';
import { fontSize, palette } from 'components/Raffle/palette';
import PrizeContainer from 'components/Raffle/main/PrizeContainer';
import { API_BASE_URL } from 'pages/Raffle/config';
import axios from 'axios';
import { offsetLimitPagination } from '@apollo/client/utilities';

const IMG_RAISE_HAND =
  'https://glucofit-image.s3.ap-northeast-2.amazonaws.com/Raising+hand.png';
const IMG_TICKET =
  'https://glucofit-image.s3.ap-northeast-2.amazonaws.com/Vector.png';

const TicketContainerComponent = ({
  userData,
  ticketCount,
  authToken,
  isCheckedIn,
  loadUserData,
}) => {
  const [timeUntilMidnight, setTimeUntilMidnight] = useState('');

  useEffect(() => {
    const updateCountdown = () => {
      const now = new Date();
      const midnight = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 1,
        0,
        0,
        0,
      );
      const diff = midnight - now;

      const hours = Math.floor(
        (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      );
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      setTimeUntilMidnight(`${hours}시간 ${minutes}분 ${seconds}초`);
    };

    updateCountdown();
    const intervalId = setInterval(updateCountdown, 1000);

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, []);

  const checkIn = async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/raffle/checkin`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        },
      );
      alert('응모권 지급이 완료됐습니다.');

      loadUserData(); // Reload data regardless of the request result
      return response.data; // Axios automatically parses JSON response
    } catch (error) {
      // console.error('Failed to fetch data:', error);
      // console.error('Failed to fetch data:', error.response?.data.code);
      alert(error.response.data.message);
      return null;
    }
  };

  useEffect(() => {}, []);

  return (
    <TicketContainer>
      <MyTicketStatusContainer>
        <AttendanceContainerLeft>
          <IconWrapper>
            <img
              src={IMG_TICKET}
              alt="이미지"
              style={{
                width: '20px', // 이미지 너비를 24px로 설정
                height: '100%', // 이미지 높이를 wrapper 높이에 맞춤
                objectFit: 'contain', // 이미지 비율을 유지하면서 최대 크기 조정
              }} // 이미지 크기 조정 스타일 추가
            />
          </IconWrapper>
          <AttendText1>내 응모권</AttendText1>
        </AttendanceContainerLeft>
        <AttendanceContainerRight>
          <ProductUnit>남은 갯수</ProductUnit>
          <ProductCount>{ticketCount ?? 0 + ' 개'}</ProductCount>
        </AttendanceContainerRight>
      </MyTicketStatusContainer>
      <AttendanceContainer>
        <AttendanceContainerLeft>
          <IconWrapper>
            <img
              src={IMG_RAISE_HAND}
              alt="이미지"
              style={{
                width: '20px', // 이미지 너비를 24px로 설정
                height: '100%', // 이미지 높이를 wrapper 높이에 맞춤
                objectFit: 'contain', // 이미지 비율을 유지하면서 최대 크기 조정
              }} // 이미지 크기 조정 스타일 추가
            />
          </IconWrapper>
          <AttendText2>{isCheckedIn}출석체크하고 응모권 받기</AttendText2>
        </AttendanceContainerLeft>
        <AttendanceContainerRight>
          {userData?.is_checked_in ? (
            <DiabledButton disabled>{` ${timeUntilMidnight}`}</DiabledButton>
          ) : authToken ? (
            <AttendButton onClick={() => checkIn()}>출석체크 하기</AttendButton>
          ) : (
            <DisabledAttendButton
              onClick={() =>
                alert('아래 참여하기 버튼으로 먼저 신청을 진행해주세요')
              }
            >
              {' '}
              출석체크 하기
            </DisabledAttendButton>
          )}
        </AttendanceContainerRight>
      </AttendanceContainer>
    </TicketContainer>
  );
};

const AttendanceContainerLeft = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
`;

const AttendanceContainerRight = styled.div`
  width: 40%;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ProductUnit = styled.div`
  color: #4d4d4d;
  font-size: ${fontSize.small};
  align-items: center; /* 수직 가운데 정렬 */

  display: flex;
  justify-content: center;
  margin-right: 1rem;

  flex-grow: 1;
`;

const ProductCount = styled.div`
  display: flex;
  flex-direction: row;
  height: 32px;
  width: 44px;
  background-color: ${palette.LightGray}; /* 배경색 추가 */
  color: black;
  border-radius: 10px;
  align-items: center; /* 수직 가운데 정렬 */
  justify-content: center; /* 수평 가운데 정렬 */

  font-size: ${fontSize.normal};
  font-weight: bolder; /* 'bolder' 키워드를 사용하여 더 굵게 만듦 */
  font-family: 'Noto Sans KR', sans-serif; /* Noto Sans KR로 변경 */
`;

const AttendText1 = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center; // 세로 중앙 정렬

  font-size: ${fontSize.normal};
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: bold;
`;

const AttendText2 = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center; // 세로 중앙 정렬

  font-size: ${fontSize.small};
  font-family: 'Noto Sans KR', sans-serif;
  // font-weight: bold;
`;

const DiabledButton = styled.div`
  height: 32px;
  width: 100%;
  background-color: #4caf50; // 버튼 배경색
  color: white; // 텍스트 색상
  border-radius: 8px; // 태두리 모서리를 둥글게 (반경 10픽셀)

  background-color: ${palette.Gray600}; /* 배경색 추가 */
  // font-weight: bolder; /* 'bolder' 키워드를 사용하여 더 굵게 만듦 */
  font-family: 'Noto Sans KR', sans-serif; /* Noto Sans KR로 변경 */

  display: flex;
  align-items: center; // 세로 중앙 정렬
  justify-content: center;
`;

const AttendButton = styled.div`
  height: 32px;
  width: 100%;
  background-color: #4caf50; // 버튼 배경색
  color: white; // 텍스트 색상
  border-radius: 8px; // 태두리 모서리를 둥글게 (반경 10픽셀)

  background-color: ${palette.FitMint700}; /* 배경색 추가 */
  // font-weight: bolder; /* 'bolder' 키워드를 사용하여 더 굵게 만듦 */
  font-family: 'Noto Sans KR', sans-serif; /* Noto Sans KR로 변경 */

  display: flex;
  align-items: center; // 세로 중앙 정렬
  justify-content: center;
`;

const DisabledAttendButton = styled.div`
  height: 32px;
  width: 100%;
  background-color: #4caf50; // 버튼 배경색
  color: color; // 텍스트 색상
  border-radius: 8px; // 태두리 모서리를 둥글게 (반경 10픽셀)

  background-color: ${palette.LightGray}; /* 배경색 추가 */
  // font-weight: bolder; /* 'bolder' 키워드를 사용하여 더 굵게 만듦 */
  font-family: 'Noto Sans KR', sans-serif; /* Noto Sans KR로 변경 */

  display: flex;
  align-items: center; // 세로 중앙 정렬
  justify-content: center;
`;

const MyTicketStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 48px;
  width: 100%;

  border-bottom: 1px solid ${palette.LightGray}; // 하단 선 색상을 props에서 받아서 설정
`;

const AttendanceContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 48px;
`;

const TicketContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${fontSize.normal};

  background-color: white; // 배경 색을 투명으로 설정
  border-radius: 10px; // 태두리 모서리를 둥글게 (반경 10픽셀)

  margin-top: 12px;
  padding-left: 20px;
  padding-right: 20px;
`;

const IconWrapper = styled.div`
  display: flex;
  width: 30px;

  justify-content: center; // 이미지를 가로 중앙에 위치
  align-items: center; // 이미지를 세로 중앙에 위치
`;

export default TicketContainerComponent;
