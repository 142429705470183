import styled from 'styled-components';

export const TopContainer = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: black;
  border-bottom: 2px solid black;
  margin: 40px 360px;
  padding-bottom: 12px;
  @media screen and (max-width: 1100px) {
    margin: 40px 20px;
  }
`;

export const ReviewContainer = styled.div`
  margin: 0 auto;
  margin-left: 360px;
  margin-right: 360px;

  @media screen and (max-width: 1100px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

export const BadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;
`;

export const ItemContainer = styled.div`
  margin-bottom: 40px;
`;

export const ProductContainer = styled.div`
  color: var(--gray-gray-800, #222);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`;

export const StarContainer = styled.div`
  margin-top: 6px;
  margin-bottom: 12px;
`;

export const TitleContainer = styled.div`
  color: var(--gray-gray-800, #222);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`;

export const ProfileContainer = styled.div`
  display: flex;
  color: var(--gray-gray-500, #707070);
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 12px;
`;

export const UserName = styled.div`
  margin-right: 10px;
`;
export const DateTime = styled.div``;

export const DescriptionContainer = styled.div`
  margin-bottom: 12px;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: 0 auto;
  img {
    width: 200px;
    height: 200px;
    border-radius: 10px;
    margin: 0;
  }

  @media screen and (max-width: 1100px) {
    img {
      width: 120px;
      height: 120px;
    }
  }
`;

export const Image = styled.div``;

export const Overlay = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px;
`;

export const BestBadge = styled.div`
  border-radius: 4px;
  background: var(--fit-mint-fit-mint-600, #10ccad);
  padding-left: 8px;
  padding-right: 8px;
  color: white;
  font-weight: 700;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  margin-right: 4px;
`;

export const SecondBadge = styled.div`
  border-radius: 4px;
  background: var(--red-red-700, #f54e6a);
  padding-left: 8px;
  padding-right: 8px;
  color: white;
  font-weight: 700;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
`;

export const ActiveLink = styled.div`
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
  padding: 3px 8px;
  border-radius: 5px;
  margin: 0 5px;
`;

export const PaginationItem = styled.div`
  margin: 0 5px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  text-decoration: none;
`;

export const PaginationItemHover = styled.div`
  text-decoration: underline;
`;
