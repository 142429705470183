import './Payment.scss';
import './Membership.scss';
import React, { useLayoutEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import Nav from 'components/Nav/Nav';
import Footer from 'components/Footer/Footer';
import SideBar from 'pages/Payment/Components/SideBar';
import OrderForm from 'pages/Payment/Components/OrderForm';
import PaymentMethodSelector from 'pages/Payment/Components/PaymentMethodSelector';
import Terms from 'pages/Payment/Components/Terms';
import MembershipBenefit from 'pages/Payment/Components/MembershipBenefit';
import useDiscount from 'hooks/price/useDiscount';
import useOrder from 'hooks/order/useOrder';
import CouponForm from 'pages/Payment/Components/CouponForm';
import { useProduct } from 'hooks/product/useProduct';
import usePayment from 'hooks/payment/usePayment';

const PaymentMembership = () => {
  const orderFormRef = useRef();
  const {
    order,
    setOrder,
    handleFindAddressClick,
    handleOrder,
    validateOrder,
  } = useOrder();
  const { selectedProduct, setSelectedProductById, setDiscount } = useProduct();
  const { createOrder, requestPay, completeOrder } = usePayment();
  const [searchParams] = useSearchParams();
  const { discount, applyDiscount } = useDiscount();

  const handlePayment = async () => {
    const test = false;
    const mRedirectUrl = test
      ? 'http://192.168.35.5:3000/redirect'
      : 'https://glucofit.co.kr/redirect';
    if (!validateOrder()) {
      return alert('구매자 정보를 입력해주세요.');
    }
    const totalPrice =
      selectedProduct.price - selectedProduct.discount > 0
        ? selectedProduct.price - selectedProduct.discount
        : 0;
    const merchantUid = await createOrder({
      order: order,
      productId: selectedProduct.id,
      productCount: 1,
      totalPrice: totalPrice,
      couponNumber: discount?.coupon_number,
      inviteCode: discount?.invite_code,
    });
    const impUid = await requestPay({
      order: order,
      merchantUid: merchantUid,
      productId: selectedProduct.id,
      productPrice: totalPrice,
      mRedirectUrl: mRedirectUrl,
      inviteCode: discount?.invite_code,
    });
    completeOrder({
      productId: selectedProduct.id,
      impUid: impUid,
      merchantUid: merchantUid,
      redirectPath: '/successpay',
    });
  };

  useLayoutEffect(() => {
    applyDiscount(
      setDiscount,
      searchParams.get('coupon_number'),
      searchParams.get('code'),
    );
    setSelectedProductById(searchParams.get('product_id'));
  }, []);

  if (selectedProduct === null) return null;
  return (
    <div>
      <div className="nav-payment">
        <Nav />
      </div>
      <div className="membership-payment-body">
        <div className="payment-left-container">
          <div className="membership-payment-title strong">주문/결제</div>
          <div className="membership-payment-container">
            <div className="membership-payment-subtitle">주문상품 정보</div>
            <MembershipBenefit
              type={selectedProduct.type}
              membershipName={selectedProduct.name}
              sensorCount={selectedProduct.sensorCount}
              eventSensorCount={selectedProduct.eventSensorCount}
              deliveryMessage={selectedProduct.deliveryMessage}
            />
          </div>
          <div className="membership-payment-container">
            <div className="membership-payment-subtitle" ref={orderFormRef}>
              구매자 정보
            </div>
            <OrderForm
              order={order}
              setOrder={setOrder}
              handleFindAddressClick={handleFindAddressClick}
              handleOrder={handleOrder}
            />
          </div>
          {!discount && (
            <div className="membership-payment-container">
              <div className="membership-payment-subtitle">할인쿠폰</div>
              <CouponForm setDiscount={setDiscount} />
            </div>
          )}
          <div className="membership-payment-container">
            <div className="membership-payment-subtitle">결제 수단</div>
            <PaymentMethodSelector order={order} handleOrder={handleOrder} />
          </div>
        </div>
        <div className="payment-right-container">
          <div className="payment-info-title">결제 금액</div>
          <SideBar
            price={selectedProduct.price}
            discount={selectedProduct.discount}
            membershipType={selectedProduct.type}
            isButtonDisabled={!order.terms1.value || !order.terms2.value}
            handleButtonClicked={handlePayment}
          >
            <Terms
              type={selectedProduct.type}
              order={order}
              handleOrder={handleOrder}
              termMessage={selectedProduct.termMessage}
            />
          </SideBar>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PaymentMembership;
