import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as CloseIcon } from '../../assets/icons/ic_close.svg';
import { ReactComponent as NavBarIcon } from '../../assets/icons/ic_menu.svg';
import Logo from '../../assets/icons/ic-main-logo-black.svg';
import LogoMobile from '../../assets/icons/ic-logo-mobile.svg';
import { useLocation, useNavigate } from 'react-router-dom';

import './Nav.scss';

function Nav({ initCheckout, isIntersecting }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleClick = () => {
    navigate('/');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // 스크롤 이벤트 핸들러
  const handleScroll = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <nav
      className={`${pathname === '/' && 'main-page'} ${
        isIntersecting && 'transparent'
      }`}
    >
      <div className="nav-left">
        <div className="logo">
          <img
            src={Logo}
            alt="글루코핏"
            className="logo-image"
            onClick={handleClick}
          />
        </div>
        <ul className="menu">
          <li>
            <a
              href="https://blog.glucofit.co.kr/"
              // onClick={logEvent('glucoselab_click', 10201)}
            >
              혈당연구소
            </a>
          </li>
          <li>
            <a href="https://connect.glucofit.co.kr">글루코핏 커넥트</a>
          </li>
          <li>
            <a
              href="https://glucofit.co.kr/review"
              // onClick={logEvent('glucofitreview_entered', 10102)}
            >
              글루코핏 후기
            </a>
          </li>
          <li>
            <a href="https://pf.kakao.com/_DYhWxj/chat"> 고객센터</a>
          </li>
        </ul>
      </div>
      <div className="nav-right">
        <div>지금바로</div>
        <button className="nav-start-button strong" onClick={initCheckout}>
          시작하기
        </button>
      </div>
      <div className="icon" onClick={toggleMenu}>
        {isMenuOpen ? <CloseIcon /> : <NavBarIcon />}
      </div>
      <img
        src={LogoMobile}
        alt="logo"
        className="mobile-logo"
        onClick={handleClick}
      />
      {isMenuOpen && (
        <div className="menu-opened">
          <ul className="menu-items strong">
            <li>
              <a href="https://blog.glucofit.co.kr/">혈당연구소</a>
            </li>
            <li>
              <a href="https://connect.glucofit.co.kr">글루코핏 커넥트</a>
            </li>
            <li>
              <a href="https://glucofit.co.kr/review">글루코핏 후기</a>
            </li>
            <li>
              <a href="https://pf.kakao.com/_DYhWxj/chat">고객센터</a>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
}

export default Nav;
