// TextInput.js

import React, { useState } from 'react';
import { palette } from 'components/Raffle/palette';
import 'pages/Raffle/Raffle.css';

const SmsTextInput = ({ onValueChange, title, placeholder, isEnabled }) => {
  const [inputValue, setInputValue] = useState('');

  const handleChange = (event) => {
    setInputValue(event.target.value);
    if (onValueChange) {
      onValueChange(event.target.value);
    }
  };

  // Assuming `title`, `inputValue`, `handleChange`, and `placeholder` are defined in your component's props or state.

  return (
    <div
      className="input-container"
      style={{
        flex: 1,
        position: 'relative',
        marginBottom: '0px',
        marginTop: '0px',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '20.5px', // Adjusted for space above the text
          left: '12px', // Adjusted to align with the text inside the input
          fontSize: '12px',
          pointerEvents: 'none', // Ensures clicks go through to the input below
          fontFamily: "'Noto Sans KR', sans-serif",
          color: '#aaaaaa',
          fontWeight: '600',
          lineHeight: '12px',
        }}
      >
        {title}
      </div>
      <input
        className="sms-input-box"
        disabled={isEnabled}
        type="text"
        value={inputValue}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default SmsTextInput;
