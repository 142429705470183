import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Layout from 'components/Anyfit/common/Layout';
import styled from 'styled-components';
import { fontSize, palette } from 'pages/AnyfitMall/theme';
import { API_BASE_URL, TOKEN } from './config';
import { validate } from 'graphql';
import QuestionButton from 'components/Anyfit/QuestionButton';
import {
  validateStorageToken,
  validateToken,
  parseTokenFromUrl,
  getTokenFromStorage,
} from './common';

const NotContentContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NotContent = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  color: ${palette.Gray400};
  margin-top: 3rem;
`;

const Container2 = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  // min-width: 360px;
  min-height: 120vh;
  max-width: 500px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 300px; // 각 그리드 아이템의 높이가 240px이 될 수 있도록 설정 (200px 높이 + 20px 패딩 * 2)
`;

const GridItem = styled.div`
  box-sizing: border-box; // 패딩과 보더가 전체 너비에 포함되도록 설정
  width: 100%;
  padding: 20px; // 원하는 패딩 값을 설정

  font-size: 16px;
  font-family: 'Noto Sans KR', sans-serif;
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  // min-height: 260px; // 각 그리드 아이템의 높이를 200px로 설정
  align-items: center; // This will center the image horizontally in a flex column container
  // justify-content: center; // This will center the content vertically
  text-align: center; // Centers the text if it's within a block element
  background: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  background: ${palette.Gray300}; // 배경색을 투명으로 설정해 패딩에는 색이 들어가지 않도록 함
`;

const ProductImage = styled.img`
  width: 100%; // The image will scale to the width of the container
  height: auto; // The height will scale proportionally
  object-fit: contain; // The image will be scaled to be as large as possible, with the whole image visible in the container
  margin-bottom: 8px; // Space between image and text
  position: relative;
  left: 5px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
`;

const ProductName = styled.div`
  font-size: 1rem; // Adjust font-size as needed
  font-family: 'Noto Sans KR', sans-serif;
  display: flex;
  justify-content: center;
  align-item: centet;

  color: #333; // Adjust text color as needed
  margin-bottom: 4px; // Space between name and price
  padding-left: 10px;
  padding-right: 10px;
  // height: 20px;
  font-size: ${fontSize.normal};
`;

const ProductPrice = styled.div`
  font-weight: bold; // Makes the price stand out
  font-family: 'Noto Sans KR', sans-serif;
  font-size: ${fontSize.normal};
  color: #333; // Adjust text color as needed
  padding-left: 10px;
  padding-right: 10px;
  // margin-bottom: 5px;
`;

const Title = styled.div`
  color: #141414;
  font-size: 20px;
  font-weight: bold;
  font-family: 'Noto Sans KR', sans-serif;
  margin-top: 60px;
  padding-left: 10px;
`;

const Button = styled.button`
  width: 80%;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: ${fontSize.normal};
  color: white;
  display: flex;
  justify-content: center;
  align-item: center;

  margin-bottom: 8px; // Space between name and price
  margin-top: 8px; // Space between name and price
  padding-bottom: 4px; // Space between name and price
  padding-top: 4px; // Space between name and price
  background-color: ${palette.AnyBlue}; // Adjust background color as needed
  border: none; // Border removed
  border-radius: 4px;

  &:hover {
    filter: brightness(85%); // Darkens the button by reducing brightness
  }
`;

const AnyfitProductList = () => {
  const navigate = useNavigate();
  const handleClick = (productId) => {
    // window.location.href = `http://localhost:3000/anyfit/productDetail/${productId}`;
    navigate(`/anyfit/productDetail/${productId}`);
  };

  const location = useLocation();
  const userCurrentState = useState(1);
  // 1 - 이용기간 아님 && 패키지 무상 대상 -> 신청하기 링크
  // 2 - 이용기간 아님 && 패키지 무상 대상 X -> 구매하기 링크
  // 3 - 이용기간 도중 && 패키지 무상 대상 (보험가입) -> 이용중인 상품입니다.
  // 4 - 이용기간 도중 && 패키지 무상 대상 X -> 이용중인 상품입니다.

  const [alreadyUse, setAlreadyUse] = useState(false);
  const [noAgreement, setNoAgreement] = useState(false);
  const [productListData, setProductListData] = useState([]);

  const fetchProducts = async (token) => {
    const response = await fetch(`${API_BASE_URL}/api/myProducts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: token }),
    });

    if (response.ok) {
      const data = await response.json();
      return data.products;
    } else {
      const errorData = await response.json();
      const { statusCode, message } = errorData;
      console.log('response...', response);
      console.error('Failed to fetch products:', message);
      if (message == 'NO_SERVICE_AGREEMENT') {
        setNoAgreement(true);
        alert('이용 동의가 필요합니다.');
        navigate('/anyfit/agreement');
      } else if (message == 'ORDER_ALREADY_EXISTS') {
        setAlreadyUse(true);
      }

      // Optionally, you can throw the error to be caught by the caller
      // throw new Error(`Error ${statusCode}: ${message}`);
    }
  };
  useEffect(() => {
    const initialize = async () => {
      let token = parseTokenFromUrl();
      console.log('parseTokenFromUrl...', token);

      if (token) {
        // todo : 클라이언트에서 분기태우면 안된다.
        // URL에서 토큰을 가져온 경우
        const validationResult = await validateToken(token);
        console.log('validationResult...', validationResult);

        if (validationResult.code == 1) {
          alert('이용 동의가 필요합니다.');
          navigate('/anyfit/agreement');
        } else if (validationResult.code == 2) {
          alert('올바른 코드가 아닙니다.');
        } else if (validationResult.result) {
          // user data
          // setUserData(validationResult.data);
          // question data
          const products = await fetchProducts(token);
          setProductListData(products);
          // store token
          localStorage.setItem('token', token);
          return;
        }
      }
    };

    window.scrollTo(0, 0);
    initialize();
    return;
  }, []);
  // useEffect(() => {
  //   const initialize = async () => {
  //     let token = parseTokenFromUrl();
  //     console.log('parseTokenFromUrl...', token);

  //     if (token) {
  //       // todo : 클라이언트에서 분기태우면 안된다.
  //       // URL에서 토큰을 가져온 경우
  //       const validationResult = await validateToken(token);
  //       console.log('validationResult...', validationResult);

  //       if (validationResult.code == 1) {
  //         alert('이용 동의가 필요합니다.');
  //         navigate('/anyfit/agreement');
  //       } else if (validationResult.code == 2) {
  //         alert('올바른 코드가 아닙니다.');
  //       } else if (validationResult.result) {
  //         // user data
  //         // setUserData(validationResult.data);

  //         const products = await fetchProducts(token);
  //         setProductListData(products);
  //         // store token
  //         localStorage.setItem('token', token);
  //         return;
  //       }
  //     }
  //   };

  //   window.scrollTo(0, 0);
  //   return;
  // }, []);

  return (
    <div>
      <Layout>
        <Title>상품</Title>
        <Container2>
          {noAgreement ? (
            <NotContentContainer>
              <NotContent>이용 동의가 필요한 페이지입니다.</NotContent>
            </NotContentContainer>
          ) : alreadyUse ? (
            <NotContentContainer>
              <NotContent>
                이미 상품을 <br />
                구매하였습니다.
              </NotContent>
            </NotContentContainer>
          ) : (
            <Container>
              {productListData?.map((productItem, index) => {
                return (
                  <GridItem
                    key={productItem.id}
                    onClick={() => handleClick(productItem.id)}
                  >
                    <ContentContainer>
                      <ProductImage
                        src={productItem.img_url}
                        alt={productItem.name}
                      />
                      <ProductName>{productItem.name}</ProductName>
                      <ProductPrice>
                        {productItem.price.toLocaleString()} 원
                      </ProductPrice>
                      <Button>신청하기</Button>
                    </ContentContainer>
                  </GridItem>
                );
              })}
              <QuestionButton></QuestionButton>
            </Container>
          )}
        </Container2>
      </Layout>
    </div>
  );
};

export default AnyfitProductList;
