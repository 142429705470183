import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const AddressFormMall = ({ handleAddressSelect, addressInvalid, itemId }) => {
  const [isEmpty, setIsEmpty] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { postcode, address } = location.state || {};

  const handleDetailAddressFocus = () => {
    localStorage.setItem('postcode', postcode);
    localStorage.setItem('address', address);
    document.getElementById('sample6_detailAddress').focus();
    document.getElementById('sample6_detailAddress').click();
  };

  useEffect(() => {
    const selectedAddress = {
      postcode,
      address,
    };

    if (addressInvalid === true) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }

    handleAddressSelect(selectedAddress);
    if (postcode && address) {
      handleDetailAddressFocus();
    }
  }, [postcode, address, addressInvalid]);

  const sample6_execDaumPostcode = () => {
    localStorage.setItem('scrollPosition', window.pageYOffset.toString());
    navigate(`/postcodemall/${itemId}`);
  };

  return (
    <div>
      <input
        type="text"
        onClick={sample6_execDaumPostcode}
        id="sample6_postcode"
        placeholder="우편번호"
        value={localStorage.getItem('postcode') ?? postcode}
        readOnly
      />
      <input
        type="button"
        onClick={sample6_execDaumPostcode}
        value="우편번호 찾기"
      />
      <br />
      <input
        type="text"
        id="sample6_address"
        placeholder="도로명 주소 (예: 서울특별시 강남구 테헤란로 217)"
        value={localStorage.getItem('address') ?? address}
        readOnly
        className={isEmpty ? 'error' : ''}
      />
      <div className="validation-text">
        {isEmpty ? '주소를 입력해 주세요' : ''}
      </div>
    </div>
  );
};

export default AddressFormMall;
