import React, { useState, useEffect, useRef } from 'react';
import Layout from '../../components/Raffle/common/Layout';
import styled from 'styled-components';
import { palette } from '../../components/Raffle/palette';
import {
  SmsTitle,
  SmsTitle2,
  SmsInput,
  SmsCodeButton,
  StyledButton,
  SmsCodeVerificationButton,
} from '../../styles/RaffleSms.module.js';
import SmsTextInput from '../../components/Raffle/SmsTextInput';
import SmsCheckList from '../../components/Raffle/SmsCheckList';
import SmsPopUpTextBox from '../../components/Raffle/SmsPopUpTextBox'; // Import the component
import { API_BASE_URL } from './config';

function RaffleSms() {
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberValidation, setPhoneNumberValidation] = useState(false);
  // 인증번호 관련 함수
  const [verificationCode, setVerificationCode] = useState('');
  const [
    isRequestVerificationCodeEnabled,
    setIsRequestVerificationCodeEnabled,
  ] = useState(false);
  const [isVerificationCodeRequested, setIsVerificationCodeRequested] =
    useState(false);
  const [isVerificationCodeCompleted, setIsVerificationCodeCompleted] =
    useState(false);
  const [isVerificationCodeWrong, setIsVerificationCodeWrong] = useState(false);

  // 약관 체크 상태
  const [isFirstChecked, setFirstChecked] = useState(false);
  const [isSecondChecked, setSecondChecked] = useState(false);
  const [isThirdChecked, setThirdChecked] = useState(false);

  // 약관 박스
  const [isOpen, setIsOpen] = useState(false);

  // 로컬 스토리지에 referralCode 존재하는지 확인
  const [urlRefCode, setUrlRefCode] = useState(null);
  const togglePopUp = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (phoneNumberValidation) {
      setIsRequestVerificationCodeEnabled(true);
      return;
    }

    if (!phoneNumberValidation) {
      setIsRequestVerificationCodeEnabled(false);
      return;
    }
  }, [phoneNumberValidation]);

  useEffect(() => {
    console.log('url ref code', window.sessionStorage.getItem('url_ref_code'));
    if (window.sessionStorage.getItem('url_ref_code') !== null) {
      setUrlRefCode(window.localStorage.getItem('url_ref_code'));
    }
  }, []);

  const handleNameChange = (newValue) => {
    setName(newValue);
  };

  const handlePhoneNumberChange = (newValue) => {
    const regex = /^010[0-9]{8}$/;
    if (regex.test(newValue)) {
      setPhoneNumber(newValue);
      setPhoneNumberValidation(true); // 번호가 유효하면 오류 상태를 true로 설정
    } else {
      setPhoneNumberValidation(false); // 유효하지 않은 번호
    }
  };

  const handleVerificationCodeChange = (newValue) => {
    setVerificationCode(newValue);
  };

  // 인증번호 발송
  const handleSubmitPhoneNumber = () => {
    if (!isRequestVerificationCodeEnabled) {
      return;
    }

    setIsRequestVerificationCodeEnabled(false);
    setIsVerificationCodeRequested(true);

    const apiUrl = `${API_BASE_URL}/auth/verify`;

    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phone: phoneNumber,
      }),
    })
      .then((response) => {
        if (response.status === 204) {
          console.log(
            'Verification code sent successfully, no content to return.',
          );
          return;
        } else {
          setIsRequestVerificationCodeEnabled(false);
        }
        // You can handle other statuses here
        return response.json();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  // 인증번호 verify
  const handleSubmitVerificationCode = () => {
    setIsRequestVerificationCodeEnabled(false);

    const apiUrl = `${API_BASE_URL}/auth/user`;

    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: name,
        phone: phoneNumber,
        code: verificationCode,
        referral_code: window.sessionStorage.getItem('url_ref_code'),
      }),
    })
      .then((response) => {
        if (response.status === 201) {
          setIsVerificationCodeCompleted(true);
          setIsVerificationCodeWrong(false);
          console.log(
            'Verification code verified successfully, no content to return.',
          );
          return response.json();
        } else {
          setIsVerificationCodeWrong(true);
        }
      })
      .then((data) => {
        if (data && 'access_token' in data) {
          const accessToken = data.access_token;
          window.localStorage.setItem('access_token', accessToken);
          window.location.href = '/raffle';
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <Background>
      <RaffleLayout>
        <Container>
          {/* <SmsTitle2>
            {'현재 접속량이 많아 자정 이후 참여가 가능합니다'}
            <br />
            {'이용에 불편드려 죄송합니다.'}
          </SmsTitle2> */}
          <SmsTitle>
            간단한
            {urlRefCode}
            <br />
            <span style={{ color: '#44e3c8' }}>본인인증이 필요</span>해요!
          </SmsTitle>
          <SmsTextInput
            onValueChange={handleNameChange}
            title="이름"
            placeholder="이름을 입력해주세요."
            isEnabled={isVerificationCodeCompleted}
          />
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <SmsTextInput
              onValueChange={handlePhoneNumberChange}
              title="휴대폰 번호"
              placeholder="01000000000"
              isEnabled={isVerificationCodeCompleted}
            />
            <SmsCodeButton
              onClick={handleSubmitPhoneNumber}
              isEnabled={isRequestVerificationCodeEnabled}
            >
              {isVerificationCodeCompleted ? '인증 완료' : '인증번호 요청'}
            </SmsCodeButton>{' '}
          </div>
          {!phoneNumberValidation && (
            <div
              style={{
                marginTop: '2px',
                marginLeft: '10px',
                fontSize: 12,
                color: 'red',
              }}
            >
              01012341234 형식으로 입력해주세요
            </div>
          )}
          {isVerificationCodeRequested && !isVerificationCodeCompleted && (
            <div>
              <SmsTextInput
                onValueChange={handleVerificationCodeChange}
                title="인증번호 입력"
                placeholder="인증번호를 입력해주세요."
                isEnabled={isVerificationCodeCompleted}
              />
            </div>
          )}
          {isVerificationCodeWrong && (
            <div
              style={{
                marginTop: '2px',
                marginLeft: '10px',
                fontSize: 12,
                color: 'red',
              }}
            >
              인증번호가 틀렸습니다.
            </div>
          )}
          <SmsCheckList
            onFirstCheckChange={setFirstChecked}
            onSecondCheckChange={setSecondChecked}
            onThirdCheckChange={setThirdChecked}
            isFirstChecked={isFirstChecked}
            isSecondChecked={isSecondChecked}
            isThirdChecked={isThirdChecked}
          />
          <span
            onClick={togglePopUp}
            style={{
              cursor: 'pointer',
              color: 'rgba(255, 255, 255, 0.64)',
              fontSize: '12px',
              textDecoration: 'underline',
            }}
          >
            약관보기
          </span>
          <SmsPopUpTextBox isOpen={isOpen} close={togglePopUp} />
          <StyledButton
            onClick={handleSubmitVerificationCode}
            disabled={
              // Use 'disabled' instead of 'isEnabled'
              !isFirstChecked || !isSecondChecked || !isThirdChecked
            }
          >
            참여하러 가기
          </StyledButton>
        </Container>
      </RaffleLayout>
    </Background>
  );
  {
    /* </Background> */
  }
}

const RaffleLayout = styled.div`
  height: 100vh;
  max-width: 600px; /* 최대 너비를 600px로 제한합니다. */
  min-width: 400px;
  margin: 5px auto; /* 가운데 정렬을 합니다. */
  padding-left: 10px; /* 내부 패딩을 추가합니다. */
  padding-right: 10px; /* 내부 패딩을 추가합니다. */
  background: #141414;

  @media (max-width: 399px) {
    min-width: 100vw; /* 너비가 400px 미만일 때 100vw로 설정합니다. */
  }
`;

const Background = styled.div`
  background: #141414;
  min-width: 100vw;
  width: 100%;
  display: flex;
  flex-direction: ;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export default RaffleSms;
