import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Logo from 'assets/icons/ic-main-logo-black.svg';
import LogoMobile from 'assets/icons/ic-logo-mobile.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/ic_close.svg';
import { ReactComponent as NavBarIcon } from 'assets/icons/ic_menu.svg';
import ArrowLeft from 'assets/icons/ic-arrow-left.svg';
import { useLocation, useNavigate } from 'react-router-dom';

import { validateToken } from 'pages/AnyfitMall/common';

// 스타일드 컴포넌트를 사용하여 컨테이너를 생성합니다.
const Container = styled.div`
  position: fixed; // Makes the header fixed at the top
  // top: 0; // Aligns the header to the top of the viewport
  // left: 0; // Aligns the header to the left of the viewport
  width: 100vw; // Ensures the header spans the full width of the viewport

  max-width: 100%; /* 최대 너비를 600px로 제한합니다. */
  height: 50px;
  margin: 0 auto; /* 가운데 정렬을 합니다. */
  padding-left: 10px; /* 내부 패딩을 추가합니다. */
  padding-right: 10px; /* 내부 패딩을 추가합니다. */

  display: flex;
  flex-direction: center;
  // width: 200px;
  background-color: #fff; // Sets the background color of the header
  z-index: 1000; // Ensures the header is above other elements
`;

const ImageWrapper = styled.div`
  margin: 0 auto; /* 가운데 정렬을 합니다. */
  display: flex; // flex를 사용하여 내부 이미지를 중앙에 위치시킴
  justify-content: center;
  align-items: center;
  width: 200px;
`;

// 이미지 스타일
const StyledImage = styled.img`
  object-fit: contain;
  width: 100px;
`;

// 드로어
const DrawerIcon = styled.div`
  position: absolute;
  margin-left: 10px;
  margin-top: 10px;
  left: 0;
  top: 3px;
`;

const ArrowLeftIcon = styled.div`
  position: absolute;
  margin-left: 14px;
  margin-top: 10px;
  left: 0;
  top: 3px;
  img {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 45px;
`;

const AnyfitLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

// 너비를 제한한 스타일드 컴포넌트를 사용하는 함수형 컴포넌트를 생성합니다.
const Header = ({ children, text, back }) => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = async () => {
    // 토큰을 검증한다.

    // 1
    const storageToken = localStorage.getItem('token');
    const validationResult = await validateToken(storageToken);
    console.log('validationResult...!', validationResult);
    if (validationResult.result == false && validationResult.code == 1) {
      navigate('/anyfit/agreement'); // 에러 페이지로 리디렉션
      alert('이용 동의가 필요합니다.');
    } else if (validationResult == null || validationResult.result == false) {
      navigate('/anyfit/error'); // 에러 페이지로 리디렉션
      alert('접근 권한이 없습니다.');
    } else {
      // 토큰이 정상이면 진행
      setIsMenuOpen(!isMenuOpen);
    }
  };
  const goBack = () => {
    navigate(-1);
  };

  // 토큰을 로컬 스토리지에서 가져와 URL에 추가
  const handleBuyClick = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert('로그인이 필요합니다.'); // 사용자에게 경고 메시지를 띄움
      navigate('/anyfit/error'); // 에러 페이지로 리디렉션
    } else {
      navigate(`/anyfit/productDetailV2?TOKEN=${encodeURIComponent(token)}`);
    }
  };

  return (
    <Container>
      {back ? (
        <ArrowLeftIcon onClick={goBack}>
          <img src={ArrowLeft} alt="" />
        </ArrowLeftIcon>
      ) : (
        <DrawerIcon className="icon" onClick={toggleMenu}>
          {isMenuOpen ? <CloseIcon /> : <NavBarIcon />}
        </DrawerIcon>
      )}

      {isMenuOpen && (
        <div className="menu-opened">
          <ul className="menu-items strong">
            {/* <li>
              <a href="https://blog.glucofit.co.kr/">혈당연구소</a>
            </li> */}
            <li>
              <a onClick={() => handleBuyClick()}>구매하기</a>
            </li>
            <li>
              <a onClick={() => navigate('/anyfit/orderList')}>구매내역</a>
            </li>
            <li>
              <a onClick={() => navigate('/anyfit/questionList')}>문의하기</a>
            </li>
            <li>
              <a
                onClick={() => {
                  // alert('준비중인 페이지입니다.');
                  navigate('/anyfit/faq');
                }}
              >
                FAQ
              </a>
            </li>
          </ul>
        </div>
      )}
      {/* <ImageWrapper
        onClick={() => {
          navigate('/anyfit/productList');
        }}
      >
        {text ? text : <StyledImage src={LogoMobile} alt="logo" />}
        {text ? text : <StyledImage src={AnyfitLogo} alt="logo" />}
      </ImageWrapper> */}

      <LogoWrapper onClick={() => navigate('/anyfit/productList')}>
        {text ? text : <StyledImage src={LogoMobile} alt="logo" />}
      </LogoWrapper>

      <AnyfitLogoWrapper>
        <StyledImage
          src="https://glucofit-image.s3.ap-northeast-2.amazonaws.com/anyfitlogo.png"
          onClick={() => {
            window.location.href = 'https://sfplus.page.link/plus';
          }}
          alt="Anyfit logo"
          style={{ width: '140px' }}
        />
      </AnyfitLogoWrapper>
    </Container>
  );
};

export default Header;
