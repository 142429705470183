import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../CSS/StartGlucofit.scss';
import Sensor from '../../../assets/images/ver3.0/img-start-sensor.png';
import Phone from '../../../assets/images/ver3.0/img-start-phone.png';
import Plus from '../../../assets/images/ver3.0/img-start-plus.png';
// import membership from '../../../assets/images/img_membership.png';
// import check from '../../../assets/icons/ic_checkmark.svg';
// import checkGray from '../../../assets/icons/ic_checkmark_gray.svg';
// import plus from '../../../assets/icons/ic_plus.svg';
// import cgm from '../../../assets/images/img_cgms.png';
// import SelectPrice from 'pages/Modal/SelectPrice';

function SpecialEvent({ initCheckout }) {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  // const handleClick = () => {
  //   // navigate('/info');

  //   if (window.location.href.includes('drdinho')) {
  //     navigate('/invite2?code=drdinho');
  //   } else {
  //     navigate('/info');
  //   }
  //   localStorage.clear();
  //   window.gtag('event', 'conversion', {
  //     send_to: 'AW-10992256925/KpgjCL7zqKwYEJ2Pwvko',
  //   });
  //   window.fbq('track', 'InitiateCheckout');
  //   // setShowModal(true);
  // };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div className="start-container">
      <div className="start-title-eng strong">Special Event</div>
      <div className="start-title">
        지금 <span className="strong">글루코핏</span>을 <br />
        <span className="strong">경험</span>해보세요
      </div>
      <div className="start-content-container">
        <div className="start-content first">
          <div className="start-content-img-phone">
            <img src={Phone} alt="글루코핏 1년 멤버십, 센서4개 월 54000원" />
          </div>
          <div className="start-content-info">
            <div className="start-best-text">
              <span className="start-best-icon strong">BEST</span>
              글루코핏 스타터 패키지
            </div>
            <div className="start-month-price">
              <span className="strong">월 54,300원</span>
            </div>
            <div className="start-content-description">
              <div>
                글루코핏 시작시 <br />
                <span className="strong">센서가 필수</span>로 필요해요
              </div>
            </div>
          </div>
          <div className="start-plus-icon">
            <img src={Plus} alt="추가" />
          </div>
        </div>
        <div className="start-content second">
          <div className="start-content-img-sensor">
            <img src={Sensor} alt="무채혈 연속혈당측정 센서" />
          </div>
          <div className="start-content-info">
            <div className="start-content-title">
              <div className="sensor-badge">센서 6개</div>
              글루코핏 스타터 패키지
            </div>
            <div className="start-content-info-sensor">
              <span className="green strong">무채혈</span>연속혈당측정 센서
            </div>
            <div className="start-content-description">
              <div>
                <span className="strong">아픈 채혈과정 없이</span> 간편하게
                <br />
                혈당을 측정해보세요
              </div>
            </div>
          </div>
          <button
            className="start-apply-button strong"
            onClick={() => navigate('/info')}
          >
            신청하기
          </button>
        </div>
      </div>
    </div>
  );
}

export default SpecialEvent;
