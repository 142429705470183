import useDiscount from 'hooks/price/useDiscount';
import { useState } from 'react';
import '../CSS/CouponForm.scss';

export default function CouponForm({
  setDiscount,
  theme = 'main',
  blockList = [],
}) {
  const [coupon, setCoupon] = useState('');
  const [invalid, setInvalid] = useState(false);
  const { applyDiscount } = useDiscount();

  const handleClick = async () => {
    if (blockList.includes(coupon)) {
      setInvalid(true);
      return;
    }
    const result = await applyDiscount(setDiscount, coupon);
    if (result) {
      alert('쿠폰이 적용되었습니다.');
      setInvalid(false);
    } else {
      setInvalid(true);
    }
  };

  const handleCouponChange = (e) => {
    setCoupon(e.target.value);
  };

  return (
    <>
      <div className="coupon-container">
        <input
          name="coupon"
          id="coupon"
          className={invalid ? 'invalid' : ''}
          value={coupon}
          placeholder="쿠폰번호를 입력해주세요"
          onChange={handleCouponChange}
        />
        <button id="coupon-input" className={theme} onClick={handleClick}>
          등록하기
        </button>
      </div>
      {invalid && (
        <div className="invalid-text">쿠폰 번호가 올바르지 않습니다.</div>
      )}
    </>
  );
}
