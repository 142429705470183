import { useState } from 'react';
import CarouselImg1 from '../../assets/images/ver3.0/img-membership-carousel-1.png';
import CarouselImg2 from '../../assets/images/ver3.0/img-membership-carousel-2.png';
import CarouselImg3 from '../../assets/images/ver3.0/img-membership-carousel-3.png';
import CarouselMobileImg1 from '../../assets/images/ver3.0/img-membership-carousel-mobile-1.png';
import CarouselMobileImg2 from '../../assets/images/ver3.0/img-membership-carousel-mobile-2.png';
import CarouselMobileImg3 from '../../assets/images/ver3.0/img-membership-carousel-mobile-3.png';

export const useMembershipCarousel = () => {
  const MOBILE_WIDTH = 1200;

  const [activeCarousel, setActiveCarousel] = useState(0);

  const carousels = [
    {
      id: 0,
      web: CarouselImg1,
      mobile: CarouselMobileImg1,
      boundary: `(max-width: ${MOBILE_WIDTH}px)`,
      themeColor: '#EEE0D0',
    },
    {
      id: 1,
      web: CarouselImg2,
      mobile: CarouselMobileImg2,
      boundary: `(max-width: ${MOBILE_WIDTH}px)`,
      themeColor: '#D3E2EA',
    },
    {
      id: 2,
      web: CarouselImg3,
      mobile: CarouselMobileImg3,
      boundary: `(max-width: ${MOBILE_WIDTH}px)`,
      themeColor: '#CBE2DD',
    },
  ];
  return {
    activeCarousel,
    setActiveCarousel,
    activeThemeColor: carousels[activeCarousel].themeColor,
    carousels,
    webMobileBoundary: MOBILE_WIDTH,
  };
};
