import React from 'react';
import '../CSS/KakaoChannel.scss';
import kakaochannel from '../../../assets/images/img_channel.png';

function KakaoChannel(props) {
  return (
    <div>
      <a href="https://pf.kakao.com/_DYhWxj/chat">
        <img className="kakao-btn" src={kakaochannel} alt="Kakao Chat" />
      </a>
    </div>
  );
}

export default KakaoChannel;
