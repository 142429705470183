import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Layout from 'components/Anyfit/common/Layout';
import styled, { css } from 'styled-components';
import { fontSize, palette } from 'pages/AnyfitMall/theme';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { API_BASE_URL, TOKEN } from './config';
import { jwtDecode } from 'jwt-decode';

const Container2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 16px;
  // min-width: 330px;
  width: 100%;
  max-width: 500px;
`;

const Title2 = styled.h1`
  font-size: 20px;
  color: black;
  margin-bottom: 24px;

  font-weight: bold;
  font-family: 'Noto Sans KR', sans-serif;
  margin-top: 20px;
`;

const OptionList = styled.ul`
  width: 100%;
  list-style: none;
  padding: 0;
`;

const OptionItem = styled.li`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-radius: 8px;
  margin-bottom: 8px; // remove margin for the last item
  background-color: #f7f7f7;
  border: 1px solid #e7e7e7;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
`;

const OptionText = styled.span`
  font-size: 16px;
  flex-grow: 1;
  color: #333;
`;

const CheckMark = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ agreed }) =>
    agreed ? palette.AnyBlue : palette.Grey300};
  margin-right: 16px;
  // Add checkmark styling
  position: relative; // ::after를 위한 위치 설정

  // Add checkmark styling
  ${({ agreed }) =>
    agreed &&
    `
    &::after {
      content: 'v';
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
      font-weight: bold;
    }
  `}
`;

const Button = styled.button`
  background-color: ${({ allAgreed }) =>
    allAgreed ? palette.AnyBlue : palette.Grey300};
  color: white;
  border: none;
  padding: 12px;
  width: 100%;
  border-radius: 4px;
  font-size: 18px;
  margin-top: 16px;
  cursor: ${({ allAgreed }) => (allAgreed ? 'pointer' : 'default')};
  margin-bottom: 30px; // Add some vertical space around the line

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const Separator = styled.div`
  height: 1px;
  background-color: ${palette.Grey300}; // Use the grey color you prefer
  margin-top: 24px; // Add some vertical space around the line
  margin-bottom: 30px; // Add some vertical space around the line
`;

const OptionContent = styled.div`
  background-color: #f7f7f7;
  height: 300px;
  width: 100%;
  margin-bottom: 0.8rem;

  max-height: ${(props) => (props.expanded ? '300px' : '0')};
  overflow-y: auto;
  transition: max-height 0.3s ease;
`;

const OptionInnerContent = styled.div`
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  margin-bottom: 16px;
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const rotateStyles = css`
  transition: transform 0.3s ease-in-out;
  transform: ${(props) =>
    props.direction === 'right' ? 'rotate(90deg)' : 'rotate(0deg)'};
`;

const RotatingImage = styled.img`
  width: 16px; // Set the width as needed
  height: 16px; // Set the height as needed
  margin: auto 0; // Push the image to the right
  ${(props) => props.isRotated && rotateStyles};
`;

const AnyfitAgreement = () => {
  //submit api
  const handleSubmit = async () => {
    if (!allAgreed) {
      alert('모든 조항에 동의해주세요');
      return;
    }

    try {
      // todo : 토큰을 복호화해서 userId 를 넣는다.
      const storageToken = localStorage.getItem('token');
      console.log('storageToken...', storageToken);
      const decodedToken = jwtDecode(storageToken);
      console.log('decodedToken...', storageToken);
      console.log(jwtDecode(storageToken));

      const userId = decodedToken.id; // Replace with the actual user ID
      const response = await axios.post(
        `${API_BASE_URL}/api/createConsentAgreements`,
        {
          personal_info_collection_consent: agreements[2],
          sensitive_info_collection_consent: agreements[4],
          personal_info_third_party_consent: agreements[3],
          sensitive_info_third_party_consent: agreements[5],
        },
        {
          headers: {
            Authorization: `Bearer ${storageToken}`,
          },
        },
      );
      console.log('response...', response);

      if (response.data.isSuccess) {
        alert('동의하였습니다.');

        // todo : 동의가 되면 anyfit/productList 로 이동한다.
        navigate('/anyfit/productDetailV2?TOKEN=' + storageToken);
      } else {
        console.log(response);
        alert('상품 페이지로 이동합니다.');
        navigate('/anyfit/productDetailV2?TOKEN=' + storageToken);
      }
    } catch (error) {
      console.error('Failed to submit agreements:', error);
      alert('동의에 실패하였습니다.');
    }
  };

  // 상태관리
  const navigate = useNavigate();
  const [allAgreed, setAllAgreed] = useState(false);
  const [agreements, setAgreements] = useState({
    2: false,
    3: false,
    4: false,
    5: false,
  });

  const handleAllAgree = () => {
    const newState = !allAgreed;
    setAllAgreed(newState);
    setAgreements({
      2: newState,
      3: newState,
      4: newState,
      5: newState,
    });
  };

  const handleAgreementChange = (id) => {
    const updatedAgreements = { ...agreements, [id]: !agreements[id] };
    setAgreements(updatedAgreements);
    // Check if all individual agreements are true
    setAllAgreed(Object.values(updatedAgreements).every((v) => v));
  };

  // Dummy options data
  const options = [
    { id: 2, text: '[필수] 개인정보 수집이용 동의' },
    { id: 3, text: '[필수] 개인정보 제3자제공 동의 ' },
    { id: 4, text: '[필수] 민감정보 수집이용 동의 ' },
    { id: 5, text: '[필수] 민감정보 제3자제공 동의 ' },
  ];
  const renderContent = (id) => {
    switch (id) {
      case 2:
        return (
          // add margin
          <OptionContent expanded={expandedItems[id]}>
            <OptionInnerContent>
              □ 개인정보 수집·이용 동의 <br />
              <br />
              - 수집 및 이용 목적: 본인 확인, 서비스 제공, 회원 관리, 민원 처리,
              건강상태 확인, 맞춤형 건강관리 서비스 제공
              <br />
              - 수집하는 정보 항목: 성명, 생년월일, 이메일 주소, 애니핏 회원ID,
              상품 구매·신청·환불 정보
              <br />
              - 보유 및 이용 기간: 회원 탈퇴 시까지 보유하며, 관련 법령에 따른
              보관 기간이 남아있는 경우 해당 기간 동안 보관
              <br />
              - 동의를 거부할 권리 및 불이익: 본 동의를 거부할 권리가 있으며,
              거부할 경우 서비스 이용에 제한이 있을 수 있습니다.
              <br />
              <br />본 동의는 슈가핏 서비스를 이용하는 데 필요한 필수 사항으로,
              개인정보의 수집 및 이용에 대해 동의하시는 경우 아래 확인란에
              체크해 주시기 바랍니다.
            </OptionInnerContent>
          </OptionContent>
        );
      case 3:
        return (
          <OptionContent expanded={expandedItems[id]}>
            <OptionInnerContent>
              □ 개인정보 제3자 제공 동의 <br />
              <br />
              - 정보를 제공받는 자: (주)삼성화재해상보험
              <br />
              <br />
              - 제공받는 자의 이용 목적:
              <br />
              ㆍ애니핏플러스 슈가핏 서비스 제공 및 개선
              <br />
              ㆍ애니핏플러스 기본 서비스(건강관리 프로그램 등) 제공 및 개선
              <br />
              ㆍ신규 서비스 개발 및 통계 분석
              <br />
              ㆍ고객 문의 응대 및 문제 해결
              <br />
              <br />
              - 제공하는 정보 항목: 애니핏 회원ID, 상품 구매·신청·환불 정보
              <br />
              - 제공받는 자의 보유 및 이용 기간: 애니핏플러스 서비스 해지 시까지
              보유하며, 관련 법령에 따른 보관 기간이 남아있는 경우 해당 기간
              동안 보관
              <br />
              - 동의를 거부할 권리 및 불이익: 본 동의를 거부할 권리가 있으며,
              거부할 경우 서비스 이용에 제한이 있을 수 있습니다.
              <br />
              <br />본 동의는 슈가핏 서비스를 이용하는 데 필요한 필수 사항으로,
              개인정보의 제3자 제공에 대해 동의하시는 경우 아래 확인란에 체크해
              주시기 바랍니다.
            </OptionInnerContent>
          </OptionContent>
        );
      case 4:
        return (
          <OptionContent expanded={expandedItems[id]}>
            <OptionInnerContent>
              □ 민감정보 수집·이용 동의 <br />
              <br />
              - 수집 및 이용 목적: 건강 상태 확인, 맞춤형 건강관리 서비스 제공
              <br />
              - 수집하는 정보 항목: 혈당 수치, 측정 시간
              <br />
              - 보유 및 이용 기간: 회원 탈퇴 시까지 보유하며, 관련 법령에 따른
              보관 기간이 남아있는 경우 해당 기간 동안 보관
              <br />
              - 동의를 거부할 권리 및 불이익: 본 동의를 거부할 권리가 있으며,
              거부할 경우 서비스 이용에 제한이 있을 수 있습니다.
              <br />
              <br />본 동의는 슈가핏 서비스를 이용하는 데 필요한 필수 사항으로,
              민감정보의 수집 및 이용에 대해 동의하시는 경우 아래 확인란에
              체크해 주시기 바랍니다.
            </OptionInnerContent>
          </OptionContent>
        );
      case 5:
        return (
          <OptionContent expanded={expandedItems[id]}>
            <OptionInnerContent>
              □ 민감정보 제3자 제공 동의 <br />
              <br />
              - 정보를 제공받는 자: (주)삼성화재해상보험
              <br />
              <br />
              - 제공받는 자의 이용 목적:
              <br />
              ㆍ애니핏플러스 슈가핏 서비스 제공 및 개선
              <br />
              ㆍ애니핏플러스 기본 서비스(건강관리 프로그램 등) 제공 및 개선
              <br />
              ㆍ신규 서비스 개발 및 통계 분석
              <br />
              ㆍ고객 문의 응대 및 문제 해결
              <br />
              <br />
              - 제공하는 정보 항목: 혈당 수치, 측정 시간
              <br />
              - 제공받는 자의 보유 및 이용 기간: 애니핏플러스 서비스 해지 시까지
              보유하며, 관련 법령에 따른 보관 기간이 남아있는 경우 해당 기간
              동안 보관
              <br />
              - 동의를 거부할 권리 및 불이익: 본 동의를 거부할 권리가 있으며,
              거부할 경우 서비스 이용에 제한이 있을 수 있습니다.
              <br />
              <br />본 동의는 슈가핏 서비스를 이용하는 데 필요한 필수 사항으로,
              민감정보의 제3자 제공에 대해 동의하시는 경우 아래 확인란에 체크해
              주시기 바랍니다.
            </OptionInnerContent>
          </OptionContent>
        );
      default:
        return null;
    }
  };

  // 확장 기능
  const [expandedItems, setExpandedItems] = useState({});
  const [rotatedItems, setRotatedItems] = useState({});

  const toggleExpand = (id) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
    setRotatedItems((prev) => ({
      ...prev,
      [id]: prev[id] === 'right' ? 'left' : 'right',
    }));
  };

  return (
    <div>
      <Layout>
        <Container2>
          <Container>
            <Title2>서비스 제공을 위한 이용 동의</Title2>
            {/* {JSON.stringify(allAgreed)}
          {JSON.stringify(agreements)} */}
            <OptionItem onClick={handleAllAgree}>
              <CheckMark agreed={allAgreed} /> 약관 전체 동의
            </OptionItem>
            <Separator />
            <OptionList>
              {options.map((option) => (
                <OptionContainer>
                  <OptionItem key={option.id}>
                    <CheckMark
                      onClick={() => {
                        handleAgreementChange(option.id);
                      }}
                      agreed={agreements[option.id]}
                    />
                    <OptionText>{option.text}</OptionText>
                    <RotatingImage
                      src="https://glucofit-image.s3.ap-northeast-2.amazonaws.com/LOG/chevron-right.svg"
                      alt="Option icon"
                      onClick={() => toggleExpand(option.id)}
                      isRotated={!!rotatedItems[option.id]}
                      direction={rotatedItems[option.id]}
                    />
                  </OptionItem>
                  {renderContent(option.id)}
                </OptionContainer>
              ))}
            </OptionList>
            <Button
              disabled={!allAgreed}
              allAgreed={allAgreed}
              onClick={() => handleSubmit()}
            >
              동의
            </Button>
          </Container>
        </Container2>
      </Layout>
    </div>
  );
};

export default AnyfitAgreement;
