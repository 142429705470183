import '../Payment/Payment.scss';
import '../Payment/Membership.scss';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from 'components/Footer/Footer';
import axios from 'axios';
import card from '../../assets/icons/ic_card.svg';
import kakao from '../../assets/icons/ic_kakao.svg';
import naverpay from '../../assets/images/img_naverpay.png';
import radioGreen from '../../assets/images/img_radio_green.png';
import AddressFormMembership from 'pages/MembershipOnly/AddressFormMembership';
import TagManager from 'react-gtm-module';
import { REAL_SERVER } from 'config';

// const SENSOR_PRICE = 19000;
function MembershipOnlyPage(props) {
  let muid = '';
  let impUid = '';
  let cuid = '';
  const [sensorCount, setSensorCount] = useState(1);
  const [inviteCode, setInviteCode] = useState('');
  const [buyerName, setBuyerName] = useState('');
  const [buyerPhoneNumber, setBuyerPhoneNumber] = useState('');
  const [userName, setUserName] = useState('');
  const [userPhoneNumber, setUserPhoneNumber] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [method, setMethod] = useState('card');
  const [selectedPrice, setSelectedPrice] = useState('1개월권_활명한방결제_30');
  const [selectedAddress, setSelectedAddress] = useState({
    postcode: '',
    address: '',
  });
  const [SENSOR_PRICE, setSensorPrice] = useState(19000);
  const [detailAddress, setDetailAddress] = useState('');
  const [totalAmount, setTotalAmount] = useState(19000);
  const [finalAmount, setFinalAmount] = useState(19000);
  const [finalAmountNum, setFinalAmountNum] = useState(SENSOR_PRICE);
  const location = useLocation();
  const phoneNumberInputRef = useRef(null);
  const [fullNumber, setFullNumber] = useState(false);
  const [nameInvalid, setNameInvalid] = useState(false);
  const [numberInvalid, setNumberInvalid] = useState(false);
  const [methodInvalid, setMethodInvalid] = useState(false);
  const [addressInvalid, setAddressInvalid] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isParamEmpty, setIsParamEmpty] = useState(false);
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const complete_input = {
    imp_uid: impUid,
    merchant_uid: muid,
  };

  const input = {
    payer_phone_number: buyerPhoneNumber,
    payer_name: buyerName,
    recipient_phone_number: userPhoneNumber,
    recipient_name: userName,
    address: selectedAddress?.address + ' ' + detailAddress,
    postal_code: selectedAddress?.postcode,
    amount: finalAmountNum,
    // is_subscription: null,
    // subscription_month_cnt: null,
    // subscription_month_amount: null,
    // invite_code: inviteCode,
  };

  const tagManagerArgs = {
    gtmId: 'GTM-57FDQMS',
  };

  // useEffect(() => {
  //   // const storedSelectedPrice = localStorage.getItem('selectedPrice');
  //   const selectedPriceFromState = location.state?.selectedPrice;
  //   // setSelectedPrice(selectedPriceFromState ?? storedSelectedPrice);
  // }, [location.state]);

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const name = urlParams.get('name');
    const phoneNumber = urlParams.get('phone_number');

    // const storedSelectedPrice = localStorage.getItem('selectedPrice');
    const storedBuyerName = localStorage.getItem('buyerName');
    const storedBuyerPhoneNumber = localStorage.getItem('buyerPhoneNumber');
    const storedPostCode = localStorage.getItem('postcode');
    const storedAddress = localStorage.getItem('address');
    const storedSensorCount = parseInt(localStorage.getItem('sensorCount'));
    const initialSensorCount = !isNaN(storedSensorCount)
      ? storedSensorCount
      : 1;

    setSelectedPrice('1개월권_활명한방결제_30');
    setSelectedAddress({ postcode: storedPostCode, address: storedAddress });
    setSensorCount(initialSensorCount);
    setFinalAmountNum(19000);

    if (!name || !phoneNumber) {
      setIsParamEmpty(true);
      setBuyerName(storedBuyerName ?? buyerName);
      setBuyerPhoneNumber(storedBuyerPhoneNumber ?? buyerPhoneNumber);
    } else {
      setBuyerName(name || '');
      setBuyerPhoneNumber(formatPhoneNumber(phoneNumber) || '');
      localStorage.setItem('savedURL', window.location.href);
      localStorage.setItem('buyerName', name || '');
      localStorage.setItem(
        'buyerPhoneNumber',
        formatPhoneNumber(phoneNumber) || '',
      );
      localStorage.setItem('buyerPhoneNumberSensor', phoneNumber || '');
      setIsParamEmpty(false);
    }

    if (storedPostCode && storedAddress) {
      document.getElementById('sample6_detailAddress').click();
    } else {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (
      buyerName !== '' &&
      buyerPhoneNumber !== '' &&
      buyerPhoneNumber.length === 13 &&
      method !== '' &&
      selectedAddress.address !== null &&
      selectedAddress.address !== undefined
    ) {
      setIsButtonClicked(true);
    } else {
      setIsButtonClicked(false);
    }
  }, [buyerName, buyerPhoneNumber, method, selectedAddress.address]);

  const handleAddressSelect = (address) => {
    setSelectedAddress(address);
  };

  const handlePhoneNumberChange = (e, type) => {
    const formattedPhoneNumber = e.target.value.replace(/[^\d]/g, '');
    const validPhoneNumber = formatPhoneNumber(formattedPhoneNumber);
    const inputElement = e.target;
    let cursorPosition = inputElement.selectionStart;

    if (formattedPhoneNumber.length <= 11) {
      if (type === 'user') {
        localStorage.setItem('userPhoneNumber', validPhoneNumber);
        setUserPhoneNumber(localStorage.getItem('userPhoneNumber'));
      } else {
        localStorage.setItem('buyerPhoneNumber', validPhoneNumber);
        setBuyerPhoneNumber(localStorage.getItem('buyerPhoneNumber'));
      }
      if (formattedPhoneNumber.length === 11) {
        setFullNumber(true);
      }
      if (fullNumber && formattedPhoneNumber.length === 10) {
        setFullNumber(false);
        inputElement.value = validPhoneNumber;
        inputElement.setSelectionRange(cursorPosition, cursorPosition - 1);
      }
    } else {
      const truncatedPhoneNumber = formattedPhoneNumber.slice(0, 11);
      const truncatedValidPhoneNumber = formatPhoneNumber(truncatedPhoneNumber);
      if (type === 'user') {
        localStorage.setItem('userPhoneNumber', truncatedValidPhoneNumber);
        setUserPhoneNumber(localStorage.getItem('userPhoneNumber'));
      } else {
        localStorage.setItem('buyerPhoneNumber', truncatedValidPhoneNumber);
        setBuyerPhoneNumber(localStorage.getItem('buyerPhoneNumber'));
      }
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    const phoneNumberRegex = /^(\d{3})(\d{4})(\d{4})$/;
    const matches = phoneNumber.match(phoneNumberRegex);
    if (matches) {
      return `${matches[1]}-${matches[2]}-${matches[3]}`;
    }
    return phoneNumber;
  };

  const handleNameKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      phoneNumberInputRef.current.focus();
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handlePaymentMethodSelect = (selectedMethod) => {
    if (method === selectedMethod) {
      setMethod('');
    } else {
      setMethod(selectedMethod);
    }
  };

  const handlePayment = () => {
    if (buyerName === '') {
      setNameInvalid(true);
    } else {
      setNameInvalid(false);
    }

    if (buyerPhoneNumber === '' || buyerPhoneNumber.length !== 13) {
      setNumberInvalid(true);
    } else {
      setNumberInvalid(false);
    }

    if (method === '') {
      setMethodInvalid(true);
    } else {
      setMethodInvalid(false);
    }

    if (
      selectedAddress.address === null ||
      selectedAddress.address === undefined
    ) {
      setAddressInvalid(true);
    } else {
      setAddressInvalid(false);
    }

    if (
      buyerName !== '' &&
      buyerPhoneNumber !== '' &&
      buyerPhoneNumber.length === 13 &&
      method !== '' &&
      selectedAddress.address !== null
    ) {
      window.gtag('event', 'conversion', {
        send_to: 'AW-10992256925/ulvjCNnzqKwYEJ2Pwvko',
      });
      createOrder();
    }
  };

  // 일반결제 관련 (completeOrder, requestPay, createOrder)
  const completeOrder = async () => {
    try {
      const response = await axios.post(REAL_SERVER, {
        query: `
              mutation completeOrder($input: complete_order_input!) {
                completeOrder(complete_order_input: $input)
              }
            `,
        variables: {
          input: complete_input,
        },
      });
      if (response.data.data.completeOrder === 'success') {
        navigate('/successreward');
      }
    } catch (error) {
      console.log('nocomplete');
      console.error(error);
    }
  };

  const requestPay = (merchantUid) => {
    window.IMP.request_pay(
      {
        // pg: 'tosspayments.iamporttest_4', // 테스트
        pg: 'tosspayments', // 실결제
        pay_method: method,
        // m_redirect_url: 'http://localhost:3000/redirectsensoronly', //테스트
        m_redirect_url: 'https://glucofit.co.kr/redirectsensoronly', //실결제
        merchant_uid: merchantUid,
        name: '글루코핏_' + selectedPrice,
        amount: finalAmountNum,
        buyer_name: buyerName,
        buyer_tel: buyerPhoneNumber,
        buyer_addr: selectedAddress?.address + ' ' + detailAddress,
        buyer_postcode: selectedAddress?.postcode,
      },
      (response) => {
        if (Object.keys(response).includes('error_code')) {
          alert(response.error_msg);
          // 결제 취소일때 원래 페이지로
          // navigate('/requestpay');
        } else {
          complete_input.imp_uid = response.imp_uid;
          complete_input.merchant_uid = response.merchant_uid;
          completeOrder();
        }
      },
    );
  };

  const createOrder = async () => {
    try {
      const response = await axios.post(REAL_SERVER, {
        query: `
              mutation createOrder($input: create_order_input!) {
                createOrder(create_order_input: $input) {
                  merchant_uid
                  customer_uid
                }
              }
            `,
        variables: {
          input: input,
        },
      });
      if (response.status === 200) {
        muid = response.data.data.createOrder.merchant_uid;
        // console.log(response.data.data.createOrder);
        // console.log(input);
        requestPay(muid);
      } else {
        alert('고객센터로 문의하세요.');
      }
    } catch (error) {
      console.error(error);
      alert('고객센터로 문의하세요.');
    }
  };

  return (
    <div>
      <div className="sensoronly-payment-body">
        <div className="payment-left">
          <div>
            <div className="payment-title">결제</div>
            <div className="payment-subtitle">결제할 상품</div>
            <div>
              <div
                className="membership-container selected"
                onClick={() => {
                  // setSelectedPrice('센서구매_일반결제_0');
                  // localStorage.setItem('selectedPrice', '센서구매_일반결제_0');
                }}
              >
                <img className="radio-icon-sensor" src={radioGreen} alt="img" />
                <div className="membership-textcontainer-sensor">
                  <div className="membership-subtextcontainer">
                    <div> 글루코핏 1개월 멤버십</div>
                  </div>
                  {/* <div className={`sensoronly-counter `}>
                    <div
                      className={`sensoronly-minus ${sensorCount === 0 ? 'zero-count' : ''}`}
                      onClick={onDecrease}
                    >
                      -
                    </div>
                    <div id='result'>{sensorCount}</div>
                    <div className='sensoronly-plus' onClick={onIncrease}>
                      +
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="sensoronly-description">
                <li>
                  글루코핏 회원 분들은 CGM 센서를 최저가에 구매할 수 있습니다.
                </li>
                <div className="sensoronly-mobilesubtitle">
                  <li>
                    멤버십 기간이 종료되는 경우 글루코핏 어플과 CGM 센서의
                    연동이 끊기게 되니 멤버십 기간을 잘 확인해주세요.
                  </li>
                </div>
                {/* <div className='sensoronly-pcsubtitle'>
                  <li>
                    멤버십 기간이 종료되는 경우 글루코핏 어플과 CGM 센서의 연동이 끊기게 되니 멤버십
                    기간을 잘 확인해주세요.
                  </li>
                </div> */}
              </div>
            </div>
          </div>
          <div>
            <div className="payment-total-subtitle-top">결제 금액</div>
            <div className="payment-total-container-top">
              <div>
                <div className="payment-total-textContainer">
                  <div>총 상품 금액</div>
                  <div> 월 {totalAmount.toLocaleString()}원</div>
                </div>
              </div>
              <div className="payment-total-price">
                <div>최종 결제 금액</div>
                <div>
                  <div className="green3">
                    월 {totalAmount.toLocaleString()} 원
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="payment-subtitle">구매자 정보</div>
            <div className="payment-info-container">
              <div className="payment-info">이름(실명)</div>
              <input
                value={localStorage.getItem('buyerName') ?? buyerName}
                ref={inputRef}
                placeholder="센서 발송을 위해 실명으로 입력해 주세요"
                onChange={(e) => {
                  localStorage.setItem('buyerName', e.target.value);
                  setBuyerName(localStorage.getItem('buyerName'));
                }}
                onKeyDown={handleNameKeyDown}
                className={nameInvalid ? 'error' : ''}
              />
              <div className="validation-text">
                {nameInvalid ? '이름을 입력해 주세요' : ''}
              </div>
            </div>
            <div className="payment-info-container">
              <div className="payment-info">휴대폰 번호</div>
              <input
                ref={phoneNumberInputRef}
                value={
                  localStorage.getItem('buyerPhoneNumber') ??
                  localStorage.getItem('buyerPhoneNumber')
                }
                placeholder="휴대폰 번호"
                inputMode="numeric"
                onChange={(e) => handlePhoneNumberChange(e, 'buyer')}
                className={numberInvalid ? 'error' : ''}
              />
              <div className="validation-text">
                {numberInvalid ? '휴대폰 번호를 입력해 주세요' : ''}
              </div>
            </div>
            <div className="payment-info-container">
              <div className="payment-info">제품 배송지</div>
              <AddressFormMembership
                handleAddressSelect={handleAddressSelect}
                addressInvalid={addressInvalid}
                isParamEmpty={isParamEmpty}
              />
              <input
                type="text"
                id="sample6_detailAddress"
                placeholder="상세주소 (예: 101동 101호)"
                value={detailAddress}
                onChange={(e) => setDetailAddress(e.target.value)}
                ref={inputRef}
              />
            </div>
            <div className="payment-checkbox" onClick={handleCheckboxChange}>
              <div className="checkbox">
                {isChecked && <div className="checkbox-icon" />}
              </div>
              <div>구매자와 실 사용자가 달라요</div>
            </div>
            {isChecked && (
              <div>
                <div className="payment-subtitle">실 사용자 정보</div>
                <div className="payment-info-container">
                  <div className="payment-info">이름(실명)</div>
                  <input
                    value={localStorage.getItem('userName') ?? userName}
                    placeholder="센서 발송을 위해 실명으로 입력해 주세요"
                    onChange={(e) => {
                      localStorage.setItem('userName', e.target.value);
                      setUserName(localStorage.getItem('userName'));
                    }}
                    onKeyDown={handleNameKeyDown}
                  />
                </div>
                <div className="payment-info-container">
                  <div className="payment-info">휴대폰 번호</div>
                  <input
                    ref={phoneNumberInputRef}
                    value={
                      localStorage.getItem('userPhoneNumber') ?? userPhoneNumber
                    }
                    inputMode="numeric"
                    placeholder="휴대폰 번호"
                    onChange={(e) => handlePhoneNumberChange(e, 'user')}
                  />
                </div>
              </div>
            )}
            <div className="payment-subtitle">
              결제 수단
              <div className="validation-text-method">
                {methodInvalid ? '결제 수단을 선택해 주세요' : ''}
              </div>
            </div>
            <div className="payment-method">
              <div
                className={`payment-method-container ${
                  method === 'card' ? 'selected' : ''
                }`}
                onClick={() => handlePaymentMethodSelect('card')}
              >
                <img className="payment-ic-card" src={card} alt="img" />
                <div>신용/체크카드</div>
              </div>
              <div
                className={`payment-method-container ${
                  method === 'kakaopay' ? 'selected' : ''
                }`}
                onClick={() => handlePaymentMethodSelect('kakaopay')}
              >
                <img className="payment-ic-kakao" src={kakao} alt="img" />
                <div>카카오페이</div>
              </div>
              <div
                className={`payment-method-container ${
                  method === 'naverpay' ? 'selected' : ''
                }`}
                onClick={() => handlePaymentMethodSelect('naverpay')}
              >
                <img className="payment-ic-naver" src={naverpay} alt="img" />
                <div>네이버페이</div>
              </div>
            </div>
          </div>
        </div>
        <div className="payment-right">
          <div className="payment-total-container-bottom">
            <div className="payment-total-subtitle">결제 금액</div>
            <div>
              <div className="payment-total-textContainer">
                <div>총 상품 금액</div>
                <div>{finalAmount}원</div>
              </div>
            </div>
            <div className="payment-total-price">
              <div>최종 결제 금액</div>
              <div>
                <div className="green3">{finalAmount}원</div>
              </div>
            </div>
          </div>
          <button
            className={`payment-totalAccept-button ${
              isButtonClicked ? 'clicked' : ''
            }`}
            onClick={handlePayment}
          >
            {finalAmount}원 결제하기
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default MembershipOnlyPage;
