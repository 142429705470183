import React, { useEffect } from 'react';
import { logEvent } from '../Main/Components/Amplitude';

function Blog(props) {
  // logEvent('glucofitreview_entered', 10102);
  useEffect(() => {
    window.location.href = 'https://glucofit.framer.website/review';
  }, []);
  return <div />;
}

export default Blog;
