import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Layout from 'components/Anyfit/common/Layout';
import AddIcon from '../../assets/icons/ic-edit-filled.svg';
import styled from 'styled-components';
import { fontSize, palette } from 'pages/AnyfitMall/theme';
import { useNavigate } from 'react-router-dom';
import AnyfitBackButton from 'components/Anyfit/AnyfitBackButton';

const NotContentContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NotContent = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  color: ${palette.Gray400};
  margin-top: 3rem;
`;

const Top = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 1.5rem;

  position: relative;
  margin-top: 60px;
`;

const Title = styled.div`
  width: 100vw;
  color: #141414;
  // text-align: center;
  font-size: 20px;
  font-weight: bold;
  font-family: 'Noto Sans KR', sans-serif;

  position: absolute;
  left: 1rem;
`;

const AnyfitErrorPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log('API_BASE_URL...', process.env.REACT_APP_ANYFIT_API_URL);
    console.log('API_BASE_URL...', process.env.REACT_APP_ENV);
  }, []);
  return (
    <Layout>
      <Top>{/* <Title>글루코핏 혈당케어</Title> */}</Top>
      <NotContentContainer>
        <NotContent>
          접속 시한이 만료됐습니다. <br />
          애니핏플러스 앱을 통해 재접속해주세요
        </NotContent>
      </NotContentContainer>

      <AnyfitBackButton
        onClick={() => {
          window.location.href = 'https://sfplus.page.link/plus';
        }}
      >
        애니핏 플러스로 바로가기
      </AnyfitBackButton>
    </Layout>
  );
};

export default AnyfitErrorPage;
