import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Layout from 'components/Anyfit/common/Layout';
import AddIcon from '../../assets/icons/ic-edit-filled.svg';
import styled from 'styled-components';
import { fontSize, palette } from 'pages/AnyfitMall/theme';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from './config';
import { validateToken } from 'pages/AnyfitMall/common';
import {
  validateStorageToken,
  parseTokenFromUrl,
  getTokenFromStorage,
} from './common';
import AnyfitBackButton from 'components/Anyfit/AnyfitBackButton';

const NotContentContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NotContent = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  color: ${palette.Gray400};
  margin-top: 3rem;
`;

const QuestionListContainer = styled.div`
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  height: 100vh;

  width: 100%;
`;
const ContentContainer = styled.div`
  height: max-content;
  border: 1px solid ${palette.LightGray};
  border-radius: 0.25rem;

  width: 100%;
  margin-bottom: 2rem;
`;

const Top = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 1.5rem;

  position: relative;
  margin-top: 60px;
`;

const Title = styled.div`
  width: 100vw;
  color: #141414;
  // text-align: center;
  font-size: 20px;
  font-weight: bold;
  font-family: 'Noto Sans KR', sans-serif;

  position: absolute;
  left: 1rem;
`;

const QuestionAddButton = styled.button`
  display: flex;
  align-items: center;
  width: 7rem;
  height: 2.5rem;
  font-size: 0.9rem;
  padding-left: 1rem;

  position: absolute;
  right: 1rem;

  font-weight: bold;
  background-color: ${palette.White};
  border: 1px solid ${palette.LightGray};
  border-radius: 0.25rem;
  cursor: pointer;

  img {
    width: 1rem;
    height: 1rem;
    margin: 0;
  }
`;

const StatusLabel = styled.div`
  color: ${(props) =>
    props.type === 'COMPLETED' ? palette.Blue600 : palette.Gray500};
  background-color: ${(props) =>
    props.type === 'COMPLETED' ? palette.Blue100 : palette.Gray200};
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.25rem;
  font-size: 0.625rem;
  font-weight: bold;
  border-radius: 0.25rem;
  padding: 0 0.5rem;
`;

const DateLabel = styled.div`
  font-size: 0.625rem;
  font-weight: normal;
  color: ${palette.Gray550};
`;

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const QuestionHeader = styled.div`
  width: 100%;
  padding: 0.625rem 1rem;
  border-bottom: 1px solid ${palette.Gray200};
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

const QuestionTitle = styled.div`
  font-size: 0.875rem;
  color: ${palette.Gray900};
  font-weight: bold;
  width: 100%;
`;

const QuestionContent = styled.div`
  background-color: ${palette.Gray100};
  font-weight: normal;
  padding: 1rem 1.25rem;
  font-size: 0.8125rem;

  width: 100%;
`;

const ResponseContainer = styled.div`
  height: max-content;
  width: 100%;
  border: 1px solid ${palette.LightGray};
  border-radius: 0.25rem;
  background-color: ${palette.White};
  padding: 1rem;
  margin-top: 0.625rem;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;

const ResponderLabel = styled.div`
  font-size: 0.625rem;
  font-weight: bold;
  color: ${palette.Orange600};
`;

const AnyfitQuestionList = () => {
  const navigate = useNavigate();
  const [questionList, setQuestionList] = useState([]);
  const [userData, setUserData] = useState(null);

  const statusMap = {
    COMPLETED: '답변완료',
    PENDING: '답변대기',
  };

  const handleClick = () => {
    navigate(`/anyfit/question/add`);
  };

  const formatDateTime = (isoString) => {
    // ISO 8601 형식의 문자열을 Date 객체로 파싱
    const date = new Date(isoString);

    // 원하는 형식 지정
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    };

    // 'ko-KR' 로케일에 따라 원하는 형식으로 변환
    const formatter = new Intl.DateTimeFormat('ko-KR', options);
    const formattedDateTime = formatter
      .format(date)
      .replace(/-/g, '.')
      .replace(/,/g, '');

    // 공백을 넣어 시각과 날짜 구분
    return formattedDateTime.replace(/ /, ' ');
  };

  useEffect(() => {
    const fetchQuestionList = async (userId, token) => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/questions?userId=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        console.log('questionList...', response.data);
        return response.data;
      } catch (error) {
        console.error('Failed to fetch question list:', error);
      }
    };

    const initialize = async () => {
      let token = parseTokenFromUrl();
      console.log('parseTokenFromUrl...', token);

      if (token) {
        const validationResult = await validateToken(token);
        console.log('validationResult...', validationResult);

        if (validationResult.result) {
          setUserData(validationResult.data);
          const questions = await fetchQuestionList(
            validationResult.data.id,
            token,
          );
          setQuestionList(questions);
          localStorage.setItem('token', token);
          return;
        }
      }

      token = getTokenFromStorage();
      console.log('getTokenFromStorage...', token);
      if (token) {
        const validationResult = await validateToken(token);
        if (validationResult.result) {
          setUserData(validationResult.data);
          const questions = await fetchQuestionList(
            validationResult.data.id,
            token,
          );
          setQuestionList(questions);
          return;
        }
      }

      alert('토큰이 유효하지 않습니다.');
      navigate('/anyfit/error');
    };

    window.scrollTo(0, 0);
    initialize();
  }, [navigate]);

  return (
    <Layout>
      <Top>
        <Title>문의하기</Title>
        <QuestionAddButton onClick={handleClick}>
          <img src={AddIcon} alt="" className="문의등록" /> 문의등록
        </QuestionAddButton>
      </Top>
      {!questionList || questionList.length === 0 ? (
        <NotContentContainer>
          <NotContent>
            문의글이 <br />
            없습니다.
          </NotContent>
        </NotContentContainer>
      ) : (
        <QuestionListContainer>
          {questionList.map((productItem) => {
            return (
              <ContentContainer key={productItem.id}>
                <QuestionHeader>
                  <FlexBox>
                    <StatusLabel type={productItem.status}>
                      {statusMap[productItem.status]}
                    </StatusLabel>
                    <DateLabel>
                      {formatDateTime(productItem.created_at)}
                    </DateLabel>
                  </FlexBox>
                  <QuestionTitle>{productItem.title}</QuestionTitle>
                </QuestionHeader>
                <QuestionContent>
                  <div>{productItem.content}</div>
                  {productItem.responses?.map((r) => (
                    <ResponseContainer key={r.created_at}>
                      <FlexBox>
                        <ResponderLabel className="status">
                          관리자
                        </ResponderLabel>
                        <DateLabel>{formatDateTime(r.created_at)}</DateLabel>
                      </FlexBox>
                      <div>{r.content}</div>
                    </ResponseContainer>
                  ))}
                </QuestionContent>
              </ContentContainer>
            );
          })}
        </QuestionListContainer>
      )}

      <AnyfitBackButton
        onClick={() => {
          window.location.href = 'https://sfplus.page.link/plus';
        }}
      >
        애니핏 플러스로 바로가기
      </AnyfitBackButton>
    </Layout>
  );
};

export default AnyfitQuestionList;
