import { useNavigate } from 'react-router-dom';
import '../CSS/Banner.scss';
import BannerImg from 'assets/images/ver3.0/img-welstory-banner.png';

export default function Banner() {
  const navigate = useNavigate();

  const handleApplyButtonClicked = () => {
    navigate('/welstory/payment');
  };
  return (
    <div>
      <button
        className="floating-button strong"
        onClick={handleApplyButtonClicked}
      >
        2주특별가 <span>99,500원</span>
      </button>
      <img
        src={BannerImg}
        className="welstory-banner-img"
        alt="웰스토리과 글루코핏이 함께하는 특별이벤트, 혈당관리는 의사가 만든 글루코핏으로"
      />
    </div>
  );
}
