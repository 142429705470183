import './GlucofitMall.scss';
import { React, useEffect } from 'react';
import Footer from 'components/Footer/Footer';
import Nav from 'components/Nav/Nav';
import libre from '../../assets/images/img_libre.png';
import patch from '../../assets/images/img_patch.png';
import { useNavigate, useParams } from 'react-router-dom';

function GlucofitMallPage(props) {
  const navigate = useNavigate();
  const { itemId } = useParams();

  useEffect(() => {
    localStorage.clear();
  }, []);

  const PRODUCTS = [
    {
      id: '1',
      img: libre,
      title: (
        <>
          프리스타일 리브레 1개 +
          <br />
          패치 2매 + 알콜솜 2매
        </>
      ),
      price: '₩ 89,000',
    },
    {
      id: '2',
      img: patch,
      title: (
        <>
          프리스타일 리브레
          <br />
          살색 패치 (20매)
        </>
      ),
      price: '₩ 6,900',
    },
  ];

  return (
    <div className="mall-container">
      <Nav />
      <div className="mall-product-container">
        {PRODUCTS.map((prod, index) => (
          <div
            onClick={() => navigate(`/mall/detail/${prod.id}`)}
            className={`product-item ${index === 0 ? 'item1' : 'item2'}`}
          >
            <img src={prod.img} alt="img" />
            <div className="product-title">{prod.title} </div>
            <div className="product-price">{prod.price}</div>
            <div className="product-free">무료 배송</div>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
}

export default GlucofitMallPage;
