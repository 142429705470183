import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from 'components/Anyfit/common/Layout';
import styled from 'styled-components';
import { palette } from 'pages/AnyfitMall/theme';
import { API_BASE_URL } from './config';
import { validateStorageToken } from './common';
import DaumPostcode from './anyfitDaumPost';

const ICON_CHECKED =
  'https://glucofit-image.s3.ap-northeast-2.amazonaws.com/LOG/ic_check-circle_filled_21.png';
const ICON_UNCHECKED =
  'https://glucofit-image.s3.ap-northeast-2.amazonaws.com/LOG/ic_check-circle_filled_20.png';

const Title = styled.div`
  color: #141414;
  font-size: 20px;
  font-weight: bold;
  font-family: 'Noto Sans KR', sans-serif;
  margin-top: 60px;
  padding-left: 10px;
`;

const SmallTitle = styled.div`
  color: #141414;
  font-size: 20px;
  font-family: 'Noto Sans KR', sans-serif;
  margin-top: 40px;
  margin-bottom: 20px;
  padding-left: 10px;
`;

const ProductImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  margin-left: 10px;
  margin-right: 10px;
`;

// 이미지를 감싸는 컨테이너 스타일 컴포넌트
const ImageContainer = styled.div`
  /* 여기에 컨테이너의 스타일을 정의하세요 */
  width: 100%;
  border-radius: 8px;
`;

const ImageContainer2 = styled.div`
  /* 여기에 컨테이너의 스타일을 정의하세요 */
  width: 52px;
  border-radius: 8px;

  margin-top: 20px;
  margin-bottom: 20px;
`;
// 이미지 스타일 컴포넌트
const Image = styled.img`
  /* 이미지의 스타일을 정의합니다 */
  width: 100%;
  height: 100%;
  object-fit: cover; /* 이미지를 비율유지하며 컨테이너에 맞추기 위해 */
  position: relative;
  left: 5px;

  border-top-left-radius: 10px; /* 상단 왼쪽 모서리에만 border radius 적용 */
  border-top-right-radius: 10px; /* 상단 오른쪽 모서리에만 border radius 적용 */
`;
const ImageContainer3 = styled.div`
  /* 여기에 컨테이너의 스타일을 정의하세요 */
  width: 20px;
  height: 42px;
  border-radius: 8px;
`;
const Image3 = styled.img`
  /* 이미지의 스타일을 정의합니다 */
  width: 24px;
  height: 24px;
  object-fit: cover; /* 이미지를 비율유지하며 컨테이너에 맞추기 위해 */

  position: relative;
  left: 12px;
`;

const ProductImageContrainerName = styled.div`
  color: #141414;
  font-size: 20px;
  font-family: 'Noto Sans KR', sans-serif;
  margin-top: 20px;
  // margin-bottom: 20px;
  padding-bottom: 20px;
  padding-left: 10px;

  width: 100%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;

  // bottom line grey
  border-bottom: 1px solid #e1e1e1;
`;

const ProductImageContrainerBenefit = styled.div`
  color: #141414;
  font-size: 16px;
  font-family: 'Noto Sans KR', sans-serif;
  margin-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;

  width: 100%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
`;

const ProductItemList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BuyerInfoInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-left: 10px;
  margin-right: 10px;
`;

// 스타일이 적용된 입력 컴포넌트
const InputTitle = styled.div`
  display: flex;
  width: 100%;
  text-align: left;
  color: ${palette.Gray500};
`;

const StyledInput = styled.input`
  width: 100%; // 입력 필드의 너비를 최대로 설정
  padding: 8px 16px; // 안쪽 여백 설정
  border: 1px solid #ccc; // 테두리 색상 설정
  border-radius: 8px; // 테두리 둥글게 설정
  font-size: 16px; // 글꼴 크기 설정
  box-shadow: none; // 상자 그림자 없음
  margin-bottom: 12px; // 입력 필드 아래 여백 추가

  &:focus {
    border-color: #0056b3; // 포커스 상태에서 테두리 색상 변경
    outline: none; // 기본 아웃라인 제거
  }

  &::placeholder {
    color: ${palette.Gray400};

`;

// Layout Container
const AddressContainerLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center; // 세로 정렬
`;

// Address Container 1
const AddressContainer1 = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%; // 버튼 사이즈 20%
`;

// Styled div for displaying data
const DisabledInput = styled.div`
  border: 1px solid grey;
  background-color: #f0f0f0;
  flex-grow: 1;

  padding: 8px 16px; // 안쪽 여백 설정
  border: 1px solid #ccc; // 테두리 색상 설정
  border-radius: 8px; // 테두리 둥글게 설정
  font-size: 16px; // 글꼴 크기 설정
  box-shadow: none; // 상자 그림자 없음
  margin-bottom: 12px; // 입력 필드 아래 여백 추가
  margin-top: 12px; // 입력 필드 아래 여백 추가

  height: 100%; // 입력 창의 높이 고정
  min-height: 40px;
  color: ${palette.Gray500};
`;

// Address Container 2 and 3
const AddressContainer2 = styled.div`
  flex: 1;
  width: 100%; // 버튼 사이즈 20%
`;
const AddressContainer3 = styled.div`
  flex: 1;
  width: 100%; // 버튼 사이즈 20%
`;

const PostCodeButton = styled.button`
  max-width: 100px;
  min-width: 100px;
  width: 20%;
  background-color: white;
  color: ${palette.AnyBlue};

  margin-left: 12px;
  border: 1px solid ${palette.AnyBlue}; // 테두리 색상 설정
  border-radius: 8px; // 테두리 둥글게 설정
  font-size: 16px; // 글꼴 크기 설정
  box-shadow: none; // 상자 그림자 없음
  margin-bottom: 12px; // 입력 필드 아래 여백 추가
  margin-top: 12px; // 입력 필드 아래 여백 추가

  cursor: pointer;
  transition: filter 0.3s ease;

  &:hover {
    filter: brightness(90%); // 밝기를 90%로 줄임
  }
`;

const PayMethodContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  margin-left: 10px;
`;

const PayMethodItem = styled.div`
  width: 100%; // 입력 필드의 너비를 최대로 설정
  padding: 8px 16px; // 안쪽 여백 설정
  border: 1px solid #ccc; // 테두리 색상 설정
  border-radius: 8px; // 테두리 둥글게 설정
  font-size: 16px; // 글꼴 크기 설정
  box-shadow: none; // 상자 그림자 없음
  margin-bottom: 12px; // 입력 필드 아래 여백 추가
  height: 42px;

  display: flex;
  flex-direction: row;

  // 내부 div 스타일
  & > div:first-child {
    flex-grow: 1;
  }
`;

const PaymentLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center; // 세로 정렬
`;

const PayButton = styled.button`
  max-width: 100%;
  width: 95%;
  height: 48px;
  color: white;
  background-color: ${palette.AnyBlue};
  border: 1px solid ${palette.AnyBlue}; // 테두리 색상 설정
  border-radius: 8px; // 테두리 둥글게 설정

  margin-left: 12px;
  margin-right: 12px;
  font-size: 20px; // 글꼴 크기 설정
  font-weight: bold;
  box-shadow: none; // 상자 그림자 없음
  margin-bottom: 12px; // 입력 필드 아래 여백 추가
  margin-top: 12px; // 입력 필드 아래 여백 추가

  cursor: pointer;
  transition: filter 0.3s ease;

  &:hover {
    filter: brightness(90%); // 밝기를 90%로 줄임
  }
`;

const AnyfitProductDetail = () => {
  const navigate = useNavigate();
  const { productId } = useParams(); // URL에서 productId를 추출합니다.
  const [productDetail, setProductDetail] = useState(null);
  const [tokenData, setTokenData] = useState(null);

  useEffect(() => {
    console.log('결제창 버전..', process.env.REACT_APP_ENV);
    const fetchProductDetail = async () => {
      const validationResult = await validateStorageToken();
      console.log('vaildationResult', validationResult);

      if (validationResult.result) {
        // if (true) {
        setTokenData(validationResult.data);
        const response = await fetch(
          `${API_BASE_URL}/api/productDetail/${productId}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${validationResult.token}`,
            },
          },
        );

        if (response.ok) {
          const data = await response.json();
          setProductDetail(data);
        } else {
          console.error(
            'Failed to fetch product details:',
            response.statusText,
          );
          alert('토큰이 유효하지 않습니다.');
          navigate('/anyfit/error');
        }
      } else {
      }
    };

    fetchProductDetail();
  }, [navigate]);

  // 1. 주문자 상태관리
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    zoneCode: '',
    fullAddress: '',
    detailAddress: '',
  });

  // 상태 업데이트를 위한 핸들러 함수
  const handleFormDataChange = (key, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: value,
    }));
  };

  // 2. 결제 수단 상태관리
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState('creditCard');

  // 결제 방법 선택 함수
  const selectPaymentMethod = (method) => {
    setSelectedPaymentMethod(method);
  };

  // 3. 신청하기 클릭시 함수
  const handleCompleteOrderFreeUser = async () => {
    const orderData = {
      userId: tokenData.id, // 예시 ID, 실제 애플리케이션에서는 사용자 인증 시스템을 통해 설정
      productId: productDetail.id, // 예시 제품 ID, 선택된 제품에 따라 동적으로 설정
      status: 'COMPLETE',
      deliverAddress: formData.fullAddress,
      detailAddress: formData.detailAddress,
      postCode: formData.zoneCode,
      phoneNumber: formData.phoneNumber,
      receiverName: formData.name,
    };
    // 필수 항목 확인
    if (
      !orderData.userId ||
      !orderData.productId ||
      !orderData.deliverAddress ||
      !orderData.detailAddress ||
      !orderData.postCode ||
      !orderData.phoneNumber ||
      !orderData.receiverName
    ) {
      alert('정보를 입력해주세요.');
      return;
    }

    try {
      const validationResult = await validateStorageToken();
      const response = await fetch(
        `${API_BASE_URL}/api/completeOrderFreeUser`, // API URL을 입력하세요
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${validationResult.token}`,
          },
          body: JSON.stringify(orderData),
        },
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log('Order completed successfully:', result);
      alert('구매가 완료됐습니다.');

      // 여기에서 추가적인 성공 처리 로직을 구현할 수 있습니다.
      // todo : 구매 내역 페이지로 이동
      navigate('/anyfit/orderList');
    } catch (error) {
      console.error('Error completing the order:', error);
      // 에러 처리 로직
    }
  };

  // 4. 결제하기 클릭시 함수
  const handleCreateAndCompleteOrder = async () => {
    const createOrderData = {
      userId: tokenData.id, // 예시 ID, 실제 애플리케이션에서는 사용자 인증 시스템을 통해 설정
      productId: productDetail.id, // 예시 제품 ID, 선택된 제품에 따라 동적으로 설정
      status: 'PENDING',
      deliverAddress: formData.fullAddress,
      detailAddress: formData.detailAddress,
      postCode: formData.zoneCode,
      phoneNumber: formData.phoneNumber,
      receiverName: formData.name,
    };
    // 필수 항목 확인
    if (
      !createOrderData.userId ||
      !createOrderData.productId ||
      // !createOrderData.deliverAddress ||
      // !createOrderData.detailAddress ||
      // !createOrderData.postCode ||
      // !createOrderData.phoneNumber ||
      !createOrderData.receiverName
    ) {
      alert('모든 필수 항목을 입력해주세요.');
      return;
    }

    try {
      const validationResult = await validateStorageToken();
      // createOrder API 호출
      const createOrderResponse = await fetch(
        `${API_BASE_URL}/api/createOrder`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${validationResult.token}`,
          },
          body: JSON.stringify(createOrderData),
        },
      );

      if (!createOrderResponse.ok) {
        throw new Error('Failed to create order');
      }

      const createOrderResult = await createOrderResponse.json();
      console.log('Order created successfully:', createOrderResult);

      // 생성된 주문의 ID를 사용하여 completeOrder API 호출
      const completeOrderData = {
        orderId: createOrderResult.createdOrder.id,
        impUid: 'imp_1234567890', // 결제 확인을 위한 예제 impUid
      };

      const completeOrderResponse = await fetch(
        `${API_BASE_URL}/api/completeOrder`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${validationResult.token}`,
          },
          body: JSON.stringify(completeOrderData),
        },
      );

      if (!completeOrderResponse.ok) {
        throw new Error('Failed to complete order');
      }

      const completeOrderResult = await completeOrderResponse.json();
      console.log('Order completed successfully:', completeOrderResult);

      // 추가적인 성공 처리 로직
      alert('주문이 완료되었습니다.');
      navigate('/anyfit/orderList');
    } catch (error) {
      console.error('Error processing order:', error);
      alert('주문 처리 중 오류가 발생했습니다.');
    }
  };

  // 5. 실결제 관련 함수
  const handleOrderProcessPayUser = async () => {
    const createOrderData = {
      userId: tokenData.id, // 예시 ID, 실제 애플리케이션에서는 사용자 인증 시스템을 통해 설정
      productId: productDetail.id, // 예시 제품 ID, 선택된 제품에 따라 동적으로 설정
      status: 'PENDING',
      deliverAddress: formData.fullAddress,
      detailAddress: formData.detailAddress,
      postCode: formData.zoneCode,
      phoneNumber: formData.phoneNumber,
      receiverName: formData.name,
    };
    // 필수 항목 확인
    if (
      !createOrderData.userId ||
      !createOrderData.productId ||
      !createOrderData.deliverAddress ||
      !createOrderData.detailAddress ||
      !createOrderData.postCode ||
      !createOrderData.phoneNumber ||
      !createOrderData.receiverName
    ) {
      alert('모든 필수 항목을 입력해주세요.');
      return;
    }

    const merchantUid = await createOrder(createOrderData);
    console.log('merchantUid...', merchantUid);
    // alert('merchantUid' + merchantUid + '로 request pay');

    const modifiedMerchantUid =
      process.env.REACT_APP_ENV + 'ss' + merchantUid.toString();
    // alert('modified muid ' + modifiedMerchantUid.toString());

    const impUid = await requestPay(modifiedMerchantUid);
    await completeOrder(impUid, modifiedMerchantUid.split('ss')[1]);
  };

  const createOrder = async (createOrderData) => {
    try {
      const validationResult = await validateStorageToken();
      // createOrder API 호출
      const createOrderResponse = await fetch(
        `${API_BASE_URL}/api/createOrder`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${validationResult.token}`,
          },
          body: JSON.stringify(createOrderData),
        },
      );

      if (!createOrderResponse.ok) {
        throw new Error('Failed to create order');
      }

      const createOrderResult = await createOrderResponse.json();
      console.log('Order created successfully:', createOrderResult);
      return createOrderResult['createdOrder']['id'];
    } catch (error) {
      console.error('Error processing order:', error);
      alert('주문 처리 중 오류가 발생했습니다.');
      throw new Error(error);
    }
  };

  const requestPay = async (merchantUid) => {
    // alert('input muid ' + merchantUid.toString());
    const PG_MID_REAL = 'uplus.im_lansik3bzc';
    const PG_MID_REAL_INTEREST_FREE = 'tosspayments.im_glucofs4sn';
    // const test = true;
    const mRedirectUrl =
      `${process.env.REACT_APP_FRONT_URL}` + '/anyfit/orderList';
    return new Promise((resolve, reject) => {
      window.IMP.request_pay(
        {
          pg:
            selectedPaymentMethod == 'kakaopay'
              ? PG_MID_REAL_INTEREST_FREE
              : PG_MID_REAL,
          pay_method: selectedPaymentMethod,
          m_redirect_url: mRedirectUrl,
          merchant_uid: merchantUid,
          name: productDetail?.name,
          amount: productDetail?.price,
          buyer_name: formData.name,
          buyer_tel: formData.phoneNumber,
          buyer_addr: formData.fullAddress + ', ' + formData.detailAddress,
          buyer_postcode: formData.postCode,
        },
        (response) => {
          // alert(JSON.stringify(response));
          if (Object.keys(response).includes('error_code')) {
            alert(response.error_msg);
            // reject(response.error_msg);
          } else {
            resolve(response.imp_uid);
          }
        },
      );
    });
  };

  const completeOrder = async (impUid, merchantUid) => {
    try {
      // const validationResult = await validateStorageToken();
      const completeOrderResponse = await fetch(
        `${API_BASE_URL}/api/completeOrderPayUser`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // Authorization: `Bearer ${validationResult.token}`,
          },
          body: JSON.stringify({
            orderId: merchantUid,
            impUid: impUid,
          }),
        },
      );

      alert('주문이 완료되었습니다.');
      navigate('/anyfit/orderList');
      // window.location.href(
      //   `${process.env.REACT_APP_FRONT_URL}` + '/anyfit/orderList',
      // );
      console.log('completeOrderResponse...', completeOrderResponse);
    } catch (error) {
      alert('주문 완료에 실패했습니다.');
      throw new Error(error);
    }
  };

  return (
    <div>
      <Layout>
        <Title>주문/결제</Title>
        <SmallTitle>주문상품 정보</SmallTitle>
        <ProductItemList>
          <ProductImageContainer>
            <ImageContainer>
              <Image
                src={
                  'https://glucofit-image.s3.ap-northeast-2.amazonaws.com/LOG/img.png'
                }
                alt="이미지 설명"
              />
            </ImageContainer>
            <ProductImageContrainerName>
              {productDetail?.name}
            </ProductImageContrainerName>
            <ProductImageContrainerBenefit>
              {' '}
              혜택
              {/* <li>블라</li>
              <li>블라</li>
              <li>블라</li> */}
            </ProductImageContrainerBenefit>
          </ProductImageContainer>

          <ImageContainer2>
            <Image
              src={
                'https://glucofit-image.s3.ap-northeast-2.amazonaws.com/LOG/add.png'
              }
              alt="이미지 설명"
            />
          </ImageContainer2>
          <ProductImageContainer>
            <ImageContainer>
              <Image
                src={
                  'https://glucofit-image.s3.ap-northeast-2.amazonaws.com/LOG/Frame+4582.png'
                }
                alt="이미지 설명"
              />
            </ImageContainer>
            <ProductImageContrainerName>
              연속혈당측정기
            </ProductImageContrainerName>
            <ProductImageContrainerBenefit>
              {' '}
              혜택
              {/* <li>블라</li>
              <li>블라</li>
              <li>블라</li> */}
            </ProductImageContrainerBenefit>
          </ProductImageContainer>
        </ProductItemList>
        <SmallTitle>구매자 정보</SmallTitle>
        <BuyerInfoInputContainer>
          <InputTitle>이름 </InputTitle>
          <StyledInput
            type="text"
            value={formData.name}
            onChange={(e) => handleFormDataChange('name', e.target.value)}
            placeholder="실사용자의 이름을 입력해주세요"
          ></StyledInput>
          <InputTitle>전화번호</InputTitle>
          <StyledInput
            type="tel"
            value={formData.phoneNumber}
            onChange={(e) =>
              handleFormDataChange('phoneNumber', e.target.value)
            }
            placeholder="전화번호를 입력해주세요"
          ></StyledInput>
          <InputTitle>제품 배송지</InputTitle>
          <AddressContainerLayout>
            <AddressContainer1>
              <DisabledInput>
                {formData.zoneCode ? formData.zoneCode : '우편번호'}
              </DisabledInput>
              <DaumPostcode
                handleFormDataChange={handleFormDataChange}
                PostCodeButton={PostCodeButton}
              ></DaumPostcode>
            </AddressContainer1>
            <AddressContainer2>
              <DisabledInput>
                {formData.fullAddress ? formData.fullAddress : '주소지'}
              </DisabledInput>
            </AddressContainer2>
            <AddressContainer3>
              <StyledInput
                placeholder={'상세주소 입력'}
                value={formData.detailAddress}
                onChange={(e) =>
                  handleFormDataChange('detailAddress', e.target.value)
                }
              ></StyledInput>
            </AddressContainer3>
          </AddressContainerLayout>
        </BuyerInfoInputContainer>
        {
          // 주문 상품이 무료 상품인 경우 분기 처리
          productDetail?.id === 2 ? (
            <PaymentLayout>
              <br />
              <PayButton onClick={() => handleCompleteOrderFreeUser()}>
                신청하기
              </PayButton>
            </PaymentLayout>
          ) : (
            <>
              <SmallTitle>결제 수단</SmallTitle>
              <PayMethodContainer>
                <PayMethodItem
                  onClick={() => selectPaymentMethod('creditCard')}
                >
                  <div>신용/체크카드</div>
                  <ImageContainer3>
                    <Image3
                      src={
                        selectedPaymentMethod === 'creditCard'
                          ? ICON_CHECKED
                          : ICON_UNCHECKED
                      }
                      alt="이미지 설명"
                    />
                  </ImageContainer3>
                </PayMethodItem>
                {/* <PayMethodItem onClick={() => selectPaymentMethod('kakaopay')}>
                  <div>카카오페이</div>
                  <ImageContainer3>
                    <Image3
                      src={
                        selectedPaymentMethod === 'kakaopay'
                          ? ICON_CHECKED
                          : ICON_UNCHECKED
                      }
                      alt="이미지 설명"
                    />
                  </ImageContainer3>
                </PayMethodItem> */}
              </PayMethodContainer>
              <SmallTitle>결제 금액</SmallTitle>
              <PaymentLayout>
                <ProductImageContrainerName>
                  {productDetail?.price} 원
                </ProductImageContrainerName>
                {/* {process.env.ENV == 'prod' ? ( */}
                {['prod', 'dev'].includes(process.env.REACT_APP_ENV) ? (
                  <PayButton onClick={() => handleOrderProcessPayUser()}>
                    결제하기
                  </PayButton>
                ) : (
                  <PayButton onClick={() => handleCreateAndCompleteOrder()}>
                    결제하기
                  </PayButton>
                )}
              </PaymentLayout>
            </>
          )
        }

        {/* 흰색 글자 처리  */}
        {/* <div style={{ color: 'white' }}>
          {JSON.stringify(productDetail)}
          {JSON.stringify(formData)}
        </div> */}
      </Layout>
    </div>
  );
};

export default AnyfitProductDetail;
