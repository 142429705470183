import React from 'react';
import fullStar from '../../assets/images/img_full_star.png';
import emptyStar from '../../assets/images/img_empty_star.png';

const RenderStars = ({ score }) => {
  const fullStars = Math.floor(score / 2);

  const starComponents = [];

  for (let i = 0; i < 5; i++) {
    if (i < fullStars) {
      starComponents.push(
        <img
          key={i}
          alt="star"
          src={fullStar}
          style={{
            width: '38px',
            height: '38px',
            margin: '0',
            padding: '5px',
            margin: '2.5px',
          }}
        />,
      );
    } else {
      // Render empty star
      starComponents.push(
        <img
          key={i}
          alt="star"
          src={fullStar}
          style={{
            width: '38px',
            height: '38px',
            margin: '0',
            padding: '5px',
            margin: '2.5px',
          }}
        />,
      );
    }
  }

  return starComponents;
};

export default RenderStars;
