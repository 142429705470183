import './Main.scss';
import React, { useState, useEffect, useRef } from 'react';
import Nav from 'components/Nav/Nav';
import Footer from 'components/Footer/Footer';
import Header from './Components/Header';
import Carousel from './Components/Carousel';
import Qna from './Components/Qna';
import Steps from './Components/Steps';
import Review from './Components/Review';
import Journey from './Components/Journey';
import Profile from './Components/Profile';
import Effects from './Components/Effects';
import TagManager from 'react-gtm-module';
import Recommand from './Components/Recommand';
import Aimode from './Components/Aimode';
import Aimode2 from './Components/Aimode2';
import FaQ from './Components/FaQ';
import Logo from './Components/Logo';
import KakaoChannel from './Components/KakaoChannel';
import RealReview from './Components/RealReview';
import Youtube from './Components/Youtube';
import { useNavigate } from 'react-router-dom';
import PositiveEffect from 'pages/Main/Components/PositiveEffect';
import SpecialEvent from 'pages/Main/Components/SpecialEvent';
import Introduction from 'pages/Main/Components/Introduction';
import About from 'pages/Main/Components/About';
import Intro from 'pages/Main/Components/Intro';
import BlogReview from './Components/BlogReview';
import HyundaiCardBanner from 'pages/Main/Components/HyundaiCardBanner';
import BannerMobileImg from 'assets/images/ver3.0/img-hyundai-card-banner-mobile-2.png';

const Main = () => {
  const tagManagerArgs = {
    gtmId: 'GTM-57FDQMS',
  };
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
    TagManager.initialize(tagManagerArgs);
  }, []);

  // 제휴 대응
  const [referralUrl, setReferralUrl] = useState(null);
  const [referralInvitePath, setReferralInvitePath] = useState(null);
  useEffect(() => {
    const afterProtocol = window.location.href.split('://')[1];
    const parts = afterProtocol.split('/');
    const subdomain = parts[1];

    setReferralUrl(subdomain);
    console.log('subdomain...', subdomain);

    if (subdomain === 'drdinho') {
      setReferralInvitePath('/info?coupon_number=drdinho');
    } else if (subdomain === 'drlee') {
      setReferralInvitePath('/info?coupon_number=drlee');
    } else if (subdomain === 'hwalmyeong') {
      setReferralInvitePath('/info?coupon_number=hwalmyeong');
    } else if (subdomain === 'hafeteria') {
      setReferralInvitePath('/info?coupon_number=hafeteria');
    } else if (subdomain === 'salb') {
      setReferralInvitePath('/info?coupon_number=salb');
    } else if (subdomain === 'lare') {
      setReferralInvitePath('/info?coupon_number=lare');
    } else if (subdomain === 'reviewmachine') {
      setReferralInvitePath('/info?coupon_number=reviewmachine');
    } else if (subdomain === 'doctorleetv') {
      setReferralInvitePath('/info?coupon_number=doctorleetv');
    } else if (subdomain === 'mktv') {
      setReferralInvitePath('/info?coupon_number=mktv');
    } else if (subdomain === 'coupon0801') {
      setReferralInvitePath('/info?coupon_number=coupon0801');
    }

    // <Route path="/reviewmachine" element={<Main />} />
    // <Route path="/dotorleetv" element={<Main />} />
  }, []);

  // 지금 시작하기 활성화, 비활성화
  const [isIntersecting, setIsIntersecting] = useState(false);

  //접근
  const initCheckout = () => {
    if (
      [
        'drdinho',
        'drlee',
        'hwalmyeong',
        'hafeteria',
        'salb',
        'lare',
        'reviewmachine',
        'doctorleetv',
        'mktv',
        'coupon0801',
      ].includes(referralUrl)
    ) {
      navigate(referralInvitePath);
    } else if (
      localStorage.getItem('inviteCode') != 'null' &&
      localStorage.getItem('inviteCode') != null
    ) {
      navigate(`/info?code=${localStorage.getItem('inviteCode')}`);
    } else {
      navigate('/info');
    }
    localStorage.clear();
    window.gtag('event', 'conversion', {
      send_to: 'AW-10992256925/KpgjCL7zqKwYEJ2Pwvko',
    });
    window.fbq('track', 'InitiateCheckout');
  };

  return (
    <div className="wrapper">
      <div style={{ position: 'relative' }}>
        <Nav initCheckout={initCheckout} isIntersecting={isIntersecting} />
        <Header
          initCheckout={initCheckout}
          setIsIntersecting={setIsIntersecting}
        />
        {/* <Recommand />
        <Carousel />
        <Qna />
        <Logo />
        <Effects /> */}
        <Intro />
        <Review />
        <About />
        <Introduction />
        <SpecialEvent initCheckout={initCheckout} />
        <Profile />
        <Youtube />
        <Steps />
        <Journey />
        <BlogReview />
        <Aimode />
        {/* <Aimode2 /> */}
        <PositiveEffect />
        <FaQ />
        <Footer />
        <KakaoChannel />
        {!isIntersecting && (
          <button className="strong" id="start-button" onClick={initCheckout}>
            지금 시작하기
          </button>
        )}
      </div>
    </div>
  );
};

export default Main;
