// import DaumPostCode from 'react-daum-postcode';
import React from 'react';
import { useDaumPostcodePopup } from 'react-daum-postcode';

const DaumPostcode = ({ handleFormDataChange, PostCodeButton }) => {
  const open = useDaumPostcodePopup(
    'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js',
  );

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    // console.log(fullAddress); // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
    // setFullAddress(fullAddress);
    // setZoneCode(data.zonecode);
    handleFormDataChange('fullAddress', fullAddress);
    handleFormDataChange('zoneCode', data.zonecode);
  };

  const handleClick = () => {
    open({ onComplete: handleComplete });
  };

  return <PostCodeButton onClick={handleClick}> 주소찾기 </PostCodeButton>;
};
export default DaumPostcode;
