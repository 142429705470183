import React from 'react';
import styled from 'styled-components';
import Footer from 'components/Footer/Footer';
import Nav from 'components/Nav/Nav';
import Header from 'components/Anyfit/common/Header';

// 스타일드 컴포넌트를 사용하여 컨테이너를 생성합니다.
const Container = styled.div`
  max-width: 600px; /* 최대 너비를 설정합니다 */
  width: 100%;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
`;

const Background = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* 가운데 정렬 */
  margin: 0 auto;
`;

// 너비를 제한한 스타일드 컴포넌트를 사용하는 함수형 컴포넌트를 생성합니다.
const Layout = ({ children, headerText, back }) => {
  return (
    <div>
      <Header text={headerText} back={back} />
      <Background>
        <Container>{children}</Container>
      </Background>
      <Footer />
    </div>
  );
};

export default Layout;
