import React from 'react';
import YouTube from 'react-youtube';
import '../CSS/Youtube.scss';

function Youtube(props) {
  const videoIds = ['95eRysrQ7iA', 'XT3krrzj_c8', 'C0O7Xnj9R0g'];

  return (
    <div className="yt-container">
      <div className="yt-title-container">
        <div className="yt-sub-title"> Backed by Science</div>
        <div className="yt-title">
          <span className="strong">현직의사와 유명 방송도 주목한</span>
          <br /> 혈당 관리의 모든것, 글루코핏
        </div>
      </div>
      <div className="yt-content-container">
        {videoIds.map((videoId, index) => (
          <div key={index} className="yt-content" id={`yt-content-${index}`}>
            <YouTube videoId={videoId} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Youtube;
