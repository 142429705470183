// SmsCheckList.js

import React from 'react';
import { palette } from './palette';

const SmsCheckList = ({
  onFirstCheckChange,
  onSecondCheckChange,
  onThirdCheckChange,
  isFirstChecked,
  isSecondChecked,
  isThirdChecked,
}) => {
  const checkboxContainerStyle = {
    display: 'block',
    marginBottom: '10px',
    cursor: 'pointer',
    position: 'relative',
    padding: '5px',
    userSelect: 'none',
  };

  const checkboxStyle = {
    height: '16px',
    width: '16px',
    backgroundColor: palette.Gray500,
    borderRadius: '50%',
    display: 'inline-block',
    position: 'relative',
    marginRight: '10px',
    verticalAlign: 'middle',
  };

  const checkboxCheckedStyle = {
    ...checkboxStyle,
    backgroundColor: palette.FitMint500, // Checked background color
  };

  const checkmarkStyle = {
    display: 'inline-block',
    position: 'absolute',
    top: '9px',
    left: '5px',
    height: '4px',
    width: '6px',
    borderLeft: '1px solid black',
    borderBottom: '1px solid black',
    transform: 'rotate(-45deg)',
    // opacity: isFirstChecked ? 1 : 0, // Only show when checked
    // corlor: '#FFF',
  };

  const labelStyle = {
    display: 'block',
    cursor: 'pointer',
    position: 'relative',
    color: palette.White,
    fontFamily: "'Noto Sans KR', sans-serif",
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '150%',
  };
  return (
    <div style={{ marginTop: '16px' }}>
      <label style={labelStyle}>
        <input
          type="checkbox"
          checked={isFirstChecked}
          onChange={(e) => onFirstCheckChange(e.target.checked)}
          style={{
            opacity: 0,
            position: 'absolute',
            cursor: 'pointer',
            height: 0,
            width: 0,
          }}
        />
        <span style={isFirstChecked ? checkboxCheckedStyle : checkboxStyle} />
        <span style={checkmarkStyle} />
        개인정보수집에 동의합니다.
      </label>
      <label style={labelStyle}>
        <input
          type="checkbox"
          checked={isSecondChecked}
          onChange={(e) => onSecondCheckChange(e.target.checked)}
          style={{
            opacity: 0,
            position: 'absolute',
            cursor: 'pointer',
            height: 0,
            width: 0,
          }}
        />
        <span style={isSecondChecked ? checkboxCheckedStyle : checkboxStyle} />
        <span style={checkmarkStyle} />
        이벤트 참여 및 마케팅 약관에 동의합니다.
      </label>
      <label style={labelStyle}>
        <input
          type="checkbox"
          checked={isThirdChecked}
          onChange={(e) => onThirdCheckChange(e.target.checked)}
          style={{
            opacity: 0,
            position: 'absolute',
            cursor: 'pointer',
            height: 0,
            width: 0,
          }}
        />
        <span style={isThirdChecked ? checkboxCheckedStyle : checkboxStyle} />
        <span style={checkmarkStyle} />만 14세 이상입니다.
      </label>
    </div>
  );
};

export default SmsCheckList;
