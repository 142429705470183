import { useRef } from 'react';
import '../CSS/Carousel.scss';
import CaroucelLeftNavigation from '../../../assets/icons/ic-carousel-left-navigation.svg';
import CaroucelRightNavigation from '../../../assets/icons/ic-carousel-right-navigation.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { useMembershipCarousel } from 'hooks/membsershipinfo/useMembershipCarousel';
import { useMobile } from 'hooks/common/useMobile';

export default function Carousel({ setActive }) {
  const { carousels, webMobileBoundary } = useMembershipCarousel();
  const { isMobile } = useMobile(webMobileBoundary);

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperProps = isMobile
    ? {
        slidesPerView: 1,
        speed: 1,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        loop: true,
        style: {
          width: '20rem',
          height: '100%',
        },
        pagination: {
          clickable: true,
        },
        onBeforeInit: (swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
          swiper.navigation.update();
        },
        modules: [Autoplay, Navigation, Pagination],
        onSlideChange: (swiper) => setActive(swiper.realIndex),
      }
    : {
        slidesPerView: 1,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        loop: true,
        style: {
          width: '30.625rem',
          height: '100%',
        },
        pagination: {
          clickable: true,
        },
        onBeforeInit: (swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
          swiper.navigation.update();
        },
        modules: [Autoplay, Navigation, Pagination],
      };

  return (
    <div className="carousel-container">
      <img
        src={CaroucelLeftNavigation}
        className="left-navigation swiper-navigation"
        alt="left-navigation"
        ref={prevRef}
      />
      <img
        src={CaroucelRightNavigation}
        className="right-navigation swiper-navigation"
        alt="right-navigation"
        ref={nextRef}
      />
      <Swiper {...swiperProps}>
        {carousels.map((carousel) => (
          <SwiperSlide key={carousel.id}>
            <picture>
              <source media={carousel.boundary} srcSet={carousel.mobile} />
              <img src={carousel.web} alt="carousel-img" />
            </picture>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
