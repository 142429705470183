import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { REAL_SERVER } from 'config';

function RedirectMall(props) {
  let complete_input = {};
  const navigate = useNavigate();

  const completeOrder = async () => {
    try {
      const response = await axios.post(REAL_SERVER, {
        query: `
              mutation completeOrder($input: complete_order_input!) {
                completeOrder(complete_order_input: $input)
              }
            `,
        variables: {
          input: complete_input,
        },
      });
      if (response.data.data.completeOrder === 'success') {
        alert('결제가 완료되었습니다');
        navigate('/successmall');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const parseUrl = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const paramValue = urlParams.get('imp_uid');
    const paramValue2 = urlParams.get('merchant_uid');
    const errorMsg = urlParams.get('error_msg');
    const selectedPrice = localStorage.getItem('selectedPrice');
    const savedURL = localStorage.getItem('savedURL');
    if (errorMsg != null) {
      //결제 실패 케이스
      alert(errorMsg);
      navigate(-1);
    } else {
      //결제 성공 케이스
      complete_input = {
        imp_uid: paramValue,
        merchant_uid: paramValue2,
      };
      completeOrder();
    }
  };

  useEffect(() => {
    parseUrl();
  }, []);
  return <div />;
}

export default RedirectMall;
