import Footer from 'components/Footer/Footer';
import React, { useState, useEffect } from 'react';
import Nav from 'components/Nav/Nav';
import fullStar from 'assets/images/img_full_star.png';
import {
  TopContainer,
  ReviewContainer,
  BadgeContainer,
  ProductContainer,
  StarContainer,
  TitleContainer,
  ProfileContainer,
  DescriptionContainer,
  ImageContainer,
  ItemContainer,
  BestBadge,
  SecondBadge,
  UserName,
  DateTime,
  ActiveLink,
  PaginationItem,
  PaginationItemHover,
} from './ReviewPage.module';
import { GET_REVIEWS_BY_PAGINATION } from 'gql/queries';
import { client } from 'gql/client';
import close from '../../assets/images/ic_close_border_24.png';
import Pagination from 'react-js-pagination';
import './RealReview.css';
import RenderStars from './RenderStars';

function ReviewPage(props) {
  const [reviews, setReviews] = useState([]);
  const [paginationInput, setPaginationInput] = useState({
    page: 1,
    size: 5,
  });
  const [totalPages, setTotalPages] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };
  const closeModal = () => {
    setSelectedImage(null);
    setModalOpen(false);
  };

  const fetchReviews = async () => {
    try {
      const response = await client.query({
        query: GET_REVIEWS_BY_PAGINATION,
        variables: {
          pagination_param_input: paginationInput,
        },
        fetchPolicy: 'no-cache',
      });
      setReviews(response.data.getReviewsByPagination);
      const totalReviews = response.data.totalReviews;
      const calculatedTotalPages = Math.ceil(
        totalReviews / paginationInput.size,
      );
      setTotalPages(calculatedTotalPages);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchReviews();
  }, [paginationInput]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onPageChange = (page) => {
    setPaginationInput({
      ...paginationInput,
      page: page,
    });
    window.scrollTo(0, 0);
  };

  const handleClick = (image) => {
    openModal(image);
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}.${month}.${day}`;
  }

  return (
    <>
      <Nav />
      <TopContainer>REVIEW</TopContainer>
      <ReviewContainer>
        {reviews.map((review, index) => {
          console.log(review.score);

          return (
            <ItemContainer key={index}>
              <BadgeContainer>
                <BestBadge>BEST</BestBadge>
                {/* <SecondBadge>2차 구매</SecondBadge> */}
              </BadgeContainer>
              <ProductContainer>{review.product_name}</ProductContainer>
              <StarContainer>{RenderStars(review.score)}</StarContainer>
              <TitleContainer>{review.message}</TitleContainer>
              <ProfileContainer>
                <UserName>{review.created_by}</UserName>
                <DateTime>{formatDate(review.date)}</DateTime>
              </ProfileContainer>
              {review.contents[0]?.original_content_url && (
                <ImageContainer>
                  <img
                    onClick={() =>
                      handleClick(review.contents[0]?.original_content_url)
                    }
                    src={review.contents[0]?.original_content_url}
                    alt={`Review ${index + 1}`}
                    className="image"
                  />
                </ImageContainer>
              )}
            </ItemContainer>
          );
        })}
      </ReviewContainer>
      <Pagination
        activePage={paginationInput.page}
        itemsCountPerPage={5}
        totalItemsCount={48}
        pageRangeDisplayed={8}
        onChange={onPageChange}
        activeLinkClass="active-link"
        itemClass="pagination-item"
        itemClassFirst="pagination-item"
        itemClassPrev="pagination-item"
        itemClassNext="pagination-item"
        itemClassLast="pagination-item"
        itemClassActive="active-link"
        itemClassOther="pagination-item"
        activeClass="pagination-item-hover"
      />
      <Footer />
      {modalOpen && (
        <div className="modal" onClick={closeModal}>
          <span className="close-button" onClick={closeModal}>
            <img src={close} alt="Review" />
          </span>
          <div className="modal-content">
            <img src={selectedImage} alt="Review" className="modal-image" />
          </div>
        </div>
      )}
    </>
  );
}

export default ReviewPage;
