import styled from 'styled-components';

export const ReviewContainer = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const ReviewTitle = styled.div`
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;

  @media screen and (max-width: 600px) {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
`;

export const ButtonContainer = styled.div``;

export const NavigationButton = styled.button`
  background-color: black;
  padding: 14px 52px;
  margin-bottom: 92px;
  @media screen and (max-width: 600px) {
    margin-bottom: 56px;
    margin-top: 0px;
  }
`;

export const ContentsContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: rgba(219, 219, 219, 0.3);
  border-radius: 20px;
  overflow-x: scroll;
  height: 510px;
  padding: 40px 70px;
  margin-top: 60px;
  margin-bottom: 64px;

  @media screen and (max-width: 600px) {
    background-color: white;
    width: 100%;
    max-width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 36px 70px;
  }
`;

export const ContentsItem = styled.div`
  background-color: white;
  width: 240px;
  margin-left: 7.5px;
  margin-right: 7.5px;
  border-radius: 16px;

  @media screen and (max-width: 600px) {
    border-radius: 16px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  }
`;

export const ImageContainer = styled.div`
  img {
    margin: 0;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
`;
export const TextContainer = styled.div``;
export const ProductContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 14px;
  margin: 0;
  justify-content: flex-start;
`;
export const TitleContainer = styled.div`
  margin-left: 8px;
`;
export const ProductName = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;
export const ReviewCount = styled.div`
  color: #707070;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 15px */
`;

export const MessageContainer = styled.div`
  padding: 4px 14px;
`;
export const Contents = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`;
export const UserInformation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 3px;
`;
export const UserName = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;
export const DateTime = styled.div`
  color: #707070;
  text-align: right;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
`;

export const StarContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* margin: 10px 5px; */
  justify-content: center;
  margin-bottom: 14px;
  margin: 0;
`;
