import React from 'react';
import styled from 'styled-components';

// 스타일드 컴포넌트로 모달의 기본 스타일을 설정합니다.
const PopupWrapper = styled.div`
  position: fixed;
  top: 60px;
  left: 5px;
  width: 100%;
  height: 68%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  // position: relative;
  width: 90%; // 모달의 너비
  height: 90%; // 모달의 높이
  background-color: rgba(255, 255, 255, 0.9); // 모달 내부의 콘텐츠 배경
  background: url(${(props) => props.backgroundImage}) no-repeat center center;
  background-size: contain;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0px;
  right: 0px;
  border: none;
  background-color: transparent;
  font-size: 24px;
  cursor: pointer;
  font-color: transparent;

  width: 600px;
  height: 100px;
`;

// LibrePopup 컴포넌트
const LibrePopup = ({ isOpen, onClose, backgroundImage }) => {
  if (!isOpen) return null;

  return (
    <PopupWrapper>
      <PopupContent backgroundImage="https://glucofit-image.s3.ap-northeast-2.amazonaws.com/LOG/popup.png">
        <CloseButton onClick={onClose}></CloseButton>
        {/* 모달 콘텐츠를 여기에 넣습니다 */}
      </PopupContent>
    </PopupWrapper>
  );
};

export default LibrePopup;
