import React from 'react';
import '../CSS/Journey.scss';
import process from '../../../assets/images/img_process.png';

function Journey() {
  const renderData = [
    {
      period: '4주',
      title: <span>혈당 반응 실험 기간</span>,
      description: (
        <span>
          평소 먹던 음식들을 다양하게 먹어보며 <br />
          내 몸이 어떻게 반응하는지 알아가요.
          <br />
          의사 코치님의 강의와 혈당 아티클로 기초 지식을 습득해요.
        </span>
      ),
      descriptionMobile: (
        <span>
          평소 먹던 음식들을 다양하게 <br />
          먹어보며 내 몸이 어떻게 반응하는지 알아가요.
        </span>
      ),
    },
    {
      period: '3달',
      title: <span>관리 방법 실험 기간</span>,
      description: (
        <span>
          4주간 공부하고 실험한 결과를 바탕으로 <br />
          나에게 가장 잘 맞는 음식 조합과
          <br />
          효과적인 혈당 조절 방법을 찾아요.
        </span>
      ),
      descriptionMobile: (
        <span>
          나에게 가장 잘 맞는 <br />
          음식 조합과 효과적인 혈당 조절 <br />
          방법을 찾아요.
        </span>
      ),
    },
    {
      period: '1년',
      title: <span>건강 습관 최적화 기간</span>,
      description: (
        <span>
          혈당은 음식 뿐 아니라 운동, 수면, 스트레스, 생리 주기 등
          <br />
          다양한 요소들에 영향 받아요.
          <br />
          평생 가져갈 나만을 위한 초개인화된 건강 습관을 찾아요.
        </span>
      ),
      descriptionMobile: (
        <span>
          혈당은 운동, 수면, 스트레스, <br />
          생리 주기 등 다양한 요소들에 영향 <br />
          받아요. 평생 가져갈 나만을 위한 <br />
          초개인화된 건강 습관을 찾아요.
        </span>
      ),
    },
  ];
  return (
    <div>
      <div className="container6">
        <div className="progress-main-title">
          미리 보는 <br />
          <span className="strong">글루코핏 프로그램 여정</span>
        </div>
        <div className="progress-container">
          <div className="process-left">
            <img src={process} alt="img" />
          </div>
          <div className="process-right">
            {renderData.map((item, index) => {
              return (
                <div className="progress-detail" key={item.period}>
                  <div className={`time-container thema${index + 1}`}>
                    {item.period}
                  </div>
                  <div className="process-info">
                    <div className="process-title strong">{item.title}</div>
                    <div className="process-text web">{item.description}</div>
                    <div className="process-text mobile">
                      {item.descriptionMobile}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Journey;
