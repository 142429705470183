import './CSS/Payment.scss';
import Footer from 'components/Footer/Footer';
import Layout from 'components/Anyfit/common/Layout';
import ProductImg from 'assets/images/ver3.0/img-welstory-product.png';
import card from 'assets/icons/ic_card.svg';
import kakao from 'assets/icons/ic_kakao.svg';
import naverpay from 'assets/images/img_naverpay.png';
import {
  validateStorageToken,
  validateToken,
  parseTokenFromUrl,
  getTokenFromStorage,
} from './common';

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { palette } from 'pages/AnyfitMall/theme';
import { API_BASE_URL } from './config';
import DaumPostcode from './anyfitDaumPost';

function AnyfitProductDetailV2() {
  const navigate = useNavigate();
  const { productId } = useParams(); // URL에서 productId를 추출합니다.
  const [productDetail, setProductDetail] = useState(null);
  const [tokenData, setTokenData] = useState(null);
  const [alreadyUse, setAlreadyUse] = useState(false);

  // 쿠폰
  const [isValidCoupon, setIsValidCoupon] = useState(null);
  const [couponStringInput, setCouponStringInput] = useState(null);

  useEffect(() => {
    console.log('결제창 버전..', process.env.REACT_APP_ENV);
    const initialize = async () => {
      let token = parseTokenFromUrl();
      console.log('parseTokenFromUrl...', token);

      if (token) {
        // todo : 클라이언트에서 분기태우면 안된다.
        // URL에서 토큰을 가져온 경우
        const validationResult = await validateToken(token);
        console.log('validationResult...', validationResult);

        if (validationResult.code == 1) {
          alert('이용 동의가 필요합니다.');
          navigate('/anyfit/agreement');
        } else if (validationResult.code == 2) {
          alert('올바른 코드가 아닙니다.');
        } else if (validationResult.result) {
          // 필요한 데이터를 가져온다
          // const products = await fetchProducts(token);
          // setProductListData(products);
          // store token
          localStorage.setItem('token', token);
          fetchProductDetail();
          return;
        }
      }
    };
    const fetchProductDetail = async () => {
      const validationResult = await validateStorageToken();
      console.log('vaildationResult', validationResult);

      if (validationResult.result) {
        // if (true) {
        setTokenData(validationResult.data);
        const response = await fetch(
          `${API_BASE_URL}/api/productDetailByUser`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${validationResult.token}`,
              'Content-Type': 'application/json', // Content-Type 헤더 추가
            },
            body: JSON.stringify({
              userId: validationResult.data.id,
            }),
          },
        );
        console.log('response...', response);

        if (response.ok) {
          const data = await response.json();
          setProductDetail(data);
        } else {
          const errorResponse = await response.json();
          console.error('Error:', errorResponse);
          console.log('Error:', errorResponse.message);
          // alert('토큰이 유효하지 않습니다.');
          if (errorResponse.message == 'NOW_ALREADY_IN_SUBSCRIPTION') {
            setAlreadyUse(true);
          }
          // navigate('/anyfit/error');
        }
      } else {
      }
    };

    initialize();
  }, [navigate]);

  // 1. 주문자 상태관리
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    zoneCode: '',
    fullAddress: '',
    detailAddress: '',
  });

  // 상태 업데이트를 위한 핸들러 함수
  const handleFormDataChange = (key, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: value,
    }));
  };

  // 2. 결제 수단 상태관리
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState('creditCard');

  // 3. 신청하기 클릭시 함수
  const handleCompleteOrderFreeUser = async () => {
    const orderData = {
      userId: tokenData.id, // 예시 ID, 실제 애플리케이션에서는 사용자 인증 시스템을 통해 설정
      productId: productDetail.id, // 예시 제품 ID, 선택된 제품에 따라 동적으로 설정
      status: 'COMPLETE',
      deliverAddress: formData.fullAddress,
      detailAddress: formData.detailAddress,
      postCode: formData.zoneCode,
      phoneNumber: formData.phoneNumber,
      receiverName: formData.name,
    };
    // 필수 항목 확인
    if (
      !orderData.userId ||
      !orderData.productId ||
      !orderData.deliverAddress ||
      !orderData.detailAddress ||
      !orderData.postCode ||
      !orderData.phoneNumber ||
      !orderData.receiverName
    ) {
      alert('정보를 입력해주세요.');
      return;
    }

    try {
      const validationResult = await validateStorageToken();
      const response = await fetch(
        `${API_BASE_URL}/api/completeOrderFreeUser`, // API URL을 입력하세요
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${validationResult.token}`,
          },
          body: JSON.stringify(orderData),
        },
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log('Order completed successfully:', result);
      alert('구매가 완료됐습니다.');

      // 여기에서 추가적인 성공 처리 로직을 구현할 수 있습니다.
      // todo : 구매 내역 페이지로 이동
      navigate('/anyfit/orderList');
    } catch (error) {
      console.error('Error completing the order:', error);
      // 에러 처리 로직
    }
  };

  // 4-1. 결제하기 클릭시 함수
  const handleCreateAndCompleteOrder = async () => {
    const createOrderData = {
      userId: tokenData.id, // 예시 ID, 실제 애플리케이션에서는 사용자 인증 시스템을 통해 설정
      productId: productDetail.id, // 예시 제품 ID, 선택된 제품에 따라 동적으로 설정
      status: 'PENDING',
      deliverAddress: formData.fullAddress,
      detailAddress: formData.detailAddress,
      postCode: formData.zoneCode,
      phoneNumber: formData.phoneNumber,
      receiverName: formData.name,
    };
    // 필수 항목 확인
    if (
      !createOrderData.userId ||
      !createOrderData.productId ||
      // !createOrderData.deliverAddress ||
      // !createOrderData.detailAddress ||
      // !createOrderData.postCode ||
      // !createOrderData.phoneNumber ||
      !createOrderData.receiverName
    ) {
      alert('모든 필수 항목을 입력해주세요.');
      return;
    }

    try {
      const validationResult = await validateStorageToken();
      // createOrder API 호출
      const createOrderResponse = await fetch(
        `${API_BASE_URL}/api/createOrder`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${validationResult.token}`,
          },
          body: JSON.stringify(createOrderData),
        },
      );

      if (!createOrderResponse.ok) {
        throw new Error('Failed to create order');
      }

      const createOrderResult = await createOrderResponse.json();
      console.log('Order created successfully:', createOrderResult);

      // 생성된 주문의 ID를 사용하여 completeOrder API 호출
      const completeOrderData = {
        orderId: createOrderResult.createdOrder.id,
        impUid: 'imp_1234567890', // 결제 확인을 위한 예제 impUid
      };

      const completeOrderResponse = await fetch(
        `${API_BASE_URL}/api/completeOrder`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${validationResult.token}`,
          },
          body: JSON.stringify(completeOrderData),
        },
      );

      if (!completeOrderResponse.ok) {
        throw new Error('Failed to complete order');
      }

      const completeOrderResult = await completeOrderResponse.json();
      console.log('Order completed successfully:', completeOrderResult);

      // 추가적인 성공 처리 로직
      alert('주문이 완료되었습니다.');
      navigate('/anyfit/orderList');
    } catch (error) {
      console.error('Error processing order:', error);
      alert('주문 처리 중 오류가 발생했습니다.');
    }
  };

  // 4-2. 실결제 관련 함수
  const handleOrderProcessPayUser = async () => {
    const createOrderData = {
      userId: tokenData.id, // 예시 ID, 실제 애플리케이션에서는 사용자 인증 시스템을 통해 설정
      productId: productDetail.id, // 예시 제품 ID, 선택된 제품에 따라 동적으로 설정
      status: 'PENDING',
      deliverAddress: formData.fullAddress,
      detailAddress: formData.detailAddress,
      postCode: formData.zoneCode,
      phoneNumber: formData.phoneNumber,
      receiverName: formData.name,
    };
    // 필수 항목 확인
    if (
      !createOrderData.userId ||
      !createOrderData.productId ||
      !createOrderData.deliverAddress ||
      !createOrderData.detailAddress ||
      !createOrderData.postCode ||
      !createOrderData.phoneNumber ||
      !createOrderData.receiverName
    ) {
      alert('모든 필수 항목을 입력해주세요.');
      return;
    }

    const merchantUid = await createOrder(createOrderData);
    console.log('merchantUid...', merchantUid);
    // alert('merchantUid' + merchantUid + '로 request pay');

    const modifiedMerchantUid =
      process.env.REACT_APP_ENV + 'ss' + merchantUid.toString();
    // alert('modified muid ' + modifiedMerchantUid.toString());

    const impUid = await requestPay(modifiedMerchantUid);
    await completeOrder(impUid, modifiedMerchantUid.split('ss')[1]);
  };

  const createOrder = async (createOrderData) => {
    try {
      const validationResult = await validateStorageToken();
      // createOrder API 호출
      const createOrderResponse = await fetch(
        `${API_BASE_URL}/api/createOrder`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${validationResult.token}`,
          },
          body: JSON.stringify(createOrderData),
        },
      );

      if (!createOrderResponse.ok) {
        throw new Error('Failed to create order');
      }

      const createOrderResult = await createOrderResponse.json();
      console.log('Order created successfully:', createOrderResult);
      return createOrderResult['createdOrder']['id'];
    } catch (error) {
      console.error('Error processing order:', error);
      alert('주문 처리 중 오류가 발생했습니다.');
      throw new Error(error);
    }
  };

  const requestPay = async (merchantUid) => {
    // alert('input muid ' + merchantUid.toString());
    const PG_MID_REAL = 'uplus.im_lansik3bzc';
    const PG_MID_REAL_INTEREST_FREE = 'tosspayments.im_glucofs4sn';
    // const test = true;
    const mRedirectUrl =
      `${process.env.REACT_APP_FRONT_URL}` + '/anyfit/orderList';

    return new Promise((resolve, reject) => {
      window.IMP.request_pay(
        {
          pg:
            selectedPaymentMethod == 'kakaopay'
              ? PG_MID_REAL_INTEREST_FREE
              : PG_MID_REAL,
          pay_method: selectedPaymentMethod,
          m_redirect_url: mRedirectUrl,
          merchant_uid: merchantUid,
          name: productDetail?.name,
          amount: productDetail?.price,
          buyer_name: formData.name,
          buyer_tel: formData.phoneNumber,
          buyer_addr: formData.fullAddress + ', ' + formData.detailAddress,
          buyer_postcode: formData.postCode,
        },
        (response) => {
          // alert(JSON.stringify(response));
          if (Object.keys(response).includes('error_code')) {
            alert(response.error_msg);
            // reject(response.error_msg);
          } else {
            resolve(response.imp_uid);
          }
        },
      );
    });
  };

  const completeOrder = async (impUid, merchantUid) => {
    try {
      // const validationResult = await validateStorageToken();
      const completeOrderResponse = await fetch(
        `${API_BASE_URL}/api/completeOrderPayUser`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // Authorization: `Bearer ${validationResult.token}`,
          },
          body: JSON.stringify({
            orderId: merchantUid,
            impUid: impUid,
          }),
        },
      );

      alert('주문이 완료되었습니다.');
      navigate('/anyfit/orderList');
      // window.location.href(
      //   `${process.env.REACT_APP_FRONT_URL}` + '/anyfit/orderList',
      // );
      console.log('completeOrderResponse...', completeOrderResponse);
    } catch (error) {
      alert('주문 완료에 실패했습니다.');
      throw new Error(error);
    }
  };

  // 5. 쿠폰 사용 함수
  const handleCompleteOrderWithCoupon = async () => {
    // 쿠폰 사용 유저의 주문 완료 처리 로직
    const orderData = {
      userId: tokenData.id, // 예시 ID, 실제 애플리케이션에서는 사용자 인증 시스템을 통해 설정
      productId: productDetail.id, // 예시 제품 ID, 선택된 제품에 따라 동적으로 설정
      status: 'COMPLETE',
      deliverAddress: formData.fullAddress,
      detailAddress: formData.detailAddress,
      postCode: formData.zoneCode,
      phoneNumber: formData.phoneNumber,
      receiverName: formData.name,
      couponString: couponStringInput,
    };
    // 필수 항목 확인
    // if (
    //   !orderData.userId ||
    //   !orderData.productId ||
    //   !orderData.deliverAddress ||
    //   !orderData.detailAddress ||
    //   !orderData.postCode ||
    //   !orderData.phoneNumber ||
    //   !orderData.receiverName
    // ) {
    //   alert('정보를 입력해주세요.');
    //   return;
    // }

    try {
      const validationResult = await validateStorageToken();
      const response = await fetch(
        `${API_BASE_URL}/api/completeOrderWithCoupon`, // API URL을 입력하세요
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${validationResult.token}`,
          },
          body: JSON.stringify(orderData),
        },
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log('Order completed successfully:', result);
      alert('구매가 완료됐습니다.');

      // 여기에서 추가적인 성공 처리 로직을 구현할 수 있습니다.
      // todo : 구매 내역 페이지로 이동
      navigate('/anyfit/orderList');
    } catch (error) {
      console.error('Error completing the order:', error);
      // 에러 처리 로직
    }
  };

  // 6. handlePayment
  const handlePayment = () => {
    if (productDetail?.price == 0) {
      handleCompleteOrderFreeUser();
    } else if (!isValidCoupon) {
      handleOrderProcessPayUser();
    } else {
      handleCompleteOrderWithCoupon();
    }
  };

  // validation
  const handleCouponInputChange = (e) => {
    const value = e.target.value;
    const lastChar = value.slice(-1);

    // Check if the last character is Korean, and if so, ignore the change
    if (/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(lastChar)) {
      alert('한글은 입력할 수 없습니다.');
      e.target.value = value.slice(0, -1); // Remove the invalid character
      return;
    }

    // Check for invalid characters
    if (/O|o|0/.test(lastChar)) {
      alert('O, o, 0 등의 문자열은 입력할 수 없습니다.');
      e.target.value = value.slice(0, -1); // Remove the invalid character
      return;
    }

    // Convert lowercase to uppercase
    const upperCaseValue = value.toUpperCase();

    // Update the input value
    e.target.value = upperCaseValue;
    setCouponStringInput(upperCaseValue);
  };
  const handleCouponValidation = async () => {
    try {
      const result = await validateCoupon(couponStringInput);
      setIsValidCoupon(result);
      if (!result) {
        alert('올바른 쿠폰이 아닙니다.');
      } else {
        alert('쿠폰 등록이 완료됐습니다.');
      }
    } catch (error) {
      alert('올바른 쿠폰이 아닙니다 (' + error.message + ')');
    } finally {
      // setLoading(false);
    }
  };

  // coupon validation
  const validateCoupon = async (couponString) => {
    const response = await fetch(`${API_BASE_URL}/coupons/validate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ couponString }),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message);
    }

    const data = await response.json();
    return data.isValid;
  };

  if (alreadyUse) {
    alert('이미 상품을 이용중입니다.');
    navigate('/anyfit/orderList');
  }

  return (
    <div>
      <div className="sensoronly-payment-body">
        <div className="payment-left">
          <div>
            <div className="sensorOnly-subtitle">주문 상품 정보</div>
            <div className="sensorOnly-product-container">
              <div>
                <input type="radio" checked={true} readOnly />
                <label
                // htmlFor={p.id}
                >
                  <div className="product-img-sugarfit">
                    <img
                      src="https://glucofit-image.s3.ap-northeast-2.amazonaws.com/LOG/sugarfit_product.jpg"
                      width="70px"
                      id="welstory-img"
                      alt="슈가핏"
                    />
                  </div>
                  <div className="product-info">
                    <div className="product-description">
                      {productDetail?.name}
                    </div>
                    {/* <div className="product-sensor-price strong">
                      {productDetail?.price?.toLocaleString()}원
                    </div> */}
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div>
            <div className="sensorOnly-subtitle">결제 금액</div>
            <div className="payment-total-container-top">
              <div>
                <div className="payment-total-textContainer">
                  <div>총 상품 금액</div>
                  <div>
                    {/* {(selectedProduct.price * productCount).toLocaleString()}원 */}
                    {productDetail?.price?.toLocaleString()} 원
                  </div>
                </div>

                {isValidCoupon ? (
                  <div className="payment-total-textContainer">
                    <div>쿠폰 할인액</div>
                    <div>
                      {/* {(selectedProduct.price * productCount).toLocaleString()}원 */}
                      - {productDetail?.price?.toLocaleString()} 원
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="payment-total-price">
                <div>최종 결제 금액</div>
                <div>
                  <div className="anyfitblue">
                    {isValidCoupon ? 0 : productDetail?.price?.toLocaleString()}{' '}
                    원
                  </div>
                </div>
              </div>
            </div>
          </div>
          {productDetail?.price == 0 ? null : (
            <>
              <div className="sensorOnly-subtitle">할인 쿠폰</div>
              <CouponInputContainer>
                <StyledInput
                  type="text"
                  // value={formData.name}
                  onChange={(e) => handleCouponInputChange(e)}
                  placeholder="쿠폰번호를 입력해주세요"
                  disabled={isValidCoupon} // 쿠폰이 유효한 경우 비활성화
                ></StyledInput>
                <CouponButton
                  onClick={() => {
                    isValidCoupon
                      ? alert('이미 등록이 완료됐습니다')
                      : handleCouponValidation(couponStringInput);
                  }}
                  disabled={isValidCoupon !== null && !isValidCoupon}
                >
                  {isValidCoupon ? '등록완료' : '쿠폰등록'}
                </CouponButton>
              </CouponInputContainer>
            </>
          )}
          {isValidCoupon ? null : (
            <div>
              <div
                className="sensorOnly-subtitle"
                // ref={ref}
              >
                구매자 정보
              </div>
              {/* <OrderForm
              // order={order}
              // setOrder={setOrder}
              // handleFindAddressClick={handleFindAddress}
              // handleOrder={handleOrder}
              theme="second"
            /> */}
              <BuyerInfoInputContainer>
                <InputTitle>이름 </InputTitle>
                <StyledInput
                  type="text"
                  value={formData.name}
                  onChange={(e) => handleFormDataChange('name', e.target.value)}
                  placeholder="실사용자의 이름을 입력해주세요"
                ></StyledInput>
                <InputTitle>전화번호</InputTitle>
                <StyledInput
                  type="tel"
                  value={formData.phoneNumber}
                  onChange={(e) =>
                    handleFormDataChange('phoneNumber', e.target.value)
                  }
                  placeholder="전화번호를 입력해주세요"
                ></StyledInput>
                <InputTitle>제품 배송지</InputTitle>
                <AddressContainerLayout>
                  <AddressContainer1>
                    <DisabledInput>
                      {formData.zoneCode ? formData.zoneCode : '우편번호'}
                    </DisabledInput>
                    <DaumPostcode
                      handleFormDataChange={handleFormDataChange}
                      PostCodeButton={PostCodeButton}
                    ></DaumPostcode>
                  </AddressContainer1>
                  <AddressContainer2>
                    <DisabledInput>
                      {formData.fullAddress ? formData.fullAddress : '주소지'}
                    </DisabledInput>
                  </AddressContainer2>
                  <AddressContainer3>
                    <StyledInput
                      placeholder={'상세주소 입력'}
                      value={formData.detailAddress}
                      onChange={(e) =>
                        handleFormDataChange('detailAddress', e.target.value)
                      }
                    ></StyledInput>
                  </AddressContainer3>
                </AddressContainerLayout>
              </BuyerInfoInputContainer>

              {isValidCoupon || productDetail?.price == 0 ? null : (
                <div>
                  <div className="sensorOnly-subtitle">결제 수단</div>
                  <div
                    className="payment-method-selector"
                    // id={!interestFreeInfo ? 'interest-no-free' : undefined}
                  >
                    <div
                      // className={`payment-method ${
                      //   method === 'card' ? 'selected' : ''
                      // }`}
                      className={`payment-method selected`}
                      id="ic-card"
                      // onClick={(e) => setMethod(e, 'card')}
                    >
                      <img className="payment-ic-card" src={card} alt="img" />
                      <div>신용/체크카드</div>
                    </div>
                    {/* <div
                className={`payment-method`}
                id="kakaopay"
                // onClick={(e) => setMethod(e, 'kakaopay')}
              >
                <img className="payment-ic-kakao" src={kakao} alt="img" />
                <div>카카오페이</div>
              </div>
              <div
                className={`payment-method`}
                id="naverpay"
                // onClick={(e) => setMethod(e, 'naverpay')}
              >
                <img className="payment-ic-naver" src={naverpay} alt="img" />
                <div>네이버페이</div>
              </div> */}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="payment-right">
          <div className="payment-total-container-bottom">
            <div className="payment-total-subtitle">결제 금액</div>
            <div>
              <div className="payment-total-textContainer">
                <div>총 상품 금액</div>
                {productDetail?.price?.toLocaleString()}원
              </div>
            </div>
            <div className="payment-total-price">
              <div>최종 결제 금액</div>
              <div>
                <div className="green3">
                  {productDetail?.price?.toLocaleString()}원
                </div>
              </div>
            </div>
          </div>
          <button
            className="payment-totalAccept-button"
            onClick={handlePayment}
          >
            {productDetail?.price == 0
              ? '신청하기'
              : isValidCoupon
              ? '쿠폰으로 슈가핏 시작하기'
              : productDetail?.price?.toLocaleString() + ' 원 결제하기'}
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
}

const BuyerInfoInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  // margin-left: 10px;
  // margin-right: 10px;
`;

// 스타일이 적용된 입력 컴포넌트
const InputTitle = styled.div`
  display: flex;
  width: 100%;
  text-align: left;
  color: ${palette.Gray500};
`;

const StyledInput = styled.input`
  width: 100%; // 입력 필드의 너비를 최대로 설정
  padding: 8px 16px; // 안쪽 여백 설정
  border: 1px solid #ccc; // 테두리 색상 설정
  border-radius: 8px; // 테두리 둥글게 설정
  font-size: 16px; // 글꼴 크기 설정
  box-shadow: none; // 상자 그림자 없음
  margin-bottom: 12px; // 입력 필드 아래 여백 추가

  &:focus {
    border-color: #0056b3; // 포커스 상태에서 테두리 색상 변경
    outline: none; // 기본 아웃라인 제거
  }

  &::placeholder {
    color: ${palette.Gray400};


  &:disabled {
    background-color: #f0f0f0; // 비활성화 상태 배경 색상
    border-color: #ddd; // 비활성화 상태 테두리 색상
    cursor: not-allowed; // 커서 변경
  }
`;

// Layout Container
const AddressContainerLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center; // 세로 정렬
`;

// Address Container 1
const AddressContainer1 = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%; // 버튼 사이즈 20%
`;

// Styled div for displaying data
const DisabledInput = styled.div`
  border: 1px solid grey;
  background-color: #f0f0f0;
  flex-grow: 1;

  padding: 8px 16px; // 안쪽 여백 설정
  border: 1px solid #ccc; // 테두리 색상 설정
  border-radius: 8px; // 테두리 둥글게 설정
  font-size: 16px; // 글꼴 크기 설정
  box-shadow: none; // 상자 그림자 없음
  margin-bottom: 12px; // 입력 필드 아래 여백 추가
  margin-top: 12px; // 입력 필드 아래 여백 추가

  height: 100%; // 입력 창의 높이 고정
  min-height: 40px;
  color: ${palette.Gray500};
`;

// Address Container 2 and 3
const AddressContainer2 = styled.div`
  flex: 1;
  width: 100%; // 버튼 사이즈 20%
`;
const AddressContainer3 = styled.div`
  flex: 1;
  width: 100%; // 버튼 사이즈 20%
`;

const PostCodeButton = styled.button`
  max-width: 100px;
  min-width: 100px;
  width: 20%;
  background-color: white;
  color: ${palette.AnyBlue};
  background-color: ${palette.AnyBlue100};

  margin-left: 12px;
  border: 1px solid ${palette.AnyBlue100}; // 테두리 색상 설정
  border-radius: 8px; // 테두리 둥글게 설정
  font-size: 16px; // 글꼴 크기 설정
  box-shadow: none; // 상자 그림자 없음
  margin-bottom: 12px; // 입력 필드 아래 여백 추가
  margin-top: 12px; // 입력 필드 아래 여백 추가

  cursor: pointer;
  transition: filter 0.3s ease;

  &:hover {
    filter: brightness(90%); // 밝기를 90%로 줄임
  }
`;

const CouponInputContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const CouponButton = styled.button`
  max-width: 100px;
  min-width: 100px;
  width: 20%;
  background-color: white;
  color: ${palette.AnyBlue};
  background-color: ${palette.AnyBlue100};

  margin-left: 12px;
  border: 1px solid ${palette.AnyBlue100}; // 테두리 색상 설정
  border-radius: 8px; // 테두리 둥글게 설정
  font-size: 16px; // 글꼴 크기 설정
  box-shadow: none; // 상자 그림자 없음
  margin-bottom: 12px; // 입력 필드 아래 여백 추가
  margin-top: 12px; // 입력 필드 아래 여백 추가

  cursor: pointer;
  transition: filter 0.3s ease;

  &:hover {
    filter: brightness(90%); // 밝기를 90%로 줄임
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${palette.Gray300}; // 비활성화 상태 배경색
    border-color: ${palette.Gray300}; // 비활성화 상태 테두리 색상
  }
`;

export default AnyfitProductDetailV2;
