import { createContext, useState } from 'react';

const DiscountContext = createContext();

const DiscountProvider = ({ children }) => {
  const [discountContext, setDiscountContext] = useState(null);
  return (
    <DiscountContext.Provider value={{ discountContext, setDiscountContext }}>
      {children}
    </DiscountContext.Provider>
  );
};

export { DiscountContext, DiscountProvider };
