import React, { useState, useEffect, useRef } from 'react';
import Layout from 'components/Raffle/common/Layout';
import styled from 'styled-components';
import { fontSize, palette } from 'components/Raffle/palette';
import { API_BASE_URL } from 'pages/Raffle/config';
import axios from 'axios';

const IMG_CHICKEN =
  'https://glucofit-image.s3.ap-northeast-2.amazonaws.com/LOG/Bubble+tea.png';
const IMG_CGM =
  'https://glucofit-image.s3.ap-northeast-2.amazonaws.com/icon.png';

const PrizeContainer = () => {
  const [prizeLibreData, setPrizeLibreData] = useState(null);
  const [prizeChickenData, setPrizeChickenData] = useState(null);
  const fetchPrizeData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/raffle`);
      return response.data; // axios는 자동으로 JSON을 파싱하여 반환
    } catch (error) {
      console.error('Failed to fetch data:', error);
      return null;
    }
  };

  useEffect(() => {
    const loadData = async () => {
      const data = await fetchPrizeData();

      if (data) {
        for (const prizeItem of data.prize) {
          if (prizeItem.type == 'LIBRE') {
            setPrizeLibreData(prizeItem);
          } else if (prizeItem.type == 'COFFEE') {
            setPrizeChickenData(prizeItem);
          }
        }
      }
    };

    loadData();
  }, []); // userId가 변경될 때마다 새로 데이터를 불러옴

  return (
    <EventGoodsContainer>
      <EventGoodsItem>
        <EventGoodsInfo>
          <ImageWrapper>
            <img
              src={IMG_CGM}
              alt="이미지"
              style={{
                position: 'relative',
                left: '5px',
                width: '24px', // 이미지 너비를 24px로 설정
                height: 'auto', // 높이를 auto로 설정하여 비율을 유지
                objectFit: 'contain', // 이미지 비율을 유지하면서 최대 크기 조정
                alignItems: 'center',
              }} // 이미지 크기 조정 스타일 추가
            />
          </ImageWrapper>
          <ProductName>프리스타일 리브레와</ProductName>
          <ProductName>글루코핏 멤버십 20일</ProductName>
        </EventGoodsInfo>
        <EventGoodsRemaining>
          <ProductUnit>남은 갯수</ProductUnit>
          <ProductCount>{prizeLibreData?.quantity} 개</ProductCount>
        </EventGoodsRemaining>
      </EventGoodsItem>
      <EventGoodsItem>
        <EventGoodsInfo>
          <ImageWrapper>
            <img
              src={IMG_CHICKEN}
              alt="이미지"
              style={{
                position: 'relative',
                left: '5px',
                width: '30px', // 이미지 너비를 24px로 설정
                height: 'auto', // 이미지 높이를 wrapper 높이에 맞춤
                objectFit: 'contain', // 이미지 비율을 유지하면서 최대 크기 조정
              }} // 이미지 크기 조정 스타일 추가
            />
          </ImageWrapper>
          <ProductName>아메리카노 1잔</ProductName>
        </EventGoodsInfo>
        <EventGoodsRemaining>
          <ProductUnit>남은 갯수</ProductUnit>
          <ProductCount>{prizeChickenData?.quantity} 개</ProductCount>
        </EventGoodsRemaining>
      </EventGoodsItem>
    </EventGoodsContainer>
  );
};

const EventGoodsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const EventGoodsItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;

  background-color: white; // 배경 색을 투명으로 설정
  border-radius: 10px; // 태두리 모서리를 둥글게 (반경 10픽셀)
`;

const EventGoodsInfo = styled.div`
  display: flex;
  height: 70%;
  flex-direction: column;
  // margin-top: 20px;
`;
const EventGoodsRemaining = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 30%;
  font-size: ${fontSize.small};
  text-align: center; /* 텍스트를 중앙 정렬 */
`;
const TagComponent = styled.div`
  color: white; // 글자 색을 흰색으로 설정
  border: 2px solid white; // 흰색 태두리를 2픽셀로 설정
  padding-left: 16px; // 내부 여백을 10픽셀로 설정
  padding-right: 16px; // 내부 여백을 10픽셀로 설정
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: transparent; // 배경 색을 투명으로 설정
  border-radius: 50px; // 태두리 모서리를 둥글게 (반경 10픽셀)

  margin-top: 40px;
  font-size: 12px;
`;

const ProductName = styled.div`
  display: flex;
  flex-direction: row;

  color: #4d4d4d;
  white-space: pre-line;
  width: 100%;
  flex-grow: 1;
  align-items: center;
  justify-content: center;

  font-size: ${fontSize.normal};
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: bold;
`;

const ProductUnit = styled.div`
  color: #4d4d4d;
  font-size: ${fontSize.normal};
  align-items: center; /* 수직 가운데 정렬 */

  display: flex;
  justify-content: center;
  margin-right: 1rem;
`;

const ProductCount = styled.div`
  display: flex;
  flex-direction: row;
  height: 32px;
  width: 44px;
  background-color: ${palette.LightGray}; /* 배경색 추가 */
  color: black;
  border-radius: 10px;
  align-items: center; /* 수직 가운데 정렬 */
  justify-content: center; /* 수평 가운데 정렬 */

  font-size: ${fontSize.small};
  font-weight: bolder; /* 'bolder' 키워드를 사용하여 더 굵게 만듦 */
  font-family: 'Noto Sans KR', sans-serif; /* Noto Sans KR로 변경 */
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 20px;

  justify-content: center; // 이미지를 가로 중앙에 위치
  align-items: center; // 이미지를 세로 중앙에 위치
  margin-bottom: 10px;
  margin-top: 20px;
`;

export default PrizeContainer;
