import '../CSS/PaymentMethodSelector.scss';
import card from '../../../assets/icons/ic_card.svg';
import kakao from '../../../assets/icons/ic_kakao.svg';
import naverpay from '../../../assets/images/img_naverpay.png';

export default function PaymentMethodSelector({
  order,
  handleOrder,
  interestFreeInfo = true,
}) {
  const method = order.payMethod.value;
  const setMethod = (e, method) => {
    e.target.name = 'payMethod';
    e.target.value = method;
    handleOrder(e);
  };
  return (
    <div
      className="payment-method-selector"
      id={!interestFreeInfo ? 'interest-no-free' : undefined}
    >
      <div
        className={`payment-method ${method === 'card' ? 'selected' : ''}`}
        id="ic-card"
        onClick={(e) => setMethod(e, 'card')}
      >
        <img className="payment-ic-card" src={card} alt="img" />
        <div>
          신용/체크카드
          {interestFreeInfo && (
            <div
              className="payment-selector-subtitle"
              style={{ color: '#8F8F8F' }}
            >
              [12개월 무이자 할부 가능]
            </div>
          )}
        </div>
      </div>
      <div
        className={`payment-method ${method === 'kakaopay' ? 'selected' : ''}`}
        id="kakaopay"
        onClick={(e) => setMethod(e, 'kakaopay')}
      >
        <img className="payment-ic-kakao" src={kakao} alt="img" />
        <div>
          카카오페이
          {interestFreeInfo && (
            <div
              className="payment-selector-subtitle"
              style={{ color: '#8F8F8F' }}
            >
              [12개월 무이자 할부 가능]
            </div>
          )}
        </div>
      </div>
      <div
        className={`payment-method ${method === 'naverpay' ? 'selected' : ''}`}
        id="naverpay"
        onClick={(e) => setMethod(e, 'naverpay')}
      >
        <img className="payment-ic-naver" src={naverpay} alt="img" />
        <div>
          네이버페이
          {interestFreeInfo && (
            <div
              className="payment-selector-subtitle"
              style={{ color: '#8F8F8F' }}
            >
              [12개월 무이자 할부 가능]
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
