import axios from 'axios';
import { TARGET_SERVER } from 'config';
import { DiscountContext } from 'contexts/DiscountContext';
import { useContext } from 'react';

export default function useDiscount() {
  const { discountContext, setDiscountContext } = useContext(DiscountContext);
  const INVITE_COUPON = 'znUoa9'; // 친구초대코드 쿠폰번호

  const validateCoupon = async (couponNumber) => {
    const {
      data: { data, errors },
    } = await axios.post(TARGET_SERVER, {
      query: `
        query validateCoupon($coupon_number: String!) {
          validateCoupon(coupon_number: $coupon_number) {
            coupon_id
            coupon_number
            type
            discount_price
            discount_rate
          }
        }
      `,
      variables: {
        coupon_number: couponNumber,
      },
    });
    if (!data.validateCoupon || errors?.length > 0) {
      alert(errors[0].message);
      return false;
    }

    return data.validateCoupon;
  };

  const validateInviteCode = async (inviteCode) => {
    const response = await axios.post(TARGET_SERVER, {
      query: `
      query checkInviteCodeIsValid($invite_code: String) {
        checkInviteCodeIsValid(invite_code: $invite_code)
      }
    `,
      variables: {
        invite_code: inviteCode,
      },
    });
    if (response.data.data.checkInviteCodeIsValid === 'success') {
      return true;
    } else {
      return false;
    }
  };

  const applyDiscount = async (setDiscount, couponNumber, inviteCode) => {
    // 쿠폰번호 검증 및 할인 적용
    if (couponNumber) {
      const coupon = await validateCoupon(couponNumber);
      if (coupon) {
        setDiscount((price) => {
          if (coupon.type === 'FIXED_AMOUNT') {
            return coupon.discount_price;
          }
          if (coupon.type === 'FIXED_RATE') {
            return price - price * (coupon.discount_rate / 100);
          }
          return price;
        });
        setDiscountContext(coupon);
        return true;
      }
    }

    // 친구초대코드 검증 및 할인 적용
    if (inviteCode && (await validateInviteCode(inviteCode))) {
      const coupon = await validateCoupon(INVITE_COUPON);
      if (coupon) {
        setDiscount((price) => {
          if (coupon.type === 'FIXED_AMOUNT') {
            return coupon.discount_price;
          }
          if (coupon.type === 'FIXED_RATE') {
            return price - price * (coupon.discount_rate / 100);
          }
          return price;
        });
        setDiscountContext({ ...coupon, invite_code: inviteCode });
        return true;
      }
    }

    // 전역 context 적용된 할인이 있을 경우 해당 할인 적용
    if (discountContext) {
      setDiscount((price) => {
        if (discountContext.type === 'FIXED_AMOUNT') {
          return discountContext.discount_price;
        }
        if (discountContext.type === 'FIXED_RATE') {
          return price - price * (discountContext.discount_rate / 100);
        }
        return price;
      });
      return true;
    }

    // 할인 적용할 것이 없을 경우
    return false;
  };

  return {
    discount: discountContext,
    applyDiscount,
  };
}
