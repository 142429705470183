import React from 'react';
import '../CSS/Introduction.scss';
import Step1 from '../../../assets/images/ver3.0/img-introduce-step-1.png';
import Step2 from '../../../assets/images/ver3.0/img-introduce-step-2.png';
import Step3 from '../../../assets/images/ver3.0/img-introduce-step-3.png';
import Step4 from '../../../assets/images/ver3.0/img-introduce-step-4.png';
import Step5 from '../../../assets/images/ver3.0/img-introduce-step-5.png';
import StepMobile1 from '../../../assets/images/ver3.0/img-introduce-step-1-mobile.png';
import StepMobile2 from '../../../assets/images/ver3.0/img-introduce-step-2-mobile.png';
import StepMobile3 from '../../../assets/images/ver3.0/img-introduce-step-3-mobile.png';
import StepMobile4 from '../../../assets/images/ver3.0/img-introduce-step-4-mobile.png';
import StepMobile5 from '../../../assets/images/ver3.0/img-introduce-step-5-mobile.png';
import Plus from '../../../assets/images/ver3.0/img-introduce-plus.png';
import Box from '../../../assets/images/ver3.0/video-glucofit-box.mp4';
import Patch from '../../../assets/images/ver3.0/video-glucofit-patch.mp4';

function Introduction() {
  const renderData = [
    {
      number: '01',
      title: (
        <>
          <span className="strong">실시간으로 혈당 변화</span>를 한눈에!
        </>
      ),
      titleMobile: (
        <>
          <span className="strong">실시간으로</span> <br />
          <span className="strong">혈당 변화</span>를 한눈에!
        </>
      ),
      description: <span>실시간으로 내 몸이 바뀌는 것을 볼 수 있어요</span>,
      descriptionMobile: (
        <span>
          실시간으로 내 몸이 바뀌는 것을 <br />볼 수 있어요
        </span>
      ),
      img: Step1,
      imgMobile: StepMobile1,
    },
    {
      number: '02',
      title: <span className="strong">음식별 혈당반응 분석</span>,
      titleMobile: (
        <>
          <span className="strong">음식별</span> <br />
          <span className="strong">혈당 변화</span>분석
        </>
      ),
      description: (
        <span>
          나의 혈당 반응 데이터로 식습관과 생활 습관을 <br />
          조금씩 개선해 나가요
        </span>
      ),
      descriptionMobile: (
        <span>
          나의 혈당 반응 데이터로 식습관과 <br />
          생활 습관을 조금씩 개선해 나가요
        </span>
      ),
      img: Step2,
      imgMobile: StepMobile2,
    },
    {
      number: '03',
      title: (
        <>
          <span className="strong">의사출신 코치의</span> 1:1 전문상담
        </>
      ),
      titleMobile: (
        <>
          <span className="strong">의사출신 코치의</span> <br />
          <span className="strong">1:1 전문상담</span>
        </>
      ),
      description: (
        <span>전문가에게 바로 내 고민에 대한 무제한 질문을 할 수 있어요</span>
      ),
      descriptionMobile: (
        <span>
          전문가에게 바로 내 고민에 대한 <br />
          무제한 질문을 할 수 있어요
        </span>
      ),
      img: Step5,
      imgMobile: StepMobile5,
    },
    {
      number: '04',
      title: (
        <>
          <span className="strong">AI의 초개인화</span> 혈당 리포트 제공
        </>
      ),
      titleMobile: (
        <>
          <span className="strong">AI의 초개인화 혈당</span> <br />
          <span className="strong">리포트 제공</span>
        </>
      ),
      description: (
        <span>
          우리 몸은 모두 달라요 <br />
          오직 나만을 위한 관리 리포트를 받아보세요
        </span>
      ),
      descriptionMobile: (
        <span>
          우리 몸은 모두 달라요 <br />
          오직 나만을 위한 관리 리포트를 받아보세요
        </span>
      ),
      img: Step4,
      imgMobile: StepMobile4,
    },
    {
      number: '05',
      title: <span className="strong">월간 트렌트 리포트</span>,
      titleMobile: <span className="strong">월간 트렌트 리포트</span>,
      description: (
        <span>
          한달 동안 혈당이 어떻게 변화했는지, 어떤 음식이 <br />잘 맞았는지
          자세한 내용을 리포트를 통해 확인해보세요
        </span>
      ),
      descriptionMobile: (
        <span>
          한달 동안 혈당이 어떻게 변화했는지,
          <br /> 어떤 음식이 잘 맞았는지 자세한 내용을 <br />
          리포트를 통해 확인해보세요
        </span>
      ),
      img: Step3,
      imgMobile: StepMobile3,
    },
  ];

  return (
    <div className="introduction-container">
      <div className="introduction-title-eng strong">Glucofit</div>
      <div className="introduction-title">
        글루코핏 프로그램은 <br />
        이렇게 구성돼요
      </div>
      <div className="introduction-content-container">
        <div className="introduction-content">
          <div className="introduction-content-badge strong">Glucofit</div>
          <div className="introduction-content-title">
            <span className="strong">무채혈 연속혈당</span> 측정기
            <div className="highlight" />
          </div>
          <div className="introduction-content-description">
            <span className="strong">아픈 채혈과정 없이 간편하게</span>
            스마트 폰으로 실시간 체크가 가능합니다
          </div>
          <div className="introduction-content-first">
            <div className="introduction-content-first-img">
              <video muted autoPlay loop playsInline id="sensor-video">
                <source src={Box} />
              </video>
            </div>
            <div className="introduction-content-first-img">
              <video muted autoPlay loop playsInline id="patch-video">
                <source src={Patch} type="video/mp4" />
              </video>
            </div>
            <div id="introduction-plus-icon">
              <img src={Plus} alt="추가" />
            </div>
          </div>
        </div>
        <div className="introduction-content">
          <div className="introduction-content-title">
            <span className="strong">글루코핏 멤버십</span>
            <div className="highlight" />
          </div>
          <div className="introduction-content-description">
            <span>
              <span className="strong">글루코핏 멤버십</span>으로
            </span>
            더 똑똑하고 건강하게 체지방을 감량해보세요
          </div>
          <div className="introduction-content-second">
            {renderData.map((item, index) => {
              return (
                <div className="introduction-content-step" key={item.number}>
                  <div className="step-number strong">{item.number}</div>
                  <div className="step-title web">{item.title}</div>
                  <div className="step-title mobile">{item.titleMobile}</div>
                  <div className="step-description web">{item.description}</div>
                  <div className="step-description mobile">
                    {item.descriptionMobile}
                  </div>
                  <div className="step-img web">
                    <img src={item.img} alt={`${index + 1}번`} />
                  </div>
                  <div className="step-img mobile">
                    <img src={item.imgMobile} alt={`${index + 1}번`} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Introduction;
