import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { REAL_SERVER } from 'config';

function RedirectSubscription(props) {
  let complete_subs_input = {
    imp_uid: '',
    merchant_uid: '',
    customer_uid: '',
  };
  const navigate = useNavigate();

  const completeSubscriptionOrder = async () => {
    try {
      const response = await axios.post(REAL_SERVER, {
        query: `
          mutation completeSubscriptionOrder($input: complete_subs_order_input!) {
            completeSubscriptionOrder(complete_subs_order_input: $input)
          }
        `,
        variables: {
          input: complete_subs_input,
        },
      });
      if (response.data.data.completeSubscriptionOrder === 'success') {
        navigate('/successpay');
      }
    } catch (error) {
      console.log('nocomplete');
      console.error(error);
    }
  };

  const parseUrl = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const paramValue = urlParams.get('imp_uid');
    const paramValue2 = urlParams.get('merchant_uid');
    const paramValue3 = urlParams.get('customer_uid');
    const errorMsg = urlParams.get('error_msg');
    if (errorMsg != null) {
      // 결제 실페 케이스
      alert(errorMsg);
      navigate('/payment');
    } else {
      // 결제 성공 케이스
      complete_subs_input = {
        imp_uid: paramValue,
        merchant_uid: paramValue2,
        customer_uid: paramValue3,
      };
      completeSubscriptionOrder();
    }
  };

  useEffect(() => {
    parseUrl();
  }, []);
  return <div />;
}

export default RedirectSubscription;
