import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

function PostCodeReward2Weeks(props) {
  const navigate = useNavigate();
  const element_wrap = useRef(null);

  useEffect(() => {
    new window.daum.Postcode({
      oncomplete: function (data) {
        let addr = '';

        if (data.userSelectedType === 'R') {
          addr = data.roadAddress;
        } else {
          addr = data.jibunAddress;
        }

        element_wrap.current.style.display = 'none';
        navigate('/reward2weeks', {
          state: { postcode: data.zonecode, address: addr },
        });
      },
      onresize: function (size) {
        element_wrap.current.style.height = size.height + 'px';
      },
      width: '100%',
      height: '100%',
    }).embed(element_wrap.current);

    element_wrap.current.style.display = 'block';
  }, []);

  return (
    <div>
      <div
        ref={element_wrap}
        style={{
          display: 'none',
          border: '1px solid',
          width: '100%',
          height: '100%',
          margin: '5px 0',
          position: 'relative',
        }}
      />
    </div>
  );
}

export default PostCodeReward2Weeks;
