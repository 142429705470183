import '../Payment/Payment.scss';
import '../Payment/Membership.scss';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Nav from 'components/Nav/Nav';
import Footer from 'components/Footer/Footer';
import axios from 'axios';
import card from '../../assets/icons/ic_card.svg';
import kakao from '../../assets/icons/ic_kakao.svg';
import naverpay from '../../assets/images/img_naverpay.png';
import radio from '../../assets/images/img_radio.png';
import radioGreen from '../../assets/images/img_radio_green.png';
import basicBadge from '../../assets/images/img_badges_basic.png';
import TagManager from 'react-gtm-module';
import AddressFormMall from './AddressFormMall';
import { REAL_SERVER } from 'config';

const sensorPrice = 89000;

function GlucofitMallDetail(props) {
  let muid = '';
  let impUid = '';
  let cuid = '';
  const [itemNumber, setItemNumber] = useState(1);
  const [buyerName, setBuyerName] = useState('');
  const [inviteCode, setInviteCode] = useState('');
  const [buyerPhoneNumber, setBuyerPhoneNumber] = useState('');
  const [userName, setUserName] = useState('');
  const [userPhoneNumber, setUserPhoneNumber] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [method, setMethod] = useState('card');
  const [selectedPrice, setSelectedPrice] = useState('제품구매_일반결제_0');
  const [selectedAddress, setSelectedAddress] = useState({
    postcode: '',
    address: '',
  });
  const [detailAddress, setDetailAddress] = useState('');
  const [totalAmount, setTotalAmount] = useState(sensorPrice.toLocaleString());
  const [finalAmount, setFinalAmount] = useState(sensorPrice.toLocaleString());
  const [finalAmountNum, setFinalAmountNum] = useState(sensorPrice);
  const location = useLocation();
  const phoneNumberInputRef = useRef(null);
  const [fullNumber, setFullNumber] = useState(false);
  const [nameInvalid, setNameInvalid] = useState(false);
  const [numberInvalid, setNumberInvalid] = useState(false);
  const [methodInvalid, setMethodInvalid] = useState(false);
  const [addressInvalid, setAddressInvalid] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const navigate = useNavigate();
  const [productId, setProductId] = useState();
  const inputRef = useRef(null);
  const params = useParams();

  const complete_input = {
    imp_uid: impUid,
    merchant_uid: muid,
  };

  const input = {
    payer_phone_number: buyerPhoneNumber,
    payer_name: buyerName,
    recipient_phone_number: userPhoneNumber,
    recipient_name: userName,
    address: selectedAddress?.address + ' ' + detailAddress,
    postal_code: selectedAddress?.postcode,
    amount: finalAmountNum,
    is_subscription: null,
    subscription_month_cnt: null,
    subscription_month_amount: null,
    invite_code: inviteCode,
    product_id: productId,
    product_count: itemNumber,
  };

  useEffect(() => {
    const storedSelectedPrice = localStorage.getItem('selectedPrice');
    const selectedPriceFromState = location.state?.selectedPrice;
    setSelectedPrice(selectedPriceFromState ?? storedSelectedPrice);
  }, [location.state]);

  useEffect(() => {
    const storedSelectedPrice = localStorage.getItem('selectedPrice');
    const storedItemNumber = parseInt(localStorage.getItem('itemNumber')) || 1;
    const storedBuyerName = localStorage.getItem('buyerName') || '';
    const storedBuyerPhoneNumber =
      localStorage.getItem('buyerPhoneNumber') || '';
    const storedPostCode = localStorage.getItem('postcode') || '';
    const storedAddress = localStorage.getItem('address') || '';
    const storedTotalAmount =
      localStorage.getItem('totalAmount') ||
      (params.itemId === '1' ? sensorPrice.toLocaleString() : '6,900');
    const storedFinalAmount =
      localStorage.getItem('finalAmount') ||
      (params.itemId === '1' ? sensorPrice.toLocaleString() : '6,900');
    const storedFinalAmountNum =
      parseInt(localStorage.getItem('finalAmountNum')) ||
      (params.itemId === '1' ? sensorPrice : 6900);

    setBuyerName(storedBuyerName ?? buyerName);
    setBuyerPhoneNumber(storedBuyerPhoneNumber ?? buyerPhoneNumber);
    setSelectedAddress({ postcode: storedPostCode, address: storedAddress });

    if (params.itemId === '1') {
      localStorage.setItem('selectedPrice', '제품구매_일반결제_0');
      setSelectedPrice(storedSelectedPrice ?? '제품구매_일반결제_0');
      setItemNumber(storedItemNumber ?? 1);
      setTotalAmount(storedTotalAmount ?? sensorPrice.toLocaleString());
      setFinalAmount(storedFinalAmount ?? sensorPrice.toLocaleString());
      setFinalAmountNum(storedFinalAmountNum ?? sensorPrice);
      setProductId(24);
    } else if (params.itemId === '2') {
      localStorage.setItem('selectedPrice', '패치구매_일반결제_0');
      setSelectedPrice(storedSelectedPrice ?? '패치구매_일반결제_0');
      setItemNumber(storedItemNumber ?? 20);
      setTotalAmount(storedTotalAmount ?? '6,900');
      setFinalAmount(storedFinalAmount ?? '6,900');
      setFinalAmountNum(storedFinalAmountNum ?? 6900);
      setProductId(23);
    }
  }, []);

  useEffect(() => {
    if (
      buyerName !== '' &&
      buyerPhoneNumber !== '' &&
      buyerPhoneNumber.length === 13 &&
      method !== '' &&
      selectedAddress.address !== null &&
      selectedAddress.address !== undefined
    ) {
      setIsButtonClicked(true);
    } else {
      setIsButtonClicked(false);
    }
  }, [buyerName, buyerPhoneNumber, method, selectedAddress.address]);

  const handleAddressSelect = (address) => {
    setSelectedAddress(address);
  };

  const handlePhoneNumberChange = (e, type) => {
    const formattedPhoneNumber = e.target.value.replace(/[^\d]/g, '');
    const validPhoneNumber = formatPhoneNumber(formattedPhoneNumber);
    const inputElement = e.target;
    let cursorPosition = inputElement.selectionStart;

    if (formattedPhoneNumber.length <= 11) {
      if (type === 'user') {
        localStorage.setItem('userPhoneNumber', validPhoneNumber);
        setUserPhoneNumber(localStorage.getItem('userPhoneNumber'));
      } else {
        localStorage.setItem('buyerPhoneNumber', validPhoneNumber);
        setBuyerPhoneNumber(localStorage.getItem('buyerPhoneNumber'));
      }
      if (formattedPhoneNumber.length === 11) {
        setFullNumber(true);
      }
      if (fullNumber && formattedPhoneNumber.length === 10) {
        setFullNumber(false);
        inputElement.value = validPhoneNumber;
        inputElement.setSelectionRange(cursorPosition, cursorPosition - 1);
      }
    } else {
      const truncatedPhoneNumber = formattedPhoneNumber.slice(0, 11);
      const truncatedValidPhoneNumber = formatPhoneNumber(truncatedPhoneNumber);
      if (type === 'user') {
        localStorage.setItem('userPhoneNumber', truncatedValidPhoneNumber);
        setUserPhoneNumber(localStorage.getItem('userPhoneNumber'));
      } else {
        localStorage.setItem('buyerPhoneNumber', truncatedValidPhoneNumber);
        setBuyerPhoneNumber(localStorage.getItem('buyerPhoneNumber'));
      }
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    const phoneNumberRegex = /^(\d{3})(\d{4})(\d{4})$/;
    const matches = phoneNumber.match(phoneNumberRegex);
    if (matches) {
      return `${matches[1]}-${matches[2]}-${matches[3]}`;
    }
    return phoneNumber;
  };

  const handleNameKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      phoneNumberInputRef.current.focus();
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handlePaymentMethodSelect = (selectedMethod) => {
    if (method === selectedMethod) {
      setMethod('');
    } else {
      setMethod(selectedMethod);
    }
  };

  const handlePayment = () => {
    localStorage.setItem('selectedPrice', selectedPrice);
    if (buyerName === '') {
      setNameInvalid(true);
    } else {
      setNameInvalid(false);
    }

    if (buyerPhoneNumber === '' || buyerPhoneNumber.length !== 13) {
      setNumberInvalid(true);
    } else {
      setNumberInvalid(false);
    }

    if (method === '') {
      setMethodInvalid(true);
    } else {
      setMethodInvalid(false);
    }

    if (
      selectedAddress.address === null ||
      selectedAddress.address === undefined
    ) {
      setAddressInvalid(true);
    } else {
      setAddressInvalid(false);
    }

    if (
      buyerName !== '' &&
      buyerPhoneNumber !== '' &&
      buyerPhoneNumber.length === 13 &&
      method !== '' &&
      selectedAddress.address !== null
    ) {
      window.gtag('event', 'conversion', {
        send_to: 'AW-10992256925/ulvjCNnzqKwYEJ2Pwvko',
      });
      createOrder();
    }
  };

  // 일반결제 관련 (completeOrder, requestPay, createOrder)
  const completeOrder = async () => {
    try {
      const response = await axios.post(REAL_SERVER, {
        query: `
                mutation completeOrder($input: complete_order_input!) {
                  completeOrder(complete_order_input: $input)
                }
              `,
        variables: {
          input: complete_input,
        },
      });
      if (response.data.data.completeOrder === 'success') {
        navigate('/successmall');
      }
    } catch (error) {
      console.log('nocomplete');
      console.error(error);
    }
  };

  const requestPay = (merchantUid) => {
    window.IMP.request_pay(
      {
        // pg: 'tosspayments.iamporttest_3', // 테스트
        pg: 'tosspayments.im_glucofs4sn', // 실결제
        pay_method: method,
        // m_redirect_url: 'http://localhost:3000/redirectmall', //테스트
        m_redirect_url: 'https://glucofit.co.kr/redirectmall', //실결제
        merchant_uid: merchantUid,
        name: '글루코핏몰_' + selectedPrice,
        amount: finalAmountNum,
        buyer_name: buyerName,
        buyer_tel: buyerPhoneNumber,
        buyer_addr: selectedAddress?.address + ' ' + detailAddress,
        buyer_postcode: selectedAddress?.postcode,
      },
      (response) => {
        if (Object.keys(response).includes('error_code')) {
          alert(response.error_msg);
          // 결제 취소일때 원래 페이지로
          // navigate('/requestpay');
        } else {
          complete_input.imp_uid = response.imp_uid;
          complete_input.merchant_uid = response.merchant_uid;
          completeOrder();
        }
      },
    );
  };

  const createOrder = async () => {
    try {
      const response = await axios.post(REAL_SERVER, {
        query: `
                mutation createOrder($input: create_order_input!) {
                  createOrder(create_order_input: $input) {
                    merchant_uid
                    customer_uid
                  }
                }
              `,
        variables: {
          input: input,
        },
      });
      if (response.status === 200) {
        muid = response.data.data.createOrder.merchant_uid;
        requestPay(muid);
      } else {
        alert('고객센터로 문의하세요.');
      }
    } catch (error) {
      console.error(error);
      alert('고객센터로 문의하세요.');
    }
  };

  const onIncrease = () => {
    setItemNumber((prevCount) => {
      const newCount = prevCount + 1;
      const newTotalAmount = newCount * sensorPrice;
      const formattedAmount = newTotalAmount.toLocaleString('en-US');
      setTotalAmount(formattedAmount);
      setFinalAmount(formattedAmount);
      setFinalAmountNum(newTotalAmount);
      localStorage.setItem('itemNumber', newCount.toString());
      localStorage.setItem('totalAmount', formattedAmount);
      localStorage.setItem('finalAmount', formattedAmount);
      localStorage.setItem('finalAmountNum', newTotalAmount);
      return newCount;
    });
  };

  const onDecrease = () => {
    setItemNumber((prevCount) => {
      if (prevCount > 0) {
        const newCount = prevCount - 1;
        const newTotalAmount = newCount * sensorPrice;
        const formattedAmount = newTotalAmount.toLocaleString('en-US');
        setTotalAmount(formattedAmount);
        setFinalAmount(formattedAmount);
        setFinalAmountNum(newTotalAmount);
        localStorage.setItem('itemNumber', newCount.toString());
        localStorage.setItem('totalAmount', formattedAmount);
        localStorage.setItem('finalAmount', formattedAmount);
        localStorage.setItem('finalAmountNum', newTotalAmount);
        return newCount;
      }
      return prevCount;
    });
  };

  return (
    <div>
      {params.itemId === '1' ? (
        <div className="sensoronly-payment-body">
          <div className="payment-left">
            <div>
              <div className="payment-title">결제</div>
              <div className="payment-subtitle">결제할 상품</div>
              <div>
                <div
                  className="membership-container selected"
                  onClick={() => {
                    setSelectedPrice('제품구매_일반결제_0');
                    localStorage.setItem(
                      'selectedPrice',
                      '제품구매_일반결제_0',
                    );
                  }}
                >
                  <img
                    className="radio-icon-sensor"
                    src={radioGreen}
                    alt="img"
                  />
                  <div className="membership-textcontainer-sensor">
                    <div className="membership-subtextcontainer">
                      <div>프리스타일 리브레 1개 + 패치 2매 + 알콜솜 2매</div>
                    </div>
                    <div className={`sensoronly-counter `}>
                      <div
                        className={`sensoronly-minus ${
                          itemNumber === 0 ? 'zero-count' : ''
                        }`}
                        onClick={onDecrease}
                      >
                        -
                      </div>
                      <div id="result">{itemNumber}</div>
                      <div className="sensoronly-plus" onClick={onIncrease}>
                        +
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="payment-total-subtitle-top">결제 금액</div>
              <div className="payment-total-container-top">
                <div>
                  <div className="payment-total-textContainer">
                    <div>총 상품 금액</div>
                    <div>{totalAmount}원</div>
                  </div>
                </div>
                <div className="payment-total-price">
                  <div>최종 결제 금액</div>
                  <div>
                    <div className="green3">{totalAmount} 원</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="payment-subtitle">구매자 정보</div>
              <div className="payment-info-container">
                <div className="payment-info">이름(실명)</div>
                <input
                  value={localStorage.getItem('buyerName') ?? buyerName}
                  ref={inputRef}
                  placeholder="실명으로 입력해 주세요"
                  onChange={(e) => {
                    localStorage.setItem('buyerName', e.target.value);
                    setBuyerName(localStorage.getItem('buyerName'));
                  }}
                  onKeyDown={handleNameKeyDown}
                  className={nameInvalid ? 'error' : ''}
                />
                <div className="validation-text">
                  {nameInvalid ? '이름을 입력해 주세요' : ''}
                </div>
              </div>
              <div className="payment-info-container">
                <div className="payment-info">휴대폰 번호</div>
                <input
                  ref={phoneNumberInputRef}
                  value={
                    localStorage.getItem('buyerPhoneNumber') ??
                    localStorage.getItem('buyerPhoneNumber')
                  }
                  placeholder="휴대폰 번호"
                  inputMode="numeric"
                  onChange={(e) => handlePhoneNumberChange(e, 'buyer')}
                  className={numberInvalid ? 'error' : ''}
                />
                <div className="validation-text">
                  {numberInvalid ? '휴대폰 번호를 입력해 주세요' : ''}
                </div>
              </div>
              <div className="payment-info-container">
                <div className="payment-info">제품 배송지</div>
                <AddressFormMall
                  handleAddressSelect={handleAddressSelect}
                  addressInvalid={addressInvalid}
                  itemId={params.itemId}
                />
                <input
                  type="text"
                  id="sample6_detailAddress"
                  placeholder="상세주소 (예: 101동 101호)"
                  value={detailAddress}
                  onChange={(e) => setDetailAddress(e.target.value)}
                  ref={inputRef}
                />
              </div>
              <div className="payment-subtitle">
                결제 수단
                <div className="validation-text-method">
                  {methodInvalid ? '결제 수단을 선택해 주세요' : ''}
                </div>
              </div>
              <div className="payment-method">
                <div
                  className={`payment-method-container ${
                    method === 'card' ? 'selected' : ''
                  }`}
                  onClick={() => handlePaymentMethodSelect('card')}
                >
                  <img className="payment-ic-card" src={card} alt="img" />
                  <div>신용/체크카드</div>
                </div>
                <div
                  className={`payment-method-container ${
                    method === 'kakaopay' ? 'selected' : ''
                  }`}
                  onClick={() => handlePaymentMethodSelect('kakaopay')}
                >
                  <img className="payment-ic-kakao" src={kakao} alt="img" />
                  <div>카카오페이</div>
                </div>
                <div
                  className={`payment-method-container ${
                    method === 'naverpay' ? 'selected' : ''
                  }`}
                  onClick={() => handlePaymentMethodSelect('naverpay')}
                >
                  <img className="payment-ic-naver" src={naverpay} alt="img" />
                  <div>네이버페이</div>
                </div>
              </div>
            </div>
          </div>
          <div className="payment-right">
            <div className="payment-total-container-bottom">
              <div className="payment-total-subtitle">결제 금액</div>
              <div>
                <div className="payment-total-textContainer">
                  <div>총 상품 금액</div>
                  <div>{finalAmount}원</div>
                </div>
              </div>
              <div className="payment-total-price">
                <div>최종 결제 금액</div>
                <div>
                  <div className="green3">{finalAmount}원</div>
                </div>
              </div>
            </div>
            <button
              className={`payment-totalAccept-button ${
                isButtonClicked ? 'clicked' : ''
              }`}
              onClick={handlePayment}
            >
              {finalAmount}원 결제하기
            </button>
          </div>
        </div>
      ) : (
        <div className="sensoronly-payment-body">
          <div className="payment-left">
            <div>
              <div className="payment-title">결제</div>
              <div className="payment-subtitle">결제할 상품</div>
              <div>
                <div
                  className="membership-container selected"
                  onClick={() => {
                    setSelectedPrice('제품구매_일반결제_0');
                    localStorage.setItem(
                      'selectedPrice',
                      '제품구매_일반결제_0',
                    );
                  }}
                >
                  <img
                    className="radio-icon-sensor"
                    src={radioGreen}
                    alt="img"
                  />
                  <div className="membership-textcontainer-sensor">
                    <div className="membership-subtextcontainer">
                      <div>프리스타일 리브레 살색 패치 (20매)</div>
                    </div>
                    {/* <div className={`sensoronly-counter `}>
                      <input
                        className={`sensoronly-minus ${itemCount === 0 ? 'zero-count' : ''}`}
                        onClick={onDecrease}
                        type='button'
                        value='-'
                      />
                      <div id='result'>{itemCount}</div>
                      <input
                        className='sensoronly-plus'
                        onClick={onIncrease}
                        type='button'
                        value='+'
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="payment-total-subtitle-top">결제 금액</div>
              <div className="payment-total-container-top">
                <div>
                  <div className="payment-total-textContainer">
                    <div>총 상품 금액</div>
                    <div>{totalAmount}원</div>
                  </div>
                </div>
                <div className="payment-total-price">
                  <div>최종 결제 금액</div>
                  <div>
                    <div className="green3">{totalAmount} 원</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="payment-subtitle">구매자 정보</div>
              <div className="payment-info-container">
                <div className="payment-info">이름(실명)</div>
                <input
                  value={localStorage.getItem('buyerName') ?? buyerName}
                  ref={inputRef}
                  placeholder="실명으로 입력해 주세요"
                  onChange={(e) => {
                    localStorage.setItem('buyerName', e.target.value);
                    setBuyerName(localStorage.getItem('buyerName'));
                  }}
                  onKeyDown={handleNameKeyDown}
                  className={nameInvalid ? 'error' : ''}
                />
                <div className="validation-text">
                  {nameInvalid ? '이름을 입력해 주세요' : ''}
                </div>
              </div>
              <div className="payment-info-container">
                <div className="payment-info">휴대폰 번호</div>
                <input
                  ref={phoneNumberInputRef}
                  value={
                    localStorage.getItem('buyerPhoneNumber') ??
                    localStorage.getItem('buyerPhoneNumber')
                  }
                  placeholder="휴대폰 번호"
                  inputMode="numeric"
                  onChange={(e) => handlePhoneNumberChange(e, 'buyer')}
                  className={numberInvalid ? 'error' : ''}
                />
                <div className="validation-text">
                  {numberInvalid ? '휴대폰 번호를 입력해 주세요' : ''}
                </div>
              </div>
              <div className="payment-info-container">
                <div className="payment-info">제품 배송지</div>
                <AddressFormMall
                  handleAddressSelect={handleAddressSelect}
                  addressInvalid={addressInvalid}
                  itemId={params.itemId}
                />
                <input
                  type="text"
                  id="sample6_detailAddress"
                  placeholder="상세주소 (예: 101동 101호)"
                  value={detailAddress}
                  onChange={(e) => setDetailAddress(e.target.value)}
                  ref={inputRef}
                />
              </div>
              <div className="payment-subtitle">
                결제 수단
                <div className="validation-text-method">
                  {methodInvalid ? '결제 수단을 선택해 주세요' : ''}
                </div>
              </div>
              <div className="payment-method">
                <div
                  className={`payment-method-container ${
                    method === 'card' ? 'selected' : ''
                  }`}
                  onClick={() => handlePaymentMethodSelect('card')}
                >
                  <img className="payment-ic-card" src={card} alt="img" />
                  <div>신용/체크카드</div>
                </div>
                <div
                  className={`payment-method-container ${
                    method === 'kakaopay' ? 'selected' : ''
                  }`}
                  onClick={() => handlePaymentMethodSelect('kakaopay')}
                >
                  <img className="payment-ic-kakao" src={kakao} alt="img" />
                  <div>카카오페이</div>
                </div>
                <div
                  className={`payment-method-container ${
                    method === 'naverpay' ? 'selected' : ''
                  }`}
                  onClick={() => handlePaymentMethodSelect('naverpay')}
                >
                  <img className="payment-ic-naver" src={naverpay} alt="img" />
                  <div>네이버페이</div>
                </div>
              </div>
            </div>
          </div>
          <div className="payment-right">
            <div className="payment-total-container-bottom">
              <div className="payment-total-subtitle">결제 금액</div>
              <div>
                <div className="payment-total-textContainer">
                  <div>총 상품 금액</div>
                  <div>{finalAmount}원</div>
                </div>
              </div>
              <div className="payment-total-price">
                <div>최종 결제 금액</div>
                <div>
                  <div className="green3">{finalAmount}원</div>
                </div>
              </div>
            </div>
            <button
              className={`payment-totalAccept-button ${
                isButtonClicked ? 'clicked' : ''
              }`}
              onClick={handlePayment}
            >
              {finalAmount}원 결제하기
            </button>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
}

export default GlucofitMallDetail;
