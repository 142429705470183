import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const PostCodeMembership = ({ isParamEmpty = true }) => {
  const navigate = useNavigate();
  const element_wrap = useRef(null);

  useEffect(() => {
    new window.daum.Postcode({
      oncomplete: function (data) {
        let addr = '';

        if (data.userSelectedType === 'R') {
          addr = data.roadAddress;
        } else {
          addr = data.jibunAddress;
        }

        // element_wrap.current.style.display = 'none';
        // navigate('/sensoronly', { state: { postcode: data.zonecode, address: addr } });
        element_wrap.current.style.display = 'none';
        if (isParamEmpty === true) {
          navigate('/membershipOnly', {
            state: { postcode: data.zonecode, address: addr },
          });
        } else {
          navigate(
            `/membershipOnly?name=${encodeURIComponent(
              localStorage.getItem('buyerName'),
            )}&phone_number=${encodeURIComponent(
              localStorage.getItem('buyerPhoneNumberSensor'),
            )}`,
            { state: { postcode: data.zonecode, address: addr } },
          );
        }
      },
      onresize: function (size) {
        element_wrap.current.style.height = size.height + 'px';
      },
      width: '100%',
      height: '100%',
    }).embed(element_wrap.current);

    element_wrap.current.style.display = 'block';
  }, []);

  return (
    <div>
      <div
        ref={element_wrap}
        style={{
          display: 'none',
          border: '1px solid',
          width: '100%',
          height: '100%',
          margin: '5px 0',
          position: 'relative',
        }}
      />
    </div>
  );
};

export default PostCodeMembership;
