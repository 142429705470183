import React, { useState, useRef, useEffect } from 'react';
import '../CSS/Review.scss';
import ReviewSwiper from 'components/Review/ReviewSwiper';

function Review() {
  // const fetchReviews = async () => {
  //   client
  //     .query({
  //       query: GET_LANDINGPAGE_REVIEWS,
  //       fetchPolicy: 'no-cache',
  //     })
  //     .then(response => {
  //       const reviewData = response.data.getLandingPageReviews;
  //       setReviews(reviewData);
  //       console.log(reviewData);
  //     })
  //     .catch(error => {
  //       console.error(error);
  //     });
  // };

  // useEffect(() => {
  //   fetchReviews();
  // }, []);

  const truncateMessage = (message, maxLength) => {
    if (message && message.length <= maxLength) {
      return message;
    } else if (message) {
      return message.substring(0, maxLength) + '...';
    }
    return '';
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}.${month}.${day}`;
  }

  return (
    <div className="review-container">
      <div className="review-title-eng strong">Review</div>
      <div className="review-title">
        글루코핏을 시작한 분들은 <br />
        <span className="strong">인생이 달라졌다</span>고 말합니다
      </div>
      <div className="review-subtitle">
        진짜 몸이 변하는 실사용자 리뷰를 통해 직접 눈으로 확인하세요
      </div>
      <ReviewSwiper />
    </div>
  );
}

export default Review;
