import './SelectPrice.scss';
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { createBrowserHistory } from 'history';
import ellipse from '../../assets/icons/ic_ellipse.svg';
import 'react-toastify/dist/ReactToastify.css';
import TagManager from 'react-gtm-module';

function SelectPrice(props) {
  const [selectedPrice, setSelectedPrice] = useState(null);
  const navigate = useNavigate();
  const history = createBrowserHistory();
  const [modalOpen, setModalOpen] = useState(true);

  const tagManagerArgs = {
    gtmId: 'GTM-57FDQMS',
  };

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    setSelectedPrice('12주');
    localStorage.setItem('selectedPrice', '12주');
  }, []);

  const onClickPayment = () => {
    if (selectedPrice) {
      window.gtag('event', 'conversion', {
        send_to: 'AW-10992256925/PHU5CM3zqKwYEJ2Pwvko',
      });
      const localPrice = localStorage.getItem('selectedPrice');
      navigate('/requestpay', { state: { selectedPrice: localPrice } });
    } else {
      toast('상품을 선택해 주세요', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        style: {
          backgroundColor: '#141414',
          color: 'white',
          textAlign: 'center',
        },
        bodyStyle: {
          backgroundColor: '#141414',
          color: 'white',
        },
      });
    }
  };

  const closeModal = () => {
    props.handleClose();
  };

  const handleModalClose = () => {
    closeModal();
  };
  const onClickReject = () => {
    props.handleClose();
  };

  useEffect(() => {
    const event = history.listen((listener) => {
      if (listener.action === 'POP') {
        history.back();
        closeModal();
      }
    });

    window.history.pushState(null, '', window.location.href);

    return () => {
      event();
    };
  }, [history, closeModal]);

  return (
    <Modal
      show={props.show}
      onShow={() => setModalOpen(true)}
      onHide={props.handleClose}
      centered
      dialogClassName="custom-modal-dialog"
    >
      <Modal.Body>
        <div className="modal-title">
          글루코핏과 함께
          <br />
          건강하게 체지방을 감량해 보세요
        </div>
        <div className="price-container-select">
          <div
            className={`price-contents-select custom-price-contents ${
              selectedPrice === '4주' ? 'selected' : ''
            }`}
            onClick={() => {
              setSelectedPrice('4주');
              localStorage.setItem('selectedPrice', '4주');
              window.gtag('event', 'conversion', {
                send_to: 'AW-10992256925/PHU5CM3zqKwYEJ2Pwvko',
              });
            }}
          >
            <div className="price-title">
              <div className="price-left">
                <div className="price-time-modal">4주</div>
                <div className="price-sensor-modal">센서 2개 발송</div>
              </div>
              <div className="price-right">
                <div className="price-percent">25%</div>
                <div className="price-price">297,000원</div>
              </div>
            </div>
            <div className="price-right-down">
              <div className="price-original-modal">400,000원</div>
              {/* <div className='price-original-modal-4주'>정가</div> */}
              <div className="price-daily-container">
                <div className="price-daily">하루 10,607원</div>
                <img className="price-ic" src={ellipse} alt="img" />
                <div className="price-discount-payment">매일 4,078원 할인</div>
              </div>
            </div>
          </div>
          <div
            className={`price-contents-select custom-price-contents ${
              selectedPrice === '12주' ? 'selected' : ''
            }`}
            onClick={() => {
              setSelectedPrice('12주');
              localStorage.setItem('selectedPrice', '12주');
              window.gtag('event', 'conversion', {
                send_to: 'AW-10992256925/TyM_CNDzqKwYEJ2Pwvko',
              });
            }}
          >
            <div className="price-title">
              <div className="price-left">
                <div className="price-time-modal">12주</div>
                <div className="price-sensor-modal">매달 센서 2개 발송</div>
                <div className="price-best-modal">BEST</div>
              </div>
              <div className="price-right">
                <div className="price-percent">37%</div>
                <div className="price-price">765,000원</div>
              </div>
            </div>
            <div className="price-right-down">
              <div className="price-original-modal">1,200,000원</div>
              <div className="price-daily-container">
                <div className="price-daily">하루 9,107원</div>
                <img className="price-ic" src={ellipse} alt="img" />
                <div className="price-discount-select">매달 45,000원 할인</div>
              </div>
            </div>
          </div>
          <div
            className={`price-contents-select custom-price-contents ${
              selectedPrice === '1년' ? 'selected' : ''
            }`}
            onClick={() => {
              setSelectedPrice('1년');
              localStorage.setItem('selectedPrice', '1년');
              window.gtag('event', 'conversion', {
                send_to: 'AW-10992256925/94aMCNPzqKwYEJ2Pwvko',
              });
            }}
          >
            <div className="price-title">
              <div className="price-left">
                <div className="price-time-modal">1년</div>
                <div className="price-sensor-modal">매달 센서 2개 발송</div>
              </div>
              <div className="price-right">
                <div className="price-percent">44%</div>
                <div className="price-price">2,700,000원</div>
              </div>
            </div>
            <div className="price-right-down">
              <div className="price-original-modal">4,800,000원</div>
              <div className="price-daily-container">
                <div className="price-daily">하루 8,035원</div>
                <img className="price-ic" src={ellipse} alt="img" />
                <div className="price-discount-select">매달 75,000원 할인</div>
              </div>
            </div>
          </div>
          <div className="modal-button-container">
            <button className="accept-button" onClick={onClickPayment}>
              건강한 다이어트 시작하기
            </button>
            <button className="reject-button" onClick={onClickReject}>
              나중에 할게요
            </button>
          </div>
        </div>
      </Modal.Body>
      <ToastContainer />
    </Modal>
  );
}

export default SelectPrice;

//뒤로가기 버튼 로직
// useEffect(() => {
//   const event = history.listen(listener => {
//     if (listener.action === 'POP') {
//       history.back();
//       closeModal();
//     }
//   });

//   window.history.pushState(null, '', window.location.href);

//   return () => {
//     event();
//   };
// }, [history, closeModal]);
// useEffect(() => {
//   const event = history.listen(listener => {
//     if (listener.action === 'POP') {
//       // history.back();
//       props.handleClose();
//     }
//   });
//   // return event;
// }, [history]);
