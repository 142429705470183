import React, { useState, useRef } from 'react';
import '../CSS/BlogReview.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import Blog1 from '../../../assets/images/img_blog_1.png';
import Blog2 from '../../../assets/images/img_blog_2.png';
import Blog3 from '../../../assets/images/img_blog_3.jpg';
import Blog4 from '../../../assets/images/img_blog_4.png';
import Blog5 from '../../../assets/images/img_blog_5.png';
import Blog6 from '../../../assets/images/img_blog_6.png';
import Blog7 from '../../../assets/images/img_blog_7.png';
import Blog8 from '../../../assets/images/img_blog_8.png';

// import review1 from '../../../assets/images/img_insta_review_1.jpg';
// import review2 from '../../../assets/images/img_insta_review_2.jpg';
// import review3 from '../../../assets/images/img_insta_review_3.jpg';
// import review4 from '../../../assets/images/img_insta_review_4.jpg';
// import star from '../../../assets/images/img_star.png';

const renderData = [
  {
    title: (
      <span>혈당 관리로 종결하는 다이어트 &lt;글루코핏&gt; 내돈내산 후기1</span>
    ),
    content: (
      <span>
        소아기를 제외한 전체 인생을 다이어트로 보냈다고 해도 과언이 아닌 나의
        삶! 최근들어 <br />
        가장 많은 관심을 받고 있는 것이 바로 '혈당'이다. 다이어트의 불패신화{' '}
        <br />
        '닭고야(닭+고구마+야채)'를 매일같이 먹는 사람이 있다고 가정하자. 만일
        고구마가 내 <br />
        몸과 좋은 짝궁이 아닐 경우, 끼니마다 굳이 닭고야를 먹으며 혈당
        스파이크를 유발하는 <br />
        식사를 하게 되는 것이다. 하지만 현미나 호밀빵 역시 내 몸에 혈당
        스파이크를...
      </span>
    ),
    contentMobile: (
      <span>
        소아기를 제외한 전체 인생을 다이어트로 보냈다고 해도 과언이 아닌 나의
        삶! 고구마가 내 몸과 좋은 짝궁이 아닐 경우, 끼니마다 굳이 닭고야를...
      </span>
    ),
    img: Blog1,
    link: 'https://blog.naver.com/erinization/223017178832',
  },
  {
    title: (
      <span>
        &lt;글루코핏&gt;으로 찾아낸 혈당 안 오르는 음식 리스트 <br />
        (내일부터 식후 에그타르트 갑니다...)
      </span>
    ),
    content: (
      <span>
        앞서 게시한 글루코핏 후기들에 이어 혈당 안 오르는 음식 리스트를
        공유한다. 그런데 이 리스트는 오직글쓴이인 나에 한해서 적용이 가능하다.
        글루코핏은 개인별 혈당 최적 음식을 찾아주기에 이 리스트를 당신에게
        그대로 적용하면 어마무시한 혈당 스파이크를 경험할 수도 있다. 그럼에도 이
        리스트를 공유하는 이유는, 일반적으로 알려진 '다이어트에 좋은 음식'이
        당신의 몸에 언제나...
      </span>
    ),
    contentMobile: (
      <span>
        앞서 게시한 글루코핏 후기들에 이어 혈당 안 오르는 음식 리스트를
        공유한다. 그런데 이 <br />
        리스트는 오직 글쓴이인 나에 한해서 적용이 가능하다...
      </span>
    ),
    img: Blog2,
    link: 'https://blog.naver.com/erinization/223017179474',
  },
  {
    title: <span> 글루코핏 체험 일기 (1일 차)</span>,
    content: (
      <span>
        건강한 다이어트, 똑똑한 다이어트​ 이것 저것 안해 본 다이어트가 없지만
        그렇다고 요요 <br />
        없이 성공한 다이어트도 없다. 이젠 내 몸에 맞는, 똑똑하고 현명한 그리고
        평생 할 수 <br />
        있는 다이어트를 하고 싶다.2023년 5월 22일 1일차 먼저 기본값으로 혈당의
        범위를 <br />
        70~120mg/dl로 설정해 두었다. 하루 중 혈당스파이크가 3번 발생하였다.
        음식을 먹을 때 <br />
        먹을 때 마다 혈당이 상승하는 건 당연한 것이다...
      </span>
    ),
    contentMobile: (
      <span>
        챌린지를 시작하며 좋은 기회로 마이노멀과 글루코핏의 지원을 받아 혈당
        관찰 챌린지에 참여하게 됐어요! ​ 저는 과거 극단적인 다이어트로...
      </span>
    ),
    img: Blog3,
    link: 'https://blog.naver.com/jsy_luna/223109965742',
  },
  {
    title: <span>생애 첫 혈당 다이어트 도전 성공! 마법 같은 글루코핏</span>,
    content: (
      <span>
        저는 오랜 직장생활로 체지방에 고민이 생겼고, 최근에 글루코핏이라는 혈당
        다이어트 <br />
        프로그램에 참여했습니다. 그 후기를 공유드립니다. ​ 4주 프로그램 참여
        결과입니다 <br />
        글루코핏 프로그램 1개월 동안 근육량은 그대로 유지되고, 체지방만 1.6kg
        빠져서 <br />
        결과적으로 체지방률은 2%p 감소했네요 (좌측 인바디부터 1달전, 2주전,
        오늘입니다) <br />
        골격근량 유지하고, 체지방만 1.6kg 감량 성공 ​운동 루틴은 ...
      </span>
    ),
    contentMobile: (
      <span>
        4주 프로그램 참여 결과입니다 ​ 글루코핏 프로그램 1개월 동안 근육량은
        그대로 <br />
        유지되고, 체지방만 1.6kg 빠져서 결과적으로...
      </span>
    ),
    img: Blog4,
    link: 'https://blog.naver.com/mygluco/222962297855',
  },
  {
    title: (
      <span>마이노멀 X 글루코핏 혈당 관찰 챌린지, 1일차 (07. 01. 토)</span>
    ),
    content: (
      <span>
        오늘 제가 소개하는건 연속혈당측정기를 사용해서 혈당을 조절하는 거예요.
        그래서 <br />
        일반적인 방법이랑은 완전 다르죠? 처음에는 저도 이게 너무 신기해서 더
        궁금했고, <br />
        또 아시다시피 제가 전당뇨로 한번 고생을 해서 빡세게 관리중이거든요.
        지금은 <br />
        건강해졌지만 과연 지금도 괜찮을까하는 궁금증으로 참여해보았어요...
      </span>
    ),
    contentMobile: (
      <span>
        오늘 제가 소개하는건 연속혈당측정기를 사용해서 혈당을 조절하는 거예요.
        그래서 <br />
        일반적인 방법이랑은 완전 다르죠? ...
      </span>
    ),
    img: Blog5,
    link: 'https://blog.naver.com/newpy__/223144655329',
  },
  {
    title: <span>혈당으로 체지방 줄이는법 쉬워요!</span>,
    content: (
      <span>
        저는 오랜 직장생활로 체지방에 고민이 생겼고, 최근에 글루코핏이라는 혈당
        다이어트 프로그램에 참여했습니다. 그 후기를 공유드립니다. ​ 4주 프로그램
        참여 결과입니다 <br />
        글루코핏 프로그램 1개월 동안 근육량은 그대로 유지되고, 체지방만 1.6kg
        빠져서 <br />
        결과적으로 체지방률은 2%p 감소했네요 (좌측 인바디부터 1달전, 2주전,
        오늘입니다) <br />
        골격근량 유지하고, 체지방만 1.6kg 감량 성공 ​운동 루틴은 ...
      </span>
    ),
    contentMobile: (
      <span>
        4주 프로그램 참여 결과입니다 ​ 글루코핏 프로그램 1개월 동안 근육량은
        그대로 <br />
        유지되고, 체지방만 1.6kg 빠져서 결과적으로...
      </span>
    ),
    img: Blog6,
    link: 'https://m.blog.naver.com/siehl/223050528955',
  },
  {
    title: <span>글루코핏 혈당스파이크 스스로 조절해서 삶의질 올리기</span>,
    content: (
      <span>
        혈당관리 하면 당뇨인들에게서 많이 듣는 단어일텐데요, 하지만 이 혈당이
        일상생활속에서 당뇨가 없는 비당뇨인들에게도 큰 영향을 주고 있습니다.
        아마 혈당스파이크 많이 들어보셨을 텐데요,특히나 다이어트 하시는 분들은
        혈당스파이크를 조심해야 하는데요, 이에 대해 소개드리려고 합니다...
      </span>
    ),
    contentMobile: (
      <span>
        혈당관리 하면 당뇨인들에게서 많이 듣는 단어일텐데요, 하지만 이 혈당이
        일상생활속에서 당뇨가 없는 비당뇨인들에게도 큰 영향을...
      </span>
    ),
    img: Blog7,
    link: 'https://blog.naver.com/pkyap1201/223051136549',
  },
  {
    title: <span>체지방 줄이는법 혈당 관리까지 건강하게</span>,
    content: (
      <span>
        요즘 저의 관심사 중 하나는 건강하게 체지방 줄이는법. 원래는 입에도 대지
        않았던 다이어트 보조제 제품들을 재작년부터는 섭취하기 시작했는데 말
        그대로 보조제일뿐 근본적인 해결 방법이 되지는 않더라고요. 그런 제 눈에
        들어온 건 혈당 관리와 병행하여 다이어트하는 글루코핏...
      </span>
    ),
    contentMobile: (
      <span>
        요즘 저의 관심사 중 하나는 건강하게 체지방 줄이는법. 원래는 입에도 대지
        않았던 다이어트 보조제 제품들을 재작년부터 섭취하기 ...
      </span>
    ),
    img: Blog8,
    link: 'https://blog.naver.com/newpy__/223144655329',
  },
];

function BlogReview() {
  const [showMore, setShowMore] = useState(false);
  const contentRef = useRef(null);

  const handleViewMore = () => {
    setShowMore((prev) => !prev);

    contentRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <div className="blog-review-container">
      <div className="blog-review-title-eng strong">Blog Review</div>
      <div className="blog-review-title">
        <span className="strong color">10명 중 8명이 만족</span>하여 <br />
        <span className="strong">계속 참여</span>하고 있어요
      </div>
      <div className="blog-review-subtitle">
        내돈내산 실제 블로그 리뷰를 통해 직접 눈으로 확인하세요
      </div>
      <div className="blog-review-web">
        <div className="blog-review-contents">
          {renderData.slice(0, 5).map((item, index) => {
            return (
              <div
                className="blog-review-content"
                key={index}
                onClick={() => (window.location.href = `${item.link}`)}
              >
                <div className="blog-review-content-text">
                  <div className="blog-review-content-title">{item.title}</div>
                  <div className="blog-review-content-description">
                    {item.content}
                  </div>
                </div>
                <div className="blog-review-content-img">
                  <img src={item.img} alt="" />
                </div>
              </div>
            );
          })}
          <div ref={contentRef} className="blog-review-contents-more">
            {showMore &&
              renderData.slice(5).map((item, index) => {
                return (
                  <div
                    className="blog-review-content"
                    key={index}
                    onClick={() => (window.location.href = `${item.link}`)}
                  >
                    <div className="blog-review-content-text">
                      <div className="blog-review-content-title">
                        {item.title}
                      </div>
                      <div className="blog-review-content-description">
                        {item.content}
                      </div>
                    </div>
                    <div className="blog-review-content-img">
                      <img src={item.img} alt="" />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <button className="blog-review-more" onClick={handleViewMore}>
          {showMore ? '접기' : '더보기'}
        </button>
      </div>
      <div className="blog-review-mobile">
        <Swiper
          slidesPerView="auto"
          spaceBetween={12}
          centeredSlides={true}
          style={{
            height: '20.125rem',
          }}
        >
          {renderData.map((item, index) => {
            return (
              <SwiperSlide
                key={index}
                onClick={() => (window.location.href = `${item.link}`)}
                style={{
                  backgroundColor: '#F3F3F3',
                  width: 312,
                  height: 322,
                  borderRadius: 8,
                  padding: 16,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 16,
                  overflow: 'hidden',
                }}
              >
                <div className="blog-review-content-header-mobile">
                  <div className="blog-review-content-title-mobile strong">
                    {item.title}
                  </div>
                  <div className="blog-review-content-img-mobile">
                    <img src={item.img} alt="" />
                  </div>
                </div>
                <div className="blog-review-content-description-mobile">
                  {item.contentMobile}
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}

export default BlogReview;

//과거 데이터
/**
 * <div className='carousel-container'>
          <div className='carousel-slide'>
            <div
              className='review-item'
              onClick={() =>
                window.open(
                  'https://www.instagram.com/reel/CtGy728phwN/?igshid=MzRlODBiNWFlZA%3D%3D',
                )
              }
            >
              <img src={review4} alt='Review 4' />
              <div className='review-text-container'>
                <div className='review-instaId'>@babymiso__</div>
                <img src={star} alt='Review 1' />
                <div>
                  지난 한 주간 제가 건강하게 먹었는데도 혈당 스파이크가 높이 높이 치솟은거
                  기억하시나요?🥲 잠도 못자고 스트레스 많이 받은 것도 있지만..
                </div>
              </div>
            </div>
            <div
              className='review-item'
              onClick={() =>
                window.open('https://www.instagram.com/p/Cq4HtMbvL1H/?igshid=MzRlODBiNWFlZA==')
              }
            >
              <img src={review3} alt='Review 3' />
              <div className='review-text-container'>
                <div className='review-instaId'>@ingzoo.diet</div>
                <img src={star} alt='Review 1' />
                <div>
                  똑같은 음식을 먹어도 사람마다혈당을 올리거나 살찌는 정도는 다 다르다고 해요. 혈당
                  센서로 실시간으로 측정한 나의 혈당데이터를 기반으로..
                </div>
              </div>
            </div>
            <div
              className='review-item'
              onClick={() =>
                window.open(
                  'https://www.instagram.com/p/Cr_CF0bJ-fz/?img_index=2&igshid=YmQ5MjYyNzNkNQ==',
                )
              }
            >
              <img src={review2} alt='Review 2' />
              <div className='review-text-container'>
                <div className='review-instaId'>@heavenpark</div>
                <img src={star} alt='Review 1' />
                <div>
                  #유지어터4년차 급혼란이 오다:폭발하는_머리: '평생 닭가슴살 고구마 샐러드만 먹어야
                  되나?' 매식단 나의 혈당을 실시간으로 체크해서 나에게 맞는 음식을 찾아...
                </div>
              </div>
            </div>

            <div
              className='review-item'
              onClick={() =>
                window.open('https://www.instagram.com/p/CsgL4jsrL1Z/?igshid=MzRlODBiNWFlZA==')
              }
            >
              <img src={review1} alt='Review 1' />
              <div className='review-text-container'>
                <div className='review-instaId'>@nana.dietstory</div>
                <img className='review-star' src={star} alt='Review 1' />
                <div>
                  최근 2-3년 전부터 밥을 먹고나면 잠이 쏟아지듯 오는 ‘혈당 스파이크’를 경험하기
                  시작했어요. 약 6년전부터 필라테스 강사를 시작하면서..
                </div>
              </div>
            </div>
          </div>
        </div>
 */
