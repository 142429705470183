import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Index(props) {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/');
  }, []);

  return <div />;
}

export default Index;
