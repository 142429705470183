import axios from 'axios';
import Footer from 'components/Footer/Footer';
import Nav from 'components/Nav/Nav';
import React, { useEffect, useState } from 'react';
import './PaymentMethodChange.scss';
import { LOCAL_SERVER, DEV_SERVER, REAL_SERVER } from 'config';
import Checked from '../../assets/icons/ic-payment-checkbox-checked.svg';
import UnChecked from '../../assets/icons/ic-payment-checkbox.svg';
import CardImg from '../../assets/icons/ic_card.svg';
import KakaoImg from '../../assets/icons/ic_kakao.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';

function PaymentMethodChange() {
  const TARGET_SERVER = REAL_SERVER;

  const navigate = useNavigate();
  const [isFound, setIsFound] = useState(false);
  const [method, setMethod] = useState('card');
  const [currentPaymentInfo, setCurrentPayementInfo] = useState({
    payerName: '',
    payerPhoneNumber: '',
  });
  const [newPaymentInfo, setNewPaymentInfo] = useState({
    payerName: '',
    payerPhoneNumber: '',
  });

  // 모바일의 경우, m_redirect_url로 해당 페이지를 설정하여, 결제수단 변경 결과를 받는다.
  const [searchParams] = useSearchParams();
  const isMethodChangeSuccess = searchParams.get('imp_success');

  /* API 관련 함수 */
  // billing_key(customer_uid) 조회 API
  const getPaymentBillingKey = async (payer_name, payer_phone_number) => {
    const response = await axios.post(TARGET_SERVER, {
      query: `
        query findPaymentBillingKey($input: find_payment_billing_key_input!) {
          findPaymentBillingKey(filter: $input) {
            id
            name
            phone_number
            status
          }
        }
      `,
      variables: {
        input: {
          name: payer_name,
          phone_number: payer_phone_number,
          status: 'USING',
        },
      },
    });
    const data = response?.data?.data?.findPaymentBillingKey;
    if (!data || data.length === 0) {
      throw new Error('결제정보가 없습니다. 입력정보를 확인해주세요.');
    }
    if (data.length > 1) {
      throw new Error('결제정보가 중복되었습니다. 고객센터에 문의해주세요.');
    }
    return data;
  };
  // billing_key(customer_uid) 생성 API
  const createPaymentBillingKey = async () => {
    const { payerName, payerPhoneNumber } = newPaymentInfo;
    const response = await axios.post(TARGET_SERVER, {
      query: `
        mutation createPaymentBillingKey($input: create_payment_billing_key_input!) {
          createPaymentBillingKey(input: $input) {
            id
            name
            phone_number
            status
          }
        }
      `,
      variables: {
        input: {
          name: payerName,
          phone_number: payerPhoneNumber,
        },
      },
    });

    const data = response?.data?.data?.createPaymentBillingKey;
    if (!data) {
      throw new Error(
        '결제정보 생성에 실패했습니다. 잠시후 다시 시도해주세요.',
      );
    }
    return response?.data?.data?.createPaymentBillingKey;
  };
  // 결제수단 변경 API
  const updatePaymentMethod = async (oldCustomerUid, newCustomerUid) => {
    const response = await axios.post(TARGET_SERVER, {
      query: `
        mutation updatePaymentMethod($input: update_payment_method_input!) {
          updatePaymentMethod(input: $input)
        }
      `,
      variables: {
        input: {
          old_customer_uid: oldCustomerUid,
          new_customer_uid: newCustomerUid,
        },
      },
    });

    if (response?.data?.data?.updatePaymentMethod !== 'success') {
      console.error('결제정보 변경 중 오류 발생');
      throw new Error(
        '결제정보 변경에 실패했습니다. 잠시후 다시 시도해주세요.',
      );
    }
  };
  // IMP.request_pay 호출 API
  const requestPay = async (oldCustomerUid, newCustomerUid) => {
    const kakaoPG = 'kakaopay.CAU1YSE6WS';
    const tossPG = 'tosspayments.bill_im_rakeh6';
    const inputPG = method === 'kakaopay' ? kakaoPG : tossPG;
    return new Promise((resolve, reject) => {
      window.IMP.request_pay(
        {
          // pg: 'tosspayments.iamporttest_4', // 테스트
          pg: inputPG, // 실결제
          pay_method: method,
          customer_uid: newCustomerUid,
          name: '결제수단 변경',
          amount: 0,
          buyer_name: newPaymentInfo.payerName,
          buyer_tel: formatPhoneNumber(newPaymentInfo.payerPhoneNumber),
          m_redirect_url: `https://glucofit.co.kr/payment/method-change?old_customer_uid=${oldCustomerUid}&new_customer_uid=${newCustomerUid}`,
        },
        (response) => {
          if (Object.keys(response).includes('error_msg')) {
            reject(new Error('결제수단 변경을 취소했습니다.'));
          } else {
            resolve(response);
          }
        },
      );
    });
  };

  /* 로직 관련 함수 */
  // 결제정보 변경시 호출, 현재 결제정보를 조회하고 새로운 결제정보를 생성한다.
  const handleChangePaymentMethod = async () => {
    try {
      const currentBillingKey = await getPaymentBillingKey(
        currentPaymentInfo.payerName,
        currentPaymentInfo.payerPhoneNumber,
      );
      const newBillingKey = await createPaymentBillingKey(
        newPaymentInfo.payerName,
        newPaymentInfo.payerPhoneNumber,
      );

      const result = await requestPay(
        currentBillingKey[0].id,
        newBillingKey.id,
      );

      //웹 환경에서만 호출됨. 모바일 환경에서는 m_redirect_url로 인해 결제수단 변경 결과를 받는다.
      await updatePaymentMethod(
        currentBillingKey[0].id,
        Number(result.customer_uid),
      );

      alert('결제정보가 변경되었습니다.');
      navigate('/');
    } catch (error) {
      console.error('결제정보 변경 중 오류 발생', error);
      alert(error.message);
    }
  };

  // 현재 결제정보 조회시 호출, 현재 결제정보를 조회한다.
  const handleSearchCurrentPayment = async () => {
    try {
      const { payerName, payerPhoneNumber } = currentPaymentInfo;
      const data = await getPaymentBillingKey(payerName, payerPhoneNumber);
      if (!data || data.length === 0) {
        return alert('결제정보를 찾을 수 없습니다.');
      }
      setIsFound(true);
      setNewPaymentInfo({
        payerName: data[0].name,
        payerPhoneNumber: data[0].phone_number,
      });
    } catch (error) {
      console.error('결제정보 조회 중 오류 발생', error);
      alert(error.message);
    }
  };

  /* UI 관련 함수 */
  // input 입력시 호출, 현재 결제정보의 input값을 변경한다.
  const handleInputCurrentPayment = (e) => {
    let { name, value } = e.target;
    if (name === 'payerPhoneNumber') {
      value = value.replace(/[^\d]/g, '');
      if (value.length > 11) {
        return;
      }
    }
    setCurrentPayementInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  // input 입력시 호출, 새로운 결제정보의 input값을 변경한다.
  const handleInputNewPayment = (e) => {
    let { name, value } = e.target;
    if (name === 'payerPhoneNumber') {
      value = value.replace(/[^\d]/g, '');
      if (value.length > 11) {
        return;
      }
    }
    setNewPaymentInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  // 전화번호 포맷팅 함수
  const formatPhoneNumber = (phoneNumber) => {
    const phoneNumberRegex = /^(\d{3})(\d{4})(\d{4})$/;
    const matches = phoneNumber.match(phoneNumberRegex);
    if (matches) {
      return `${matches[1]}-${matches[2]}-${matches[3]}`;
    }
    return phoneNumber;
  };

  useEffect(() => {
    // 모바일 환경에서만 m_redirect_url로 인해 결제수단 변경 결과를 받는다.
    if (isMethodChangeSuccess === 'true') {
      const process = async (oldCustomerUid, newCustomerUid) => {
        await updatePaymentMethod(oldCustomerUid, newCustomerUid);
        alert('결제정보가 변경되었습니다.');
        navigate('/');
      };
      process(
        Number.parseInt(searchParams.get('old_customer_uid')),
        Number.parseInt(searchParams.get('new_customer_uid')),
      );
    }

    if (isMethodChangeSuccess === 'false') {
      alert('결제 수단 변경에 실패했습니다. 다시 시도해주세요.');
    }
  }, [isMethodChangeSuccess]);

  return (
    <div className="payment-method-change-container">
      <Nav />
      <div className="content">
        <div className="title strong">결제수단 변경</div>
        <div className="content-box">
          <div className="subtitle">현재 결제정보</div>
          <div className="input-container">
            <div className="input-box">
              <label className="input-title">이름</label>
              <input
                type="text"
                placeholder="이름"
                name="payerName"
                value={currentPaymentInfo.payerName}
                onChange={handleInputCurrentPayment}
                disabled={isFound}
              />
            </div>
            <div className="input-box">
              <label className="input-title">전화번호</label>
              <input
                type="text"
                placeholder="전화번호"
                name="payerPhoneNumber"
                value={formatPhoneNumber(currentPaymentInfo.payerPhoneNumber)}
                onChange={handleInputCurrentPayment}
                disabled={isFound}
              />
            </div>
            {!isFound && (
              <button className="button" onClick={handleSearchCurrentPayment}>
                결제정보 조회하기
              </button>
            )}
          </div>
        </div>
        {isFound && (
          <div className="content-box">
            <div className="success">
              결제정보를 성공적으로 조회했습니다. 새로운 결제정보를
              입력해주세요.
            </div>
            <div className="subtitle">새로운 결제정보</div>
            <div className="input-container">
              <div className="input-box">
                <label className="input-title">이름</label>
                <input
                  type="text"
                  placeholder="이름"
                  name="payerName"
                  value={newPaymentInfo.payerName}
                  onChange={handleInputNewPayment}
                />
              </div>
              <div className="input-box">
                <label className="input-title">전화번호</label>
                <input
                  type="text"
                  placeholder="전화번호"
                  name="payerPhoneNumber"
                  value={formatPhoneNumber(newPaymentInfo.payerPhoneNumber)}
                  onChange={handleInputNewPayment}
                />
              </div>

              <div className="membership-payment-method">
                <div
                  className={`payment-method ${
                    method === 'card' ? 'selected' : ''
                  }`}
                  id="ic-card"
                  onClick={() => setMethod('card')}
                >
                  <img className="payment-ic-card" src={CardImg} alt="img" />
                  <div>신용/체크카드</div>
                  {method === 'card' ? (
                    <img src={Checked} alt="" className="payment-checkbox" />
                  ) : (
                    <img src={UnChecked} alt="" className="payment-checkbox" />
                  )}
                </div>
                <div
                  className={`payment-method ${
                    method === 'kakaopay' ? 'selected' : ''
                  }`}
                  id="kakaopay"
                  onClick={() => setMethod('kakaopay')}
                >
                  <img className="payment-ic-kakao" src={KakaoImg} alt="img" />
                  <div>카카오페이</div>
                  {method === 'kakaopay' ? (
                    <img src={Checked} alt="" className="payment-checkbox" />
                  ) : (
                    <img src={UnChecked} alt="" className="payment-checkbox" />
                  )}
                </div>
              </div>
              <button className="button" onClick={handleChangePaymentMethod}>
                결제수단 변경하기
              </button>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default PaymentMethodChange;
