import React from 'react';
import spikeGraph from '../../../assets/images/img_spikeGraph.png';
import { logEvent } from './Amplitude';

function Qna(props) {
  return (
    <div>
      <div className="container3">
        <div>
          <h2 className="text-title32">QnA</h2>
          <h2 className="text-title32-1">QnA</h2>
          <div className="question-container">
            <div className="text-question">
              Q. 혈당을 조절하면 건강한 다이어트가 가능한가요?
            </div>
            <div className="text-question-1">
              Q. 혈당을 조절하면 <br />
              건강한 다이어트가 가능한가요?
            </div>
          </div>
          <div className="answer-container">
            <div className="text-answer">
              A. '혈당 스파이크'는 살이 찌는 핵심 원인입니다.
              <br />
              글루코핏을 통해 실시간으로 살찌는 순간을 눈으로 확인해 보세요.
            </div>
            <div className="text-answer-1">
              A. '혈당 스파이크'는 살이 찌는 핵심 원인입니다. 글루코핏을 통해
              실시간으로 살찌는 순간을 눈으로 확인해 보세요.
            </div>
            <div className="spike-img">
              <img src={spikeGraph} alt="img" />
            </div>
            <div className="text-subtitle16">
              식습관 & 생활습관에 <span className="green">사소한 변화</span>를
              주어 혈당을 건강 범위 내로 유지하면 <br />
              <span className="green">자연스럽게 살을 뺄</span> 수 있어요
            </div>
            <div className="text-subtitle16-1">
              식습관 & 생활습관에 <span className="green">사소한 변화</span>를
              주어 <br /> 혈당을 건강 범위 내로 유지하면 <br />
              <span className="green">자연스럽게 살을 뺄</span> 수 있어요
            </div>
            <button
              className="detail-button"
              onClick={() => {
                // logEvent('seedetails_click', 10204);
                window.location.href =
                  'https://glucofit.framer.website/blog/%EB%8B%A4%EC%9D%B4%EC%96%B4%ED%8A%B8%EC%9D%98-%EC%B5%9C%EB%8C%80-%EC%A0%81-%ED%98%88%EB%8B%B9-%EC%8A%A4%ED%8C%8C%EC%9D%B4%ED%81%AC';
                window.gtag('event', 'conversion', {
                  send_to: 'AW-10992256925/0WURCMrzqKwYEJ2Pwvko',
                });
              }}
            >
              자세한 원리 보러가기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Qna;
