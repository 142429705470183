import { useState } from 'react';
import '../CSS/MembershipSelector.scss';
import Carousel from 'pages/MembershipInfo/Components/Carousel';
import { useMembershipCarousel } from 'hooks/membsershipinfo/useMembershipCarousel';
import { getPricePerDay, getPricePerMonth } from 'utils/utils';

export default function MembershipSelector({
  PRODUCT_TYPE,
  products,
  selectedProduct,
  setSelectedProductById,
  setSelectedProductByType,
  onNavigate,
}) {
  const { setActiveCarousel, activeThemeColor } = useMembershipCarousel();
  const [activeTap, setActiveTap] = useState(0);

  const handleTap = (index) => {
    // 탭의 디폴트 상품 선택
    setSelectedProductByType(
      index === 0
        ? PRODUCT_TYPE.YEAR_MEMBERSHIP
        : PRODUCT_TYPE.MONTH_MEMBERSHIP,
    );
    // 탭 변경
    setActiveTap(index);
  };

  const handleMembership = (id) => {
    setSelectedProductById(id);
  };

  if (!selectedProduct) return null;
  return (
    <div className="selector-container">
      {selectedProduct.discount == 80000 ? (
        <div className="title">
          1개월 멤버십 사용자를 위한{' '}
          <div className="strong">추가구매 8만원 할인</div>
        </div>
      ) : (
        <div className="title">
          가장 합리적인 <div className="strong">글루코핏 멤버십</div>
        </div>
      )}

      <div className="membership-selector">
        <input
          type="radio"
          id="left"
          name="membership-selector"
          defaultChecked={activeTap === 0}
        />
        <label htmlFor="left" onClick={() => handleTap(0)}>
          1년 멤버십
        </label>
        <input
          type="radio"
          id="right"
          name="membership-selector"
          defaultChecked={activeTap === 1}
        />
        <label htmlFor="right" onClick={() => handleTap(1)}>
          (Trial) 1개월 멤버십
        </label>
      </div>
      <div
        className="membership-content"
        style={{ backgroundColor: activeThemeColor }}
      >
        <div className="left-content">
          <Carousel setActive={setActiveCarousel} />
        </div>
        <div className="right-content">
          {activeTap === 0 ? (
            <>
              <div className="price">
                <span className="strong">
                  월&nbsp;
                  {getPricePerMonth(
                    selectedProduct.price,
                    selectedProduct.discount,
                  ).toLocaleString()}
                  원
                </span>
                <div>
                  하루&nbsp;
                  {getPricePerDay(
                    selectedProduct.price,
                    selectedProduct.discount,
                  ).toLocaleString()}
                  원
                </div>
              </div>
              <div className="text">
                건강 챙기는 분들을 위해 <br />
                <span className="strong">12개월 무이자 할부 이벤트</span>를
                시작합니다!
              </div>
              <div className="membership-products">
                {products
                  .filter((m) => m.type === PRODUCT_TYPE.YEAR_MEMBERSHIP)
                  .map((m) => (
                    <div
                      key={m.id}
                      className={`product ${m.theme} ${
                        selectedProduct.id === m.id && 'selected'
                      }`}
                      onClick={() => handleMembership(m.id)}
                    >
                      <div className="product-left">
                        <div className={`product-badge strong ${m.theme}`}>
                          {m.badge}
                        </div>
                        <div className="product-sensor strong">
                          센서 {m.sensorCount}개
                        </div>
                      </div>
                      <div className="product-center">
                        {m.event && (
                          <div className="product-event">{m.event}</div>
                        )}
                        <div className="product-content">{m.content}</div>
                        <div className="product-description">
                          {m.description}
                        </div>
                      </div>
                      <div className="product-select-button" />
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <>
              <div className="price">
                <span className="strong">
                  {(
                    selectedProduct.price - selectedProduct.discount
                  ).toLocaleString()}
                  원
                </span>
                <div>(1개월)</div>
              </div>
              <div className="membership-products">
                {products
                  .filter((m) => m.type === PRODUCT_TYPE.MONTH_MEMBERSHIP)
                  .map((m) => (
                    <div
                      key={m.id}
                      className={`product ${m.theme} ${
                        selectedProduct.id === m.id && 'selected'
                      }`}
                      onClick={() => handleMembership(m.id)}
                    >
                      <div className="product-left">
                        <div className={`product-badge strong ${m.theme}`}>
                          {m.badge}
                        </div>
                        <div className="product-sensor strong">
                          센서 {m.sensorCount}개
                        </div>
                      </div>
                      <div className="product-center">
                        {m.event && (
                          <div className="product-event">{m.event}</div>
                        )}
                        <div className="product-content">{m.content}</div>
                        <div className="product-description">
                          {m.description}
                        </div>
                      </div>
                      <div className="product-select-button" />
                    </div>
                  ))}
              </div>
            </>
          )}
          <button className="next-button" onClick={onNavigate}>
            다음
          </button>
        </div>
      </div>
    </div>
  );
}
