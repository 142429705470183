import React, { useEffect, useState } from 'react';
import StartBadge from '../../assets/icons/ic-start-badge.svg';
import HotBadge from '../../assets/icons/ic-hot-badge.svg';
import TotalCareBadge from '../../assets/icons/ic-total-care-badge.svg';

export const useProduct = () => {
  const PRODUCT_TYPE = {
    YEAR_MEMBERSHIP: '1년',
    MONTH_MEMBERSHIP: '1개월',
    SENSOR_MEMBERSHIP: '멤버십 센서',
    WELSTORY: '웰스토리',
  };

  const [products, setProducts] = useState([
    {
      id: 28,
      type: PRODUCT_TYPE.YEAR_MEMBERSHIP,
      name: '스타터 패키지',
      description: '센서 착용이 처음이라면?',
      badge: (
        <>
          <img src={StartBadge} alt="스타드 패키지" />
          Start
        </>
      ),
      content: <span className="strong">글루코핏 스타터 패키지</span>,
      price: 651600,
      discount: 0,
      sensorCount: 6,
      eventSensorCount: 0,
      deliveryMessage: (
        <>
          센서는&nbsp;
          <span className="strong">매달 2개씩 3개월간&nbsp;</span>
          발송됩니다.
        </>
      ),
      termMessage: '센서는 매달 2개씩 3개월간 배송될 것을 확인했습니다.',
      theme: 'blue',
    },
    {
      id: 29,
      type: PRODUCT_TYPE.YEAR_MEMBERSHIP,
      name: 'HOT 패키지',
      description: '정기적으로 관리하고 싶은 분께 추천',
      badge: (
        <>
          <img src={HotBadge} alt="HOT 패키지" />
          HOT
        </>
      ),
      content: (
        <>
          1년간 <span className="strong">매달 1개씩</span> 배송 받기
        </>
      ),
      event: '센서 1개 추가 증정! (10만원 상당)',
      price: 1188000,
      discount: 0,
      sensorCount: 12,
      eventSensorCount: 1,
      deliveryMessage: (
        <>
          센서는&nbsp;
          <span className="strong">
            첫 달 이벤트 센서를 포함한 2개 발송, 그 후 매 달 1개씩&nbsp;
          </span>
          발송됩니다.
        </>
      ),
      termMessage:
        '센서는 첫 달 2개, 그 후 매달 1개씩 배송될 것을 확인했습니다.',
      theme: 'red',
    },
    {
      id: 30,
      type: PRODUCT_TYPE.YEAR_MEMBERSHIP,
      name: 'TOTAL CARE 패키지',
      description: '완벽한 습관 형성',
      content: (
        <>
          1년간 <span className="strong">매달 2개씩</span> 배송 받기
        </>
      ),
      badge: (
        <>
          <img src={TotalCareBadge} alt="토탈 케어 패키지" />
          <div>TOTAL CARE</div>
        </>
      ),
      event: '센서 2개 추가 증정! (20만원 상당)',
      price: 2388000,
      discount: 0,
      sensorCount: 24,
      eventSensorCount: 2,
      deliveryMessage: (
        <>
          센서는&nbsp;
          <span className="strong">매달 2개씩 13개월간&nbsp;</span>
          발송됩니다.
        </>
      ),
      termMessage: '센서는 매달 2개씩 13개월간 배송될 것을 확인했습니다.',
      theme: 'blue',
    },
    {
      id: 18,
      type: PRODUCT_TYPE.MONTH_MEMBERSHIP,
      name: '1개월 패키지',
      description: '센서 착용이 처음이라면?',
      content: <span className="strong">글루코핏 1개월 패키지</span>,
      badge: (
        <>
          <img src={StartBadge} alt="1개월 패키지" />
          Start
        </>
      ),
      event: null,
      price: 276000,
      discount: 0,
      sensorCount: 2,
      eventSensorCount: 0,
      deliveryMessage: null,
      termMessage: '센서는 2개 배송될 것을 확인했습니다.',
      theme: 'blue',
    },
    {
      id: 31,
      type: PRODUCT_TYPE.SENSOR_MEMBERSHIP,
      name: '글루코핏_센서구매1_일반결제_0',
      description: null,
      content: '리브레2 연속혈당측정기',
      event: null,
      price: 85000,
      discount: 0,
      sensorCount: 1,
      eventSensorCount: null,
      deliveryMessage: '일괄 배송 예정',
      termMessage: null,
      theme: null,
    },
    {
      id: 32,
      type: PRODUCT_TYPE.SENSOR_MEMBERSHIP,
      name: '글루코핏_센서구매2_일반결제_0',
      description: null,
      content: '리브레2 연속혈당측정기',
      event: null,
      price: 163800,
      discount: 0,
      sensorCount: 2,
      eventSensorCount: null,
      deliveryMessage: '일괄 배송 예정',
      termMessage: null,
      theme: null,
    },
    {
      id: 33,
      type: PRODUCT_TYPE.SENSOR_MEMBERSHIP,
      name: '글루코핏_센서구매6_일반결제_0',
      description: null,
      content: '리브레2 연속혈당측정기',
      event: null,
      price: 479400,
      discount: 0,
      sensorCount: 6,
      eventSensorCount: null,
      deliveryMessage: '일괄 배송 예정',
      termMessage: null,
      theme: null,
    },
    {
      id: 34,
      type: PRODUCT_TYPE.SENSOR_MEMBERSHIP,
      name: '글루코핏_6개월멤버십1_일반결제_180',
      description: null,
      content: '연속혈당측정기 (6개월 멤버십)',
      event: null,
      price: 120000,
      discount: 0,
      sensorCount: 1,
      eventSensorCount: null,
      deliveryMessage: '일괄 배송 예정',
      termMessage: null,
      theme: null,
    },
    {
      id: 35,
      type: PRODUCT_TYPE.WELSTORY,
      name: '글루코핏_2주멤버십1_일반결제_20',
      description: null,
      content: (
        <>
          글루코핏 2주 멤버십 <br />+ 연속혈당측정기 1개
        </>
      ),
      event: null,
      price: 140000,
      discount: 40500,
      sensorCount: 1,
      eventSensorCount: null,
      deliveryMessage: '일괄 배송 예정',
      termMessage: null,
      theme: null,
    },
  ]);

  const [selectedProduct, setSelectedProduct] = useState(null);

  const findProductById = (id) => {
    id = typeof id === 'string' ? Number(id) : id;
    const findProduct = products.find((m) => m.id === id);
    if (!findProduct) {
      throw new Error('멤버십 상품을 찾을 수 없습니다.');
    }
    return findProduct;
  };

  const findProductByType = (type) => {
    const findProduct = products.find((m) => m.type === type);
    if (!findProduct) {
      throw new Error('멤버십 상품을 찾을 수 없습니다.');
    }
    return findProduct;
  };

  const setSelectedProductById = (id) => {
    setSelectedProduct(findProductById(id || products[0].id));
  };

  const setSelectedProductByType = (type) => {
    setSelectedProduct(findProductByType(type || products[0].type));
  };

  const setDiscount = (apply) => {
    setProducts((prev) => {
      return prev.map((m) => {
        return {
          ...m,
          discount: apply ? apply(m.price) : m.discount,
        };
      });
    });
  };

  useEffect(() => {
    if (selectedProduct) {
      setSelectedProductById(selectedProduct.id);
    }
  }, [products]);

  return {
    products,
    selectedProduct,
    setDiscount,
    findProductById,
    setSelectedProductById,
    setSelectedProductByType,
    PRODUCT_TYPE,
  };
};
