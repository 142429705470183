import React from 'react';
import weight from '../../../assets/images/img_weight.png';
import food from '../../../assets/images/img_bg1.png';
import walk from '../../../assets/images/img_bg2.png';
import message from '../../../assets/images/img_message.png';
import '../CSS/Recommand.scss';

function Recommand(props) {
  return (
    <div>
      <div className="recommand-container">
        <div className="recommand-title">이런 분들께 글루코핏을 추천드려요</div>
        <div className="target-container">
          <div className="target-image-container">
            <img src={food} alt="img" />
            <div className="target-title">
              내가 살찌는 음식과, 잘 맞는 음식을 <br />
              찾아보고 싶은 분
            </div>
          </div>
          <div className="target-image-container">
            <img src={walk} alt="img" />
            <div className="target-title">
              다이어트를 넘어서 <br />
              진짜 건강을 되찾고 싶은 분
            </div>
          </div>
          <div className="target-image-container">
            <img src={weight} alt="img" />
            <div className="target-title">
              더 이상은 요요 없는
              <br />
              지속 가능한 다이어트를 하고 싶은 분
            </div>
          </div>
        </div>
      </div>
      <div className="message-image-container">
        <img src={message} alt="img" />
        <div className="message-title">
          일시적인 다이어트를 넘어
          <br />
          평생 가는 살 안 찌는 습관 만들기까지
        </div>
      </div>
    </div>
  );
}

export default Recommand;
