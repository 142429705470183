// FloatingButton.js
import React from 'react';
import styled from 'styled-components';
import { palette } from 'pages/AnyfitMall/theme';

const FloatingButtonStyled = styled.button`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${palette.AnyBlue};
  color: white;
  border: none;
  border-radius: 12px;
  padding: 1rem 2rem;
  min-width: 280px;

  font-size: 1rem;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 1000;
  width: 90%;
`;

const AnyfitBackButton = ({ onClick, children }) => (
  <FloatingButtonStyled onClick={onClick}>{children}</FloatingButtonStyled>
);

export default AnyfitBackButton;
