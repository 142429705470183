import React from 'react';
import Nav from 'components/Nav/Nav';
import Footer from 'components/Footer/Footer';

function ErrorPage(props) {
  const errorContainerStyle = {
    height: '800px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: '100px',
  };
  const errorMessageStyle = {
    fontWeight: 700,
    fontSize: '18px',
  };
  return (
    <div>
      <Nav />
      <div className="error-container" style={errorContainerStyle}>
        <div className="error-message" style={errorMessageStyle}>
          접근 권한이 없습니다.
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ErrorPage;
