import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './styles/GlobalStyle';
import theme from './styles/theme';
import Router from './Router';
import { DiscountProvider } from 'contexts/DiscountContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <DiscountProvider>
      <GlobalStyle />
      <Router />
    </DiscountProvider>
  </ThemeProvider>,
);
