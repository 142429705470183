import { useNavigate } from 'react-router-dom';
import '../CSS/SpecialEvent.scss';
import Phone from '../../../assets/images/ver3.0/img-start-phone.png';

function SpecialEvent() {
  const navigate = useNavigate();

  const handleApplyButtonClicked = () => {
    navigate('/welstory/payment');
  };
  return (
    <div className="welstory-start-container">
      <div className="welstory-start-title-eng strong">Special Event</div>
      <div className="welstory-start-title">
        지금 <span className="strong">글루코핏</span>을 <br />
        <span className="strong">경험</span>해보세요
      </div>
      <div className="welstory-start-content-container">
        <div className="welstory-start-content first">
          <div className="welstory-start-content-img-phone">
            <img src={Phone} alt="글루코핏 1년 멤버십, 센서4개 월 54000원" />
          </div>
          <div className="welstory-start-content-info">
            <div className="welstory-start-best-text">
              <span className="welstory-start-best-icon strong">특별</span>
              글루코핏 2주 체험 패키지
            </div>
            <div className="welstory-start-month-price">
              <span className="strong">99,500원</span>
            </div>
            <button
              className="welstory-start-apply-button strong"
              onClick={handleApplyButtonClicked}
            >
              신청하기
            </button>
            <div className="welstory-start-content-description">
              <div>
                <div className="strong">포함 상품</div>
                연속혈당측정기 1개
                <br />
                글루코핏 2주 멤버십
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpecialEvent;
