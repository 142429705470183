import React from 'react';
import '../CSS/Steps.scss';
import Step1Left from '../../../assets/images/ver3.0/img-step-1-left.png';
import Step1Right from '../../../assets/images/ver3.0/img-step-1-right.png';
import Step2Left from '../../../assets/images/ver3.0/img-step-2-left.png';
import Step2Right from '../../../assets/images/ver3.0/img-step-2-right.png';
import Step3 from '../../../assets/images/ver3.0/img-step-3.png';
// import video from '../../../assets/images/img_video.png';
// import video2 from '../../../assets/images/img_video2.png';
// import bniee from '../../../assets/images/img_bniee.png';
// import appScreen from '../../../assets/images/img_appScreen.png';
// import no1 from '../../../assets/icons/ic_number1.svg';
// import no2 from '../../../assets/icons/ic_number2.svg';
// import no3 from '../../../assets/icons/ic_number3.svg';
// import apple from '../../../assets/icons/ic_apple.svg';
// import google from '../../../assets/icons/ic_google.svg';
// import question from '../../../assets/images/img_question.png';

function Steps() {
  const renderData = [
    {
      title: (
        <span>
          연속혈당측정기를 <br /> 부착해요
        </span>
      ),
      titleMobile: <span>연속혈당측정기를 부착해요</span>,
      subtitle: (
        <span>
          채혈하지 않아도 실시간으로 혈당을 측정할 수 있는 <br />
          동전 크기의 작은 기기를 팔에 부착해요. <br />
          남녀노소 누구나 부착할만큼 전혀 아프지 않아요!
        </span>
      ),
      subtitleMobile: (
        <span>
          채혈하지 않아도 실시간으로 혈당을 <br />
          측정할 수 있는 동전 크기의 작은 기기를 팔에 부<br />
          착해요. 남녀노소 누구나 부착할만큼 전혀 <br />
          아프지 않아요!
        </span>
      ),
      img: [Step1Left, Step1Right],
    },
    {
      title: (
        <span>
          음식 반응성 테스트를 <br /> 진행해요
        </span>
      ),
      titleMobile: <span>음식 반응성 테스트를 진행해요</span>,
      subtitle: (
        <span>
          여러가지 음식을 다양하게 먹어보고 글루코핏 앱으로 <br />
          내 몸의 반응을 측정해요. <br />
          데이터 해석이 어렵다면 전문가의 도움을 받아보세요!
        </span>
      ),
      subtitleMobile: (
        <span>
          여러가지 음식을 다양하게 먹어보고 <br />
          글루코핏 앱으로 내 몸의 반응을 측정해요. <br />
          데이터 해석이 어렵다면 <br />
          전문가의 도움을 받아보세요!
        </span>
      ),
      img: [Step2Left, Step2Right],
    },
    {
      title: (
        <span>
          궁금한 건 의사 코치에게 <br /> 질문해요
        </span>
      ),
      titleMobile: <span>궁금한 건 의사 코치에게 질문해요</span>,
      subtitle: (
        <span>
          내 혈당이 왜 오른 건지, 혈당 점수를 <br />
          어떻게 해석해야 할지 모르겠다면 <br />
          의사 출신 코치님의 전문적인 1:1 도움을 받아보세요.
        </span>
      ),
      subtitleMobile: (
        <span>
          내 혈당이 왜 오른 건지, <br />
          혈당 점수를 어떻게 해석해야 할지 모르겠다면 <br />
          의사 출신 코치님의 전문적인 <br />
          1:1 도움을 받아보세요.
        </span>
      ),
      img: [Step3],
    },
  ];
  return (
    <div>
      <div className="container4">
        <div className="step-title-eng">
          <span className="strong">3 steps</span>
        </div>
        <div className="step-title web">
          <span className="strong">3단계면 끝나는</span> 혈당 케어
        </div>
        <div className="step-title mobile">
          <span className="strong">3단계면 끝나는</span> <br /> 혈당 케어
        </div>
        <div className="step-subtitle">
          간편하고 쉽게 혈당을 측정 할 수 있어요
        </div>
        <div className="step-content-container">
          {renderData.map((item, index) => {
            return (
              <div className="step-content" key={index}>
                <div className="step-content-img">
                  {item.img.map((img, index) => (
                    <div key={img}>
                      <img src={img} alt="img" />
                    </div>
                  ))}
                </div>
                <div className="step-content-info">
                  <span className="step-content-number">{index + 1}</span>
                  <div className="step-content-title strong web">
                    {item.title}
                  </div>
                  <div className="step-content-title strong mobile">
                    {item.titleMobile}
                  </div>
                  <div className="step-content-subtitle web">
                    {item.subtitle}
                  </div>
                  <div className="step-content-subtitle mobile">
                    {item.subtitleMobile}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Steps;
