import React, { useState, useEffect } from 'react';
import AiImage from '../../../assets/images/img_Ai.webp';
import AiImage2 from '../../../assets/images/img_Ai_mobile.png';
import sparkle from '../../../assets/images/img_sparkle.png';
import badge from '../../../assets/images/img_badge.png';
import badgeMobile from '../../../assets/images/img_badge_mobile.png';
import SecurityIcon from '../../../assets/icons/ic_security.svg';
// import apple from '../../../assets/icons/ic_apple.svg';
// import google from '../../../assets/icons/ic_google.svg';
import '../CSS/Aimode.scss';

function Aimode(props) {
  return (
    <div className="Aimode-container">
      <div className="Aimode-title">
        <img src={sparkle} alt="img" />
        <div className="Aimode-title">글루코핏 AI모드</div>
      </div>
      <div className="Aimode-subtitle">
        <span>이제 센서 없이도</span>
        <br />내 혈당을 확인할 수 있어요
      </div>
      <div className="Aimode-subtitle2">
        2주간 센서로 측정한 내 혈당 데이터로 학습시킨
        <br />
        초개인화된 글루코핏 AI
      </div>
      <div className="Aimode-badge-container">
        <img src={SecurityIcon} alt="특허출원" />
        특허출원
      </div>
      <div className="Aimode-image-container">
        <img src={AiImage} alt="img" />
      </div>
    </div>
  );
}

export default Aimode;
