import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Layout from 'components/Anyfit/common/Layout';
import styled from 'styled-components';
import { fontSize, palette } from 'pages/AnyfitMall/theme';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from './config';
import { validateToken } from 'pages/AnyfitMall/common';
import { validateStorageToken } from './common';
import OrderCancelButton from 'components/Anyfit/OrderCancelButton';
import AnyfitBackButton from 'components/Anyfit/AnyfitBackButton';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  /* margin-top: 20px; */
  height: 100%;

  // min-width: 360px;
  min-height: 120vh;
  width: 100%;
`;

const NotContent = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  color: ${palette.Gray400};
  margin-top: 3rem;
`;

const Top = styled.div`
  display: flex;
  width: 85%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const Title = styled.div`
  color: #141414;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  font-family: 'Noto Sans KR', sans-serif;
  margin-top: 60px;
`;

// Styled Components
const OrderListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 30px;
  width: 90%;
`;

const OrderCard = styled.div`\
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.8rem;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
`;

const OrderCardUpper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.8rem;
`;

const OrderCardLower = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 0.6rem;
  gap: 0.8rem;
`;

const OrderImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 0.6rem;
`;

const OrderStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
`;

// background-color: ${(props) =>
//   props.status === '배송중' ? '#4caf50' : '#f44336'};

const statusColors = {
  DEFAULT: palette.AnyBlue,
  CANCEL: palette.Gray200,
};

const StatusBadge = styled.div`
  font-size: ${fontSize.big};
  color: white;
  // font-weight: bold;
  font-family: 'Noto Sans KR', sans-serif;
  background-color: ${({ status }) =>
    statusColors[status] || statusColors.DEFAULT};
  border-radius: 16px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 88px;

  display: flex; // 플렉스 박스 사용
  justify-content: center; // 가로 중앙 정렬
  align-items: center; // 세로 중앙 정렬
`;

const OrderDate = styled.div`
  font-size: 0.875rem;
  color: #666;
`;

const OrderDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.2rem;
  font-size: 0.8rem;
  font-weight: bold;
  width: 100%;
`;

const OrderName = styled.div``;

const OrderPrice = styled.div`
  color: #333;
`;

const Guide = styled.div`
  padding-left: 10px;
  width: 100%;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  color: ${palette.Gray600};
  background-color: ${palette.Gray100};
  padding: 1rem;
  border-radius: 12px;
`;

const AnyfitOrderList = () => {
  const fetchOrders = async (token) => {
    const validationResult = await validateStorageToken();
    const response = await fetch(`${API_BASE_URL}/api/getMyOrder`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${validationResult.token}`,
      },
      body: JSON.stringify({ token: token }),
    });

    if (response.ok) {
      const data = await response.json();
      setOrderList(data.orders);
    } else {
      console.error('Failed to fetch products:', response.statusText);
      alert('토큰이 유효하지 않습니다.');
      navigate('/anyfit/error');
    }
  };
  const completeOrder = async (impUid, merchantUid) => {
    try {
      // const validationResult = await validateStorageToken();
      const completeOrderResponse = await fetch(
        `${API_BASE_URL}/api/completeOrderPayUser`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // Authorization: `Bearer ${validationResult.token}`,
          },
          body: JSON.stringify({
            orderId: merchantUid,
            impUid: impUid,
          }),
        },
      );

      alert('주문이 완료되었습니다.');
      console.log('completeOrderResponse...', completeOrderResponse);

      const validationResult = await validateStorageToken();
      if (validationResult.result) {
        fetchOrders(validationResult.token);
        setToken(validationResult.token);
        return;
      } else {
        alert('토큰이 유효하지 않습니다. 애니핏 플러스에서 재접속해주세요');
        navigate('/anyfit/error');
      }

      // 주문 완료 후 페이지 새로고침
      // window.location.reload();
    } catch (error) {
      alert('주문 완료에 실패했습니다.');
      throw new Error(error);
    }
  };

  useEffect(() => {
    const initialize = async () => {
      const validationResult = await validateStorageToken();
      if (validationResult.result) {
        fetchOrders(validationResult.token);
        setToken(validationResult.token);
        return;
      } else {
        alert('토큰이 유효하지 않습니다.');
        navigate('/anyfit/error');
      }
    };

    const parseUrl = async () => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const impUid = urlParams.get('imp_uid');
      const merchantUid = urlParams.get('merchant_uid');
      const impSuccess = urlParams.get('imp_success');
      const errorMsg = urlParams.get('error_msg');
      // const selectedPrice = localStorage.getItem('selectedPrice');
      // const savedURL = localStorage.getItem('savedURL');

      if (errorMsg != null) {
        // 결제 실패 케이스
        alert(errorMsg);
      } else if (impSuccess == 'true') {
        console.log('queryString...' + queryString);
        // alert('queryString' + queryString);
        // 결제 성공 케이스
        // complete_input = {
        //   imp_uid: paramValue,
        //   merchant_uid: paramValue2,
        // };
        // completeOrder();
        // const modifiedMerchantUid =
        //   process.env.REACT_APP_ENV + 'ss' + merchantUid.toString();
        await completeOrder(impUid, merchantUid.split('ss')[1]);
      }
    };

    initialize();
    parseUrl();
  }, []);

  const navigate = useNavigate();
  const [orderList, setOrderList] = useState([
    {
      status: '주문완료',
      date: '2024-05-01',
      img_url:
        'https://glucofit-image.s3.ap-northeast-2.amazonaws.com/LOG/prodImage.png',
      product_name: 'CGM패키지 (무료)',
      product_price: 81000,
    },
  ]);
  const [token, setToken] = useState('');

  const formatDateTime = (isoString) => {
    if (!isoString) {
      return '';
    }
    // ISO 8601 형식의 문자열을 Date 객체로 파싱
    const date = new Date(isoString);

    // 원하는 형식 지정
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    };

    // 'ko-KR' 로케일에 따라 원하는 형식으로 변환
    const formatter = new Intl.DateTimeFormat('ko-KR', options);
    const formattedDateTime = formatter
      .format(date)
      .replace(/-/g, '.')
      .replace(/,/g, '');

    // 날짜만 반환
    return formattedDateTime.slice(0, 13);
    // 공백을 넣어 시각과 날짜 구분
    return formattedDateTime.replace(/ /, ' ');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Container>
        <Top>
          <Title>구매내역</Title>
        </Top>
        {!orderList || orderList.length === 0 ? (
          <NotContent>
            아직 구매 내역이 <br />
            없습니다.
          </NotContent>
        ) : (
          <OrderListContainer>
            {orderList.map((order, index) => (
              <OrderCard key={index}>
                <OrderCardUpper>
                  <OrderStatus>
                    <StatusBadge status={order.state}>
                      {order.state == 'COMPLETE'
                        ? '주문완료'
                        : order.state == 'USE'
                        ? '배송완료'
                        : order.state == 'CANCEL'
                        ? '주문취소'
                        : '배송중'}
                      {/* //USE 케이스 추가  */}
                    </StatusBadge>
                  </OrderStatus>
                  <OrderDate>
                    {' '}
                    | &nbsp; 구매일 {formatDateTime(order?.created_at)}
                    {/* COMPLETE 일 경우 취소하기 버튼 */}
                  </OrderDate>
                </OrderCardUpper>
                <OrderCardLower>
                  <OrderImage
                    src={order?.product?.img_url}
                    alt={order?.product?.name}
                  />
                  <OrderDetails>
                    <OrderName>주문 ID : {order?.id}</OrderName>
                    <OrderName>{order?.product?.name}</OrderName>
                    <OrderPrice>
                      {order?.product?.price?.toLocaleString()}원
                    </OrderPrice>
                    {order.state == 'COMPLETE' && (
                      <>
                        <OrderCancelButton
                          orderId={order.id}
                          fetchOrders={fetchOrders}
                          token={token}
                        >
                          {' '}
                          주문취소
                        </OrderCancelButton>
                      </>
                    )}
                  </OrderDetails>
                </OrderCardLower>
              </OrderCard>
            ))}
          </OrderListContainer>
        )}
        <Guide>
          <div>
            <p>□ 보험연계 부가서비스로 신청하신 경우</p>
            <ol style={{ marginLeft: '2px' }}>
              <li>환불이 불가합니다.</li>
            </ol>
            <p>□ 직접 슈가핏 서비스를 구매하신 경우</p>
            <ol style={{ marginLeft: '2px' }}>
              <li>택배 출고 전 환불 신청 시 전액 환불됩니다.</li>
              <li>
                배송이 완료된 상품은 상품 수령일로부터 7일 이내, 개봉 전 상품에
                한해 전액 환불이 가능합니다.
                <br />( 단, 반품 시에 발생하는 왕복 배송비(9,000원)는 고객 부담
                -전자상거래 등에서의 소비자보호에 관한 법률 제17조- )
              </li>
              <li>
                아래에 해당한 경우 교환 및 환불이 불가합니다.
                <ul style={{ marginLeft: '2px' }}>
                  <li>
                    - 센서를 부착한 이후(센서 하자일 경우 새 상품으로 1:1
                    교체해드립니다.)
                  </li>
                  <li>- 슈가핏 패키지 수령 후 개봉 및 내/외부 포장 파손 시</li>
                  <li>
                    - 소비자의 고의, 과실로 인한 슈가핏 패키지가 파손 및 훼손된
                    경우
                  </li>
                  <li>
                    - 센서의 구조, 기능을 개조 또는 상품을 변조하여 문제가
                    발생된 경우
                  </li>
                  <li>- 천재지변에 의해 발생한 피해</li>
                </ul>
              </li>
              <li>
                교환 및 반품 환불은 상품 도착 및 상품상태 점검 후 이루어집니다.
                <ul style={{ marginLeft: '2px' }}>
                  <li>
                    - 반품 상품 점검 시 교환/반품 불가 사유에 해당될 경우
                    반품/환불은 불가
                  </li>
                  <li>
                    - 구성품의 패키지 구성품의 일부가 누락되거나 반품 입고가
                    되지 않았을 경우 환불 지연
                  </li>
                  <li>
                    - 지정된 택배사 외 타 업체 이용 시 반품/교환이 지연되거나
                    추가 비용이 발생
                  </li>
                </ul>
              </li>
            </ol>
          </div>
        </Guide>
      </Container>
      <AnyfitBackButton
        onClick={() => {
          window.location.href = 'https://sfplus.page.link/plus';
        }}
      >
        애니핏 플러스로 바로가기
      </AnyfitBackButton>
    </Layout>
  );
};

export default AnyfitOrderList;
