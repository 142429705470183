import axios from 'axios';
import Layout from 'components/Anyfit/common/Layout';
import { palette } from 'pages/AnyfitMall/theme';
import styled from 'styled-components';
import { API_BASE_URL } from './config';
import { useNavigate } from 'react-router-dom';
import { validateToken } from 'pages/AnyfitMall/common';
import React, { useState, useEffect, useRef } from 'react';
import { validateStorageToken } from './common';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 0.5rem;
  // min-width: 360px;
  min-height: 120vh;
  // max-width: 460px;
  margin-top: 60px;
`;

const ContentContainer = styled.div`
  width: 100%;
  position: relative;
  margin-top: 1rem;
`;

const Input = styled.input`
  width: 100%;
  height: 3rem;
  padding: 1rem 1.25rem;
  border: none;
  border-radius: 0px;
  outline: none;
  margin-bottom: 1rem;
  background-color: ${palette.Gray100};
  font-size: 0.875rem;

  &::placeholder {
    color: ${palette.Gray400};
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 23.625rem;
  padding: 1rem 1.25rem;
  border: none;
  background-color: ${palette.Gray100};
  outline: none;
  font-size: 0.875rem;

  &::placeholder {
    color: ${palette.Gray400};
  }
`;

const MaxLength = styled.div`
  position: absolute;
  bottom: 1.25rem;
  right: 1.25rem;
  font-size: 0.875rem;
  color: ${palette.Gray400};
  span {
    color: ${palette.Gray500};
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  height: 3rem;
  border-radius: 0.5rem;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  background-color: ${palette.AnyBlue};
  color: white;
  outline: none;
  border: none;
`;

const AnyfitQuestionAdd = () => {
  const navigate = useNavigate();

  const [data, setData] = useState({
    userId: null,
    title: {
      value: '',
      maxLength: 50,
    },
    content: {
      value: '',
      maxLength: 3000,
    },
  });

  const handleChange = (e) => {
    if (e.target.value.length > data[e.target.name].maxLength) {
      return;
    }
    setData((prev) => ({
      ...prev,
      [e.target.name]: { ...prev[e.target.name], value: e.target.value },
    }));
  };

  const handleSubmit = async () => {
    const validationResult = await validateStorageToken();

    try {
      await axios.post(
        `${API_BASE_URL}/questions`,
        {
          user_id: data.userId,
          title: data.title.value,
          content: data.content.value,
        },
        {
          headers: {
            Authorization: `Bearer ${validationResult.token}`,
          },
        },
      );
      alert(
        '1:1 문의가 등록되었습니다. 답변은 최소 3일 이상 시간이 소요될 수 있습니다.',
      );
      navigate('/anyfit/questionList');
    } catch (error) {
      console.error('Failed to create question:', error);
      alert(error);
    }
  };
  useEffect(() => {
    const validate = async () => {
      const token = localStorage.getItem('token'); // 토큰을 로컬 스토리지에서 가져옵니다.
      const validationResult = await validateToken(token);
      if (!validationResult.result) {
        console.error('Failed to validate token:', validationResult);
        navigate(-1);
      } else {
        setData((prev) => ({ ...prev, userId: validationResult.data.id }));
      }
    };

    validate();
  }, [navigate]);

  return (
    <Layout headerText="문의등록" back>
      <Container>
        <ContentContainer>
          <Input
            placeholder={`제목을 입력해주세요.(최대 ${data.title.maxLength}자)`}
            name="title"
            value={data.title.value}
            onChange={handleChange}
          />
          <Textarea
            placeholder="문의 내용을 입력해주세요."
            name="content"
            value={data.content.value}
            onChange={handleChange}
          />
          <MaxLength>
            <span>{data.content.value.length}</span>/{data.content.maxLength}
          </MaxLength>
        </ContentContainer>
        <SubmitButton
          disabled={
            data.content.value.length === 0 || data.title.value.length === 0
          }
          onClick={handleSubmit}
        >
          등록하기
        </SubmitButton>
      </Container>
    </Layout>
  );
};

export default AnyfitQuestionAdd;
