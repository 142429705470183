import './MobileHeader.scss';
import ArrowLeft from 'assets/icons/ic-arrow-left.svg';

export default function MobileHeader({
  onClickLeft,
  onClickCenter,
  centerText,
}) {
  return (
    <div className="mobile-header-container">
      <div className="left" onClick={onClickLeft}>
        <img src={ArrowLeft} alt="" />
      </div>
      <div className="center" onClick={onClickCenter}>
        {centerText}
      </div>
    </div>
  );
}
