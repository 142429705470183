import './CSS/Welstory.scss';
import Intro from 'pages/Main/Components/Intro';
import About from 'pages/Main/Components/About';
import Introduction from 'pages/Main/Components/Introduction';
import SpecialEvent from './Components/SpecialEvent';
import Banner from 'pages/WelStory/Components/Banner';
import Footer from 'components/Footer/Footer';

function Welstory() {
  return (
    <div>
      <Banner />
      <Intro />
      <About />
      <Introduction />
      <SpecialEvent />
      <Footer />
    </div>
  );
}

export default Welstory;
