import './RewardPage.scss';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Nav from 'components/Nav/Nav';
import Footer from 'components/Footer/Footer';
import axios from 'axios';
import AddressForm from '../Payment/AddressForm';
import TagManager from 'react-gtm-module';
import radioGreen from '../../assets/images/img_radio_green.png';
import AddressFormReward2Weeks from 'pages/Payment/AddressFormReward2Weeks';
import { REAL_SERVER } from 'config';

const Reward2WeeksPage = () => {
  let muid = '';
  let impUid = '';
  let cuid = '';
  const [buyerName, setBuyerName] = useState('');
  const [buyerPhoneNumber, setBuyerPhoneNumber] = useState('');
  const [userName, setUserName] = useState('');
  const [userPhoneNumber, setUserPhoneNumber] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [method, setMethod] = useState('');
  const [selectedPrice, setSelectedPrice] =
    useState('친구초대리워드_NORMAL_15');
  const [selectedAddress, setSelectedAddress] = useState({
    postcode: '',
    address: '',
  });
  const [detailAddress, setDetailAddress] = useState('');
  const location = useLocation();
  const phoneNumberInputRef = useRef(null);
  const [nameInvalid, setNameInvalid] = useState(false);
  const [numberInvalid, setNumberInvalid] = useState(false);
  const [addressInvalid, setAddressInvalid] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [fullNumber, setFullNumber] = useState(false);

  const input = {
    payer_phone_number: buyerPhoneNumber,
    payer_name: buyerName,
    recipient_phone_number: userPhoneNumber,
    recipient_name: userName,
    address: selectedAddress?.address + ' ' + detailAddress,
    postal_code: selectedAddress?.postcode,
    amount: 0,
    reward_name: '글루코핏_리워드2주_일반결제_15',
  };

  const tagManagerArgs = {
    gtmId: 'GTM-57FDQMS',
  };

  useEffect(() => {
    const storedSelectedPrice = localStorage.getItem('selectedPrice');
    const selectedPriceFromState = location.state?.selectedPrice;
    setSelectedPrice(selectedPriceFromState ?? storedSelectedPrice);
  }, [location.state]);

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    const storedSelectedPrice = localStorage.getItem('selectedPrice');
    const storedBuyerName = localStorage.getItem('buyerName');
    const storedBuyerPhoneNumber = localStorage.getItem('buyerPhoneNumber');
    const storedPostCode = localStorage.getItem('postcode');
    const storedAddress = localStorage.getItem('address');

    setSelectedPrice(storedSelectedPrice ?? selectedPrice);
    setBuyerName(storedBuyerName ?? buyerName);
    setBuyerPhoneNumber(storedBuyerPhoneNumber ?? buyerPhoneNumber);
    setSelectedAddress({ postcode: storedPostCode, address: storedAddress });

    if (storedPostCode && storedAddress) {
      document.getElementById('sample6_detailAddress').click();
    } else {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (
      buyerName !== '' &&
      buyerPhoneNumber !== '' &&
      buyerPhoneNumber.length === 13 &&
      selectedAddress.address !== null
    ) {
      setIsButtonClicked(true);
    } else {
      setIsButtonClicked(false);
    }
  }, [buyerName, buyerPhoneNumber, method, selectedAddress.postcode]);

  const handleAddressSelect = (address) => {
    setSelectedAddress(address);
  };

  const handlePhoneNumberChange = (e, type) => {
    const formattedPhoneNumber = e.target.value.replace(/[^\d]/g, '');
    const validPhoneNumber = formatPhoneNumber(formattedPhoneNumber);
    const inputElement = e.target;
    let cursorPosition = inputElement.selectionStart;

    if (formattedPhoneNumber.length <= 11) {
      if (type === 'user') {
        localStorage.setItem('userPhoneNumber', validPhoneNumber);
        setUserPhoneNumber(localStorage.getItem('userPhoneNumber'));
      } else {
        localStorage.setItem('buyerPhoneNumber', validPhoneNumber);
        setBuyerPhoneNumber(localStorage.getItem('buyerPhoneNumber'));
      }
      if (formattedPhoneNumber.length === 11) {
        setFullNumber(true);
      }
      if (fullNumber && formattedPhoneNumber.length === 10) {
        setFullNumber(false);
        inputElement.value = validPhoneNumber;
        inputElement.setSelectionRange(cursorPosition, cursorPosition - 1);
      }
    } else {
      const truncatedPhoneNumber = formattedPhoneNumber.slice(0, 11);
      const truncatedValidPhoneNumber = formatPhoneNumber(truncatedPhoneNumber);
      if (type === 'user') {
        localStorage.setItem('userPhoneNumber', truncatedValidPhoneNumber);
        setUserPhoneNumber(localStorage.getItem('userPhoneNumber'));
      } else {
        localStorage.setItem('buyerPhoneNumber', truncatedValidPhoneNumber);
        setBuyerPhoneNumber(localStorage.getItem('buyerPhoneNumber'));
      }
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    const phoneNumberRegex = /^(\d{3})(\d{4})(\d{4})$/;
    const matches = phoneNumber.match(phoneNumberRegex);
    if (matches) {
      return `${matches[1]}-${matches[2]}-${matches[3]}`;
    }
    return phoneNumber;
  };

  const handleNameKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      phoneNumberInputRef.current.focus();
    }
  };

  // const handleCheckboxChange = () => {
  //   setIsChecked(!isChecked);
  // };

  const handlePayment = () => {
    if (buyerName === '') {
      setNameInvalid(true);
    } else {
      setNameInvalid(false);
    }

    if (buyerPhoneNumber === '' || buyerPhoneNumber.length !== 13) {
      setNumberInvalid(true);
    } else {
      setNumberInvalid(false);
    }

    if (
      selectedAddress.address === null ||
      selectedAddress.address === undefined
    ) {
      setAddressInvalid(true);
    } else {
      setAddressInvalid(false);
    }

    if (
      buyerName !== '' &&
      buyerPhoneNumber !== '' &&
      buyerPhoneNumber.length === 13 &&
      selectedAddress.address !== null
    ) {
      createRewardOrder();
    }
  };

  const createRewardOrder = async () => {
    try {
      const response = await axios.post(REAL_SERVER, {
        query: `
          mutation createRewardOrder($input: create_reward_order_input!) {
            createRewardOrder(create_reward_order_input: $input) {
              merchant_uid
            }
          }
        `,
        variables: {
          input: input,
        },
      });
      if (response.request.status === 200) {
        navigate('/successreward');
      } else {
        alert('신청에 실패했습니다. 고객센터로 문의바랍니다.');
      }
    } catch (error) {
      alert('신청에 실패했습니다. 고객센터로 문의바랍니다.');
    }
  };

  return (
    <div>
      {/* <div className='nav-payment'>
        <Nav />
      </div> */}
      <div className="payment-header" />
      <div className="payment-body">
        <div className="payment-left">
          <div>
            <div className="payment-title">친구 초대 이벤트</div>
            <div className="payment-subtitle">결제할 상품</div>
            <div className="price-contents-payment-invite custom-price-content">
              <div className="price-title">
                <div className="price-left">
                  <img className="radio-icon" src={radioGreen} alt="img" />
                  <div className="price-time-modal-payment">
                    센서 1개 + 글루코핏 2주권
                  </div>
                </div>
                <div className="price-right">
                  <div className="price-invite">무료</div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="payment-total-subtitle-top">결제 금액</div>
            <div className="payment-total-container-top">
              <div>
                <div className="payment-total-textContainer">
                  <div>총 상품 금액</div>
                  <div>150,000원</div>
                </div>
                <div className="payment-total-textContainer">
                  <div>할인</div>
                  <div>150,000원</div>
                </div>
              </div>
              <div className="payment-total-price">
                <div>최종 결제 금액</div>
                <div className="row-container-reward">
                  <div className="free-text">무료</div>
                  <div className="green2">150,000원</div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="payment-subtitle">신청자 정보</div>
            <div className="payment-info-container">
              <div className="payment-info">이름(실명)</div>
              <input
                value={localStorage.getItem('buyerName') ?? buyerName}
                ref={inputRef}
                placeholder="어플 승인을 위해 실명으로 입력해 주세요"
                onChange={(e) => {
                  localStorage.setItem('buyerName', e.target.value);
                  setBuyerName(localStorage.getItem('buyerName'));
                }}
                onKeyDown={handleNameKeyDown}
                className={nameInvalid ? 'error' : ''}
              />
              <div className="validation-text">
                {nameInvalid ? '이름을 입력해 주세요' : ''}
              </div>
            </div>
            <div className="payment-info-container">
              <div className="payment-info">휴대폰 번호</div>
              <input
                ref={phoneNumberInputRef}
                value={
                  localStorage.getItem('buyerPhoneNumber') ?? buyerPhoneNumber
                }
                placeholder="휴대폰 번호"
                inputMode="numeric"
                onChange={(e) => handlePhoneNumberChange(e, 'buyer')}
                className={numberInvalid ? 'error' : ''}
              />
              <div className="validation-text">
                {numberInvalid ? '휴대폰 번호를 입력해 주세요' : ''}
              </div>
            </div>
            <div className="payment-info-container">
              <div className="payment-info">제품 배송지</div>
              <AddressFormReward2Weeks
                handleAddressSelect={handleAddressSelect}
                addressInvalid={addressInvalid}
              />
              <input
                type="text"
                id="sample6_detailAddress"
                placeholder="상세주소 (예: 101동 101호)"
                value={detailAddress}
                onChange={(e) => setDetailAddress(e.target.value)} // 추후 여기도 localstorage 넣어야함
                ref={inputRef}
              />
            </div>
            <li className="li-text">해당 상품은 양도가 불가능합니다.</li>
          </div>
        </div>
        <div className="payment-right">
          <div className="payment-total-container-bottom">
            <div className="payment-total-subtitle">결제 금액</div>
            <div>
              <div className="payment-total-textContainer">
                <div>총 상품 금액</div>
                <div>150,000원</div>
              </div>
              <div className="payment-total-textContainer">
                <div>할인</div>
                <div>150,000원</div>
              </div>
            </div>
            <div className="payment-total-price">
              <div>최종 결제 금액</div>
              <div className="row-container-reward">
                <div className="free-text">무료</div>
                <div className="green2">150,000원</div>
              </div>
            </div>
          </div>
          <button
            className={`payment-totalAccept-button ${
              isButtonClicked ? 'clicked' : ''
            }`}
            onClick={handlePayment}
          >
            신청하기
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Reward2WeeksPage;
