import '../Payment/Membership.scss';
import './CSS/SensorOnlyPage.scss';
import { useEffect, useLayoutEffect, useRef } from 'react';
import Footer from 'components/Footer/Footer';
import SensorImg from 'assets/images/ver3.0/img-sensor-count.png';
import useOrder from 'hooks/order/useOrder';
import { useProduct } from 'hooks/product/useProduct';
import useDiscount from 'hooks/price/useDiscount';
import OrderForm from 'pages/Payment/Components/OrderForm';
import PaymentMethodSelector from 'pages/Payment/Components/PaymentMethodSelector';
import CouponForm from 'pages/Payment/Components/CouponForm';
import usePayment from 'hooks/payment/usePayment';
import { useLocation, useNavigate } from 'react-router-dom';

function TwoWeeksPage() {
  const ref = useRef();
  const naviagte = useNavigate();
  const { state } = useLocation();
  const { order, setOrder, handleOrder, validateOrder } = useOrder();
  const {
    PRODUCT_TYPE,
    products,
    selectedProduct,
    setSelectedProductById,
    setDiscount,
  } = useProduct();
  const { createOrder, requestPay, completeOrder } = usePayment();
  const { discount, applyDiscount } = useDiscount();

  const handlePayment = async () => {
    const test = false;
    const mRedirectUrl = test
      ? 'http://192.168.35.5:3000/redirectsensoronly'
      : 'https://glucofit.co.kr/redirectsensoronly';
    if (!validateOrder(['terms1', 'terms2'])) {
      return alert('구매자 정보를 입력해주세요.');
    }
    const merchantUid = await createOrder({
      order: order,
      productId: selectedProduct.id,
      productCount: 1,
      totalPrice: selectedProduct.price - selectedProduct.discount,
      couponNumber: discount?.coupon_number,
    });
    const impUid = await requestPay({
      order: order,
      merchantUid: merchantUid,
      productId: selectedProduct.id,
      productPrice: selectedProduct.price - selectedProduct.discount,
      mRedirectUrl: mRedirectUrl,
      investmentFree: false,
    });
    completeOrder({
      productId: selectedProduct.id,
      impUid: impUid,
      merchantUid: merchantUid,
      redirectPath: '/successreward',
    });
  };

  const handleFindAddress = () => {
    naviagte('/postcode', {
      state: {
        redirectPath: '/2weeks',
        productId: selectedProduct.id,
        order: {
          buyerName: order.buyerName.value,
          buyerPhoneNumber: order.buyerPhoneNumber.value,
          userName: order.userName.value,
          userPhoneNumber: order.userPhoneNumber.value,
          payMethod: order.payMethod.value,
        },
      },
    });
  };

  useLayoutEffect(() => {
    applyDiscount(setDiscount);
    setSelectedProductById(
      state?.productId || products.find((p) => p.id === 34).id,
    );
  }, []);

  if (selectedProduct === null) return null;
  return (
    <div>
      <div className="sensoronly-payment-body">
        <div className="payment-left">
          <div>
            <div className="sensorOnly-subtitle">주문 상품 정보</div>
            <div className="sensorOnly-product-container">
              {products
                .filter((p) => p.id === 34)
                .map((p) => (
                  <div key={p.id}>
                    <input
                      type="radio"
                      name="sensor-only-product"
                      id={p.id}
                      onClick={() => setSelectedProductById(p.id)}
                      checked={selectedProduct.id === p.id}
                      readOnly
                    />
                    <label htmlFor={p.id}>
                      <div className="product-img">
                        <img src={SensorImg} alt="sensor" />
                        {p.sensorCount > 1 && (
                          <div className="count">
                            X<span>{p.sensorCount}</span>
                          </div>
                        )}
                      </div>
                      <div className="product-info">
                        <div className="product-description">
                          {p.content}
                          <span className="strong">
                            &nbsp;{p.sensorCount}개
                          </span>
                          <span className="product-delivery">
                            {p.deliveryMessage}
                          </span>
                        </div>
                        <div className="product-sensor-price strong">
                          {p.price.toLocaleString()}원
                          <span>
                            &nbsp;(1개당
                            {(p.price / p.sensorCount).toLocaleString()}원)
                          </span>
                        </div>
                      </div>
                      <div className="checked" />
                    </label>
                  </div>
                ))}
            </div>
          </div>
          <div>
            <div className="sensorOnly-subtitle">결제 금액</div>
            <div className="payment-total-container-top">
              <div>
                <div className="payment-total-textContainer">
                  <div>총 상품 금액</div>
                  <div>{selectedProduct.price.toLocaleString()}원</div>
                </div>
                {selectedProduct.discount > 0 && (
                  <div className="payment-total-textContainer">
                    <div>할인</div>
                    <div>-{selectedProduct.discount.toLocaleString()}원</div>
                  </div>
                )}
              </div>
              <div className="payment-total-price">
                <div>최종 결제 금액</div>
                <div>
                  <div className="green3">
                    {(
                      selectedProduct.price - selectedProduct.discount
                    ).toLocaleString()}
                    원
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="sensorOnly-subtitle" ref={ref}>
              구매자 정보
            </div>
            <OrderForm
              order={order}
              setOrder={setOrder}
              handleFindAddressClick={handleFindAddress}
              handleOrder={handleOrder}
              theme="second"
            />
            {!discount && (
              <>
                <div className="sensorOnly-subtitle">할인쿠폰</div>
                <CouponForm
                  setDiscount={setDiscount}
                  theme="second"
                  blockList={[
                    'drdinho',
                    'drlee',
                    'hwalmyeong',
                    'hafeteria',
                    'salb',
                    'lare',
                    'reviewmachine',
                    'doctorleetv',
                    'mktv',
                    'coupon0801',
                  ]}
                />
              </>
            )}
            <div className="sensorOnly-subtitle">결제 수단</div>
            <PaymentMethodSelector
              order={order}
              handleOrder={handleOrder}
              interestFreeInfo={false}
            />
          </div>
        </div>
        <div className="payment-right">
          <div className="payment-total-container-bottom">
            <div className="payment-total-subtitle">결제 금액</div>
            <div>
              <div className="payment-total-textContainer">
                <div>총 상품 금액</div>
                <div>{selectedProduct.price.toLocaleString()}원</div>
              </div>
              {selectedProduct.discount > 0 && (
                <div className="payment-total-textContainer">
                  <div>할인</div>
                  <div>-{selectedProduct.discount.toLocaleString()}원</div>
                </div>
              )}
            </div>
            <div className="payment-total-price">
              <div>최종 결제 금액</div>
              <div>
                <div className="green3">
                  {(
                    selectedProduct.price - selectedProduct.discount
                  ).toLocaleString()}
                  원
                </div>
              </div>
            </div>
          </div>
          <button
            className="payment-totalAccept-button strong clicked"
            onClick={handlePayment}
          >
            {(
              selectedProduct.price - selectedProduct.discount
            ).toLocaleString()}
            원 결제하기
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TwoWeeksPage;
