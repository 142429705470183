import React, { useEffect, useRef } from 'react';
import './ReviewSwiper.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import ReviewImg1 from 'assets/images/ver3.0/img-review-1.png';
import ReviewImg2 from 'assets/images/ver3.0/img-review-2.png';
import ReviewImg3 from 'assets/images/ver3.0/img-review-3.png';
import ReviewImg4 from 'assets/images/ver3.0/img-review-4.png';
import ReviewProfileImg1 from 'assets/images/ver3.0/img-review-profile-1.png';
import ReviewProfileImg2 from 'assets/images/ver3.0/img-review-profile-2.png';
import ReviewProfileImg3 from 'assets/images/ver3.0/img-review-profile-3.png';
import ReviewProfileImg4 from 'assets/images/ver3.0/img-review-profile-4.png';

function ReviewSwiper({ buttonColor }) {
  const renderData = [
    {
      title: (
        <span>
          정말 혈당 스파이크를 관리하니까 3개월 동안 9kg 빠지고 여러 건강
          컨디션이 개선됐어요!
        </span>
      ),
      message: (
        <span>
          부모님이 당뇨셔서 걱정이 많았어요. 40대가 넘어가니까 뱃살 나잇살이 잘
          안빠져서 고민이 많았는데 글루코핏 AI 설명, 그리고 의사 코치님과 함께
          꾸준히 관리하다보니까 건강도 개선되고 살도 덩달아 빠졌어요.
        </span>
      ),
      hashTag: ['40대', '여성', '1년 멤버십'],
      profileName: '김**',
      img: ReviewImg1,
      profileImg: ReviewProfileImg1,
    },
    {
      title: (
        <span>
          15kg 빼고 1년 유지했어요. 잘 맞는 음식을 찾으니 유지가 쉽더라구요.
          개선됐어요!
        </span>
      ),
      message: (
        <span>
          사람마다 잘 맞는 탄수화물이 다르다는 것을 알고 제 몸에 여러가지 실험을
          했어요. 햄버거, 마라탕, 오트밀 등 나한테 잘 맞는 음식을 찾게 되니까
          스트레스 없이 관리할 수 있다는 점이 정말 좋아요.
        </span>
      ),
      hashTag: ['30대', '남성', '1년 멤버십'],
      profileName: '양**',
      img: ReviewImg2,
      profileImg: ReviewProfileImg2,
    },
    {
      title: (
        <span>
          당이 높다는 이야기를 듣고 무서웠는데 글루코핏 의사 코치님 덕분에
          개선되고 주치의 선생님에게 칭찬받았어요!
        </span>
      ),
      message: (
        <span>
          건강검진에서 당이 높다는 이야기를 들었어요. 처음에는 정말
          공포스러웠는데 글루코핏을 통해서 차근차근 관리하다보니 공복혈당이
          10mg/dL 낮아지고 내과 선생님에게 어떻게 이렇게 관리 잘했냐는 칭찬도
          받았어요.
        </span>
      ),
      hashTag: ['50대', '남성', '1년 멤버십'],
      profileName: '안**',
      img: ReviewImg3,
      profileImg: ReviewProfileImg3,
    },
    {
      title: (
        <span>
          살이 너무 안 빠져서 고민이었는데 <br /> 4주만에 체지방률이 4%
          줄었어요!
        </span>
      ),
      message: (
        <span>
          정체기가 진짜 심했는데 식단이 문제였어요.. 저는 고구마가 혈당 스파이크
          주범..!! ㅠㅠ 파스타로 바꿨더니 훨씬났더라구요! 친구는
          반대........정말 사람마다 맞는 탄수화물이 다르더라구요
        </span>
      ),
      profileName: '이**',
      hashTag: ['40대', '여성', '1년 멤버십'],
      img: ReviewImg4,
      profileImg: ReviewProfileImg4,
    },
  ];

  const slideRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    slideRef.current.slideTo(1);
  }, []);
  return (
    <div className="review-swiper-container">
      <div className="review-swiper-contents web">
        <Swiper
          slidesPerView="auto"
          centeredSlides={true}
          onSwiper={(swiper) => (slideRef.current = swiper)}
          spaceBetween={40}
          style={{
            height: 800,
            overflow: 'visible',
          }}
        >
          {renderData.map((item, index) => {
            return (
              <SwiperSlide
                key={index}
                style={{
                  backgroundColor: '#FFFFFF',
                  width: 550,
                  height: 723,
                  borderRadius: 16,
                  boxShadow: '-4px 8px 32px 0px rgba(0, 0, 0, 0.10)',
                  marginTop: 40,
                }}
              >
                <div className="review-swiper-content-title strong">
                  {item.title}
                </div>
                <div className="review-swiper-content-img">
                  <img src={item.img} alt="" />
                </div>
                <div className="review-swiper-content-info">
                  <div className="review-swiper-content-user">
                    <img
                      src={item.profileImg}
                      alt=""
                      className="review-swiper-profile-img"
                    />
                    {item.profileName}님의 후기
                  </div>
                  <div className="review-swiper-content-message">
                    {item.message}
                  </div>
                  <div className="review-swiper-content-hashtag">
                    {item.hashTag.map((item, index) => {
                      return <span key={index}>#{item}</span>;
                    })}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <div className="review-swiper-contents mobile">
        <Swiper
          slidesPerView="auto"
          spaceBetween={24}
          centeredSlides={true}
          style={{
            height: 600,
            overflow: 'visible',
          }}
        >
          {renderData.map((item, index) => {
            return (
              <SwiperSlide
                key={index}
                style={{
                  backgroundColor: '#FFFFFF',
                  width: 312,
                  height: 585,
                  borderRadius: 16,
                  boxShadow: '-4px 8px 32px 0px rgba(0, 0, 0, 0.10)',
                  marginTop: 20,
                }}
              >
                <div className="review-swiper-content-title strong">
                  {item.title}
                </div>
                <div className="review-swiper-content-img">
                  <img src={item.img} alt="" />
                </div>
                <div className="review-swiper-content-info">
                  <div className="review-swiper-content-user">
                    <img
                      src={item.profileImg}
                      alt=""
                      className="review-swiper-profile-img"
                    />
                    {item.profileName}님의 후기
                  </div>
                  <div className="review-swiper-content-message">
                    {item.message}
                  </div>
                  <div className="review-swiper-content-hashtag">
                    {item.hashTag.map((item, index) => {
                      return <span key={index}>#{item}</span>;
                    })}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <button
        className="review-swiper-view-more-button"
        onClick={() => navigate('/review')}
        style={{
          backgroundColor: buttonColor,
        }}
      >
        더 보러가기
      </button>
    </div>
  );
}

export default ReviewSwiper;
