import React, { useState, useEffect, useRef } from 'react';
import Layout from 'components/Raffle/common/Layout';
import styled from 'styled-components';
import axios from 'axios';
import { fontSize, palette } from 'components/Raffle/palette';
import PrizeContainer from 'components/Raffle/main/PrizeContainer';
import TicketContainerComponent from 'components/Raffle/main/TicketContainer';
import LibrePopup from 'components/Raffle/main/LibrePopup';
import { API_BASE_URL } from 'pages/Raffle/config';

import { sendKakao } from './raffleScratch';
import { useLocation, useNavigate } from 'react-router-dom';

const RaffleMain = () => {
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isChckedIn, setIsCheckedIn] = useState(null);
  const [userData, setUserData] = useState(null);
  const [ticketCount, setTicketCount] = useState(null);
  const [authToken, setAuthToken] = useState(
    localStorage.getItem('access_token'),
  );

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/auth/user`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      const userData = response.data; // Axios automatically parses JSON response

      // user ref code
      localStorage.setItem('my_ref_code', userData.referral_code);
      return userData;
    } catch (error) {
      console.error('Failed to fetch data:', error);
      return null;
    }
  };
  const loadData = async () => {
    if (authToken) {
      const data = await fetchUserData();
      setUserData(data);
      setTicketCount(data?.coupon);
      setIsCheckedIn(data?.is_checked_in);
      console.log('data...', data);
    }
  };

  useEffect(() => {
    // 카카오 sdk
    const Kakao = window.Kakao;
    console.log(Kakao.isInitialized());

    // user Data 세팅 (fetch)
    loadData();

    // state - token
    setAuthToken(localStorage.getItem('access_token'));

    // 리퍼럴 코드 세팅
    const params = new URLSearchParams(window.location.search);
    const referralCode = params.get('ref');
    if (referralCode) {
      sessionStorage.setItem('url_ref_code', referralCode);
    }
  }, []); // userId가 변경될 때마다 새로 데이터를 불러옴

  const handleClick = () => {
    if (authToken) {
      if (userData?.coupon == 0) {
        alert(
          '응모권이 부족합니다. 아직 출석체크를 하지 않았다면 출석체크를 해주세요.',
        );
      } else {
        // 사용자가 인증된 경우 "scratch" 페이지로 리디렉션
        window.location.href = '/raffle/scratch';
      }
    } else {
      // 사용자가 인증되지 않은 경우 "sms" 페이지로 리디렉션
      window.location.href = '/raffle/sms';
    }
  };

  return (
    <Layout>
      <Container>
        <ContentsContainer>
          {/* <ImageContainer> */}
          <TagComponent> 건강한 혈당 관리 필수템</TagComponent>
          {/* </ImageContainer> */}
          <Title>프리스타일 리브레</Title>
          <Title2>20개를 드려요</Title2>
          <SubTitle onClick={() => setIsPopupOpen(true)}>
            프리스타일 리브레가 뭔가요? 🤔
          </SubTitle>
          <LibrePopup
            isOpen={isPopupOpen}
            onClose={() => setIsPopupOpen(false)}
            // backgroundImage="path_to_your_image.jpg" // 배경 이미지 경로를 설정하세요
          />
          <EventSection backgroundImage="https://glucofit-image.s3.ap-northeast-2.amazonaws.com/color-g.png">
            <ImageContainer>
              <ImageWrapper>
                <img
                  src="https://glucofit-image.s3.ap-northeast-2.amazonaws.com/Group+1+(2).png"
                  alt="이미지"
                  style={{ width: '180px' }} // 이미지 크기 조정 스타일 추가
                />
              </ImageWrapper>
            </ImageContainer>
            <EventGoodsAndTicketContainer>
              {/* 남은 상품 */}
              <PrizeContainer />
              <TicketContainerComponent
                is
                userData={userData}
                ticketCount={ticketCount}
                authToken={authToken}
                isChckedIn={isChckedIn}
                loadUserData={loadData}
              />
            </EventGoodsAndTicketContainer>
            <StyledButton onClick={() => handleClick()}> 참여하기</StyledButton>
            <StyledButton2 onClick={sendKakao}>
              {' '}
              친구에게 공유하기
            </StyledButton2>
          </EventSection>
          <ImageContainer>
            <ImageWrapper2>
              <a href="https://glucofit.co.kr/">
                <img
                  src="https://glucofit-image.s3.ap-northeast-2.amazonaws.com/LOG/Powered+by+Glucofit.png"
                  alt="이미지"
                  style={{ width: '320px' }} // 이미지 크기 조정 스타일 추가
                />
              </a>
            </ImageWrapper2>
          </ImageContainer>
          <EventGuideContainer>
            <EventGuideTitle>유의사항</EventGuideTitle>
            <li>
              ‘글핏복권’ 은 부정한 방법의 응모를 제한하기 위해 본인인증을 완료한
              회원에 한해 참여가 가능합니다.
            </li>
            <li>
              글루코핏 당첨자는 공정성을 위해 사람이 아닌 프로그램을 통해
              랜덤으로 선정됩니다.
            </li>
            <li>본 제품은 교환/환불이 불가능합니다.</li>
            <li>국내 주소지에 한해 경품이 발송됩니다.</li>
            <li>
              매크로 프로그램, 가상 아이디 사용 등 부정한 방법으로 응모 시도를
              하는 경우 경품이 지급되지 않습니다.
            </li>
            <li>본 이벤트는 상황에 따라 조기 종료될 수 있습니다.</li>
          </EventGuideContainer>
        </ContentsContainer>
      </Container>
    </Layout>
  );
};

const ImageWrapper2 = styled.div`
  display: flex;
  width: 100%;

  justify-content: center; // 이미지를 가로 중앙에 위치
  align-items: center; // 이미지를 세로 중앙에 위치
  transition: transform 0.3s ease-in-out; // 확대/축소 효과에 대한 부드러운 전환 추가

  &:hover {
    transform: scale(1.1); // 호버 시 1.1배로 확대
    // transform: rotate(720deg); // 호버 시 360도 회전
    cursor: pointer; // 마우스 포인터 변경
  }
`;

const EventGuideContainer = styled.div`
  margin-top: 60px;
  padding: 20px;
  color: ${palette.Gray500};
`;

const EventGuideTitle = styled.div`
  padding-bottom: 10px;
  font-weight: bolder; /* 'bolder' 키워드를 사용하여 더 굵게 만듦 */
  font-family: 'Noto Sans KR', sans-serif; /* Noto Sans KR로 변경 */
`;

const EventGoodsAndTicketContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
`;

const TagComponent = styled.div`
  color: white; // 글자 색을 흰색으로 설정
  border: 2px solid white; // 흰색 태두리를 2픽셀로 설정
  padding-left: 16px; // 내부 여백을 10픽셀로 설정
  padding-right: 16px; // 내부 여백을 10픽셀로 설정
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: transparent; // 배경 색을 투명으로 설정
  border-radius: 50px; // 태두리 모서리를 둥글게 (반경 10픽셀)

  margin-top: 40px;
  font-size: 12px;
`;
const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center; /* 텍스트를 중앙 정렬 */
  white-space: pre-line;

  font-size: 24px;
  // font-weight: bolder; /* 'bolder' 키워드를 사용하여 더 굵게 만듦 */
  font-family: 'Noto Sans KR', sans-serif; /* Noto Sans KR로 변경 */
  color: white;

  margin-top: 20px;
`;

const Title2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center; /* 텍스트를 중앙 정렬 */
  white-space: pre-line;

  font-size: 40px;
  font-weight: bolder; /* 'bolder' 키워드를 사용하여 더 굵게 만듦 */
  font-family: 'Noto Sans KR', sans-serif; /* Noto Sans KR로 변경 */
  color: transparent; /* 텍스트 색을 투명하게 설정 */

  border-radius: 16px;

  /* 그라디언트를 텍스트에 적용 */
  background: radial-gradient(
    118.44% 131.89% at 26.72% 102.62%,
    #469dc3 21%,
    #4cdad1 48.5%,
    #f1ffd4 100%
  );
  -webkit-background-clip: text; /* 배경 클립을 텍스트로 설정 */
  background-clip: text; /* 배경 클립을 텍스트로 설정 (비표준 크롬 대응) */
`;

const SubTitle = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center; /* 텍스트를 중앙 정렬 */
  white-space: pre-line;
  margin-top: 1rem;

  font-size: 14px;
  font-weight: 4000; /* 더 높은 font-weight 값을 지정하여 더 굵은 글꼴 적용 */
  font-family: Pretendard; /* 폰트 이름을 font-family로 수정 */
  color: white;
  text-decoration: underline;
`;

const EventSection = styled.div`
  width: 90%; /* props로 전달된 width를 적용 */
  height: 100%;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // flex-grow: 1;

  margin-top: 28px;
  margin-bottom: 24px;
  border-radius: 20px;

  background-image: ${(props) =>
    `url(${props.backgroundImage})`}; /* 배경 이미지 URL 사용 */
  background-size: cover; /* 배경 이미지가 컨테이너를 꽉 채우도록 설정 */
  background-position: center; /* 배경 이미지가 중앙에 위치하도록 설정 */
`;

const ImageContainer = styled.div`
  width: 100%; /* 좌우로 100%로 커지도록 설정 */
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 100%;

  justify-content: center; // 이미지를 가로 중앙에 위치
  align-items: center; // 이미지를 세로 중앙에 위치
`;

const IconWrapper = styled.div`
  display: flex;
  width: 30px;

  justify-content: center; // 이미지를 가로 중앙에 위치
  align-items: center; // 이미지를 세로 중앙에 위치
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  // height: 100%;

  // background-image: url(https://glucofit-image.s3.ap-northeast-2.amazonaws.com/bg-item.png); /* 배경 이미지 URL 사용 */
  // background-size: cover; /* 배경 이미지가 컨테이너를 꽉 채우도록 설정 */
  // background-position: center; /* 배경 이미지가 중앙에 위치하도록 설정 */
  background-color: #141414;
`;

const ContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  border-radius: 20px;
  min-height: 100vh;
  flex-grow: 1;
  // height: 100%;
`;

const StyledButton = styled.button`
  background-color: black;
  color: white; /* 흰색 텍스트 적용 */
  border: none;
  padding: 12px 24px; /* 적당한 크기의 패딩을 적용 */

  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-block;
  text-align: center; /* 텍스트 중앙 정렬 */
  margin-top: 20px; /* 상하좌우에 약간의 마진 추가 */
  margin-bottom: 12px; /* 상하좌우에 약간의 마진 추가 */
  margin-left: 24px;
  margin-right: 24px;

  font-size: ${fontSize.big};
  font-weight: bolder; /* 'bolder' 키워드를 사용하여 더 굵게 만듦 */
  font-family: 'Noto Sans KR', sans-serif; /* Noto Sans KR로 변경 */

  &:hover {
    background-color: ${palette.Background};
    color: ${palette.Gray800};
  }
`;

const StyledButton2 = styled.button`
  background-color: ${palette.FitMint600};
  color: white; /* 흰색 텍스트 적용 */
  border: none;
  padding: 12px 24px; /* 적당한 크기의 패딩을 적용 */

  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-block;
  text-align: center; /* 텍스트 중앙 정렬 */
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 24px;

  font-size: ${fontSize.big};
  font-weight: bolder; /* 'bolder' 키워드를 사용하여 더 굵게 만듦 */
  font-family: 'Noto Sans KR', sans-serif; /* Noto Sans KR로 변경 */

  &:hover {
    background-color: ${palette.Background};
    color: ${palette.Gray800};
  }
`;

export default RaffleMain;
