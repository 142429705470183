import { useEffect, useState } from 'react';
import { useDaumPostcodePopup } from 'react-daum-postcode';

export default function useOrder() {
  const formatPhoneNumber = (phoneNumber) => {
    const phoneNumberRegex = /^(\d{3})(\d{3,4})(\d{4})$/;
    const matches = phoneNumber.match(phoneNumberRegex);
    if (matches) {
      return `${matches[1]}-${matches[2]}-${matches[3]}`;
    }
    return phoneNumber;
  };

  function formatTerms() {
    return !this.value;
  }

  const [order, setOrder] = useState(() => {
    const savedData = JSON.parse(sessionStorage.getItem('order'));
    return {
      buyerName: {
        value: savedData?.buyerName?.value || '',
        invalid: false,
        required: true,
      },
      buyerPhoneNumber: {
        value: savedData?.buyerPhoneNumber?.value || '',
        invalid: false,
        formatted: formatPhoneNumber,
        required: true,
      },
      postcode: {
        value: savedData?.postcode?.value || '',
        invalid: false,
        required: true,
      },
      address: {
        value: savedData?.address?.value || '',
        invalid: false,
        required: true,
      },
      detailAddress: {
        value: savedData?.detailAddress?.value || '',
        invalid: false,
        required: false,
      },
      userName: {
        value: savedData?.userName?.value || '',
        invalid: false,
        required: false,
      },
      userPhoneNumber: {
        value: savedData?.userPhoneNumber?.value || '',
        invalid: false,
        formatted: formatPhoneNumber,
        required: false,
      },
      payMethod: {
        value: savedData?.payMethod?.value || 'card',
        invalid: false,
        required: true,
      },
      terms1: {
        value: savedData?.terms1?.value || false,
        invalid: false,
        required: true,
        formatted: formatTerms,
      },
      terms2: {
        value: savedData?.terms2?.value || false,
        invalid: false,
        required: true,
        formatted: formatTerms,
      },
    };
  });

  const handleOrder = (e) => {
    let { name, value } = e.target;
    if (name === 'buyerPhoneNumber' || name === 'userPhoneNumber') {
      value = e.target.value.replace(/[^\d]/g, '');
      if (value.length > 11) {
        return;
      }
    }
    if (name === 'userName') {
      setOrder((prev) => ({
        ...prev,
        userPhoneNumber: {
          ...prev.userPhoneNumber,
          required: value.length > 0,
        },
      }));
    }

    setOrder((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        value: prev[name].formatted ? prev[name].formatted(value) : value,
        invalid: typeof value === 'boolean' ? !value : value.length === 0,
      },
    }));
  };

  const validateOrder = (exceptField) => {
    return Object.entries(order).every(([key, value]) => {
      if (value.required && !value.value && !exceptField?.includes(key)) {
        setOrder((prev) => ({
          ...prev,
          [key]: { ...prev[key], invalid: true },
        }));
        return false;
      }
      return true;
    });
  };

  const open = useDaumPostcodePopup(
    'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js',
  );

  const handleFindAddressClick = () => {
    open({
      onComplete: (data) => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
          if (data.bname !== '') {
            extraAddress += data.bname;
          }
          if (data.buildingName !== '') {
            extraAddress +=
              extraAddress !== ''
                ? `, ${data.buildingName}`
                : data.buildingName;
          }
          fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        }

        setOrder((prev) => ({
          ...prev,
          address: { ...prev.address, value: fullAddress, invalid: false },
          postcode: { ...prev.postcode, value: data.zonecode, invalid: false },
        }));
      },
    });
  };

  useEffect(() => {
    if (!order) return;
    sessionStorage.setItem('order', JSON.stringify(order));
  }, [order]);

  return {
    order,
    setOrder,
    handleOrder,
    validateOrder,
    handleFindAddressClick,
  };
}
