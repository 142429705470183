import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
// import { validateStorageToken } from './common';
import { palette } from 'pages/AnyfitMall/theme';
import { API_BASE_URL } from 'pages/AnyfitMall/config';

const CancelBtn = styled.div`
  font-size: 0.8rem;
  color: ${palette.Red400};
  width: 60px;
  border: 1px solid ${palette.Red400};
  border-radius: 4px;
  margin-top: 6px;

  display: flex;
  justify-content: center;
  align-items: center; // 수직 중앙 정렬 추가

  &:hover {
    color: ${palette.Red500};
    cursor: pointer;
  }
`;

const OrderCancelButton = ({ orderId, token, fetchOrders, children }) => {
  useEffect(() => {
    console.log('token...', token);
  }, []);
  const navigate = useNavigate();
  const cancelOrder = async (token, orderId) => {
    // 토큰 출력
    console.log('token...', token);
    const response = await fetch(`${API_BASE_URL}/api/cancelOrder`, {
      method: 'POST',

      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ orderId: orderId }),
    });

    if (response.ok) {
      const data = await response.json();
      console.log('data...', data);
      alert('주문이 취소됐습니다.');
      // fetchOrders();
      window.location.reload();
    } else {
      console.error('Failed to fetch products:', response.statusText);
      alert('토큰이 유효하지 않습니다.');
      navigate('/anyfit/error');
    }
  };

  return (
    <CancelBtn onClick={() => cancelOrder(token, orderId)}>
      {children}
    </CancelBtn>
  );
};

export default OrderCancelButton;
