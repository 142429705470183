import React from 'react';
import '../CSS/About.scss';
import Cgm from '../../../assets/images/ver3.0/img-about-cgm.png';
import Phone from '../../../assets/images/ver3.0/img-about-phone.png';
import Mark from '../../../assets/images/ver3.0/img-about-mark.png';
import LeftPolygon from '../../../assets/icons/ic-about-left-polygon.svg';
import RightPolygon from '../../../assets/icons/ic-about-right-polygon.svg';
import CenterPolygon from '../../../assets/icons/ic-about-center-polygon.svg';

function About() {
  return (
    <div className="about-container">
      <div className="about-membership">
        <div className="about-tag strong" id="about-membership">
          글루코핏 멤버십
          <img src={LeftPolygon} alt="" id="left-polygon" />
        </div>
        <img src={Phone} alt="글루코핏 멤버십" />
        <div className="about-cgm">
          <img src={CenterPolygon} alt="" id="center-polygon" />
          <img src={Cgm} alt="연속 혈당 측정기 (CGM)" />
          <div className="about-tag strong" id="about-cgm">
            연속 혈당 측정기(CGM)
            <img src={RightPolygon} alt="" id="right-polygon" />
          </div>
        </div>
        <div className="about-text-container">
          <div className="about-title-eng strong">
            About GLUCOFIT
            <div className="about-mark">
              <img src={Mark} alt="서울대 마크" />
            </div>
          </div>
          <div className="about-title">
            <span className="strong">국내 유일 서울대,</span> <br />
            <span className="strong">의사출신</span>이 만든 <br />
            신뢰도 높은 <span className="strong">글루코핏</span>
          </div>
          <div className="about-description">
            7건의 AI 특허와 의학적 전문성 기반의 <br />
            국내 최초 혈당 케어 솔루션
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
